import React from "react";

function GetTotal( props ) {
    // console.log(props,"props")
  const { values } = props;
  const total = values.reduce((acc, val) => acc + val, 0);

  return <>{total}</>;
}

export default GetTotal;

import { useState } from "react";
import Image from "../../Components/Common/Image/Image";
import logoemt from "../../Assets/Logo/logoemt.png";
import Button from "../../Components/Common/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { RiLockPasswordFill } from "react-icons/ri";
import { FaUserAlt, FaPhoneAlt, FaEye, FaEyeSlash } from "react-icons/fa";
import SelectTag from "../../Components/LpComponents/SelectTag/SelectTag";
import { useFormik } from "formik";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import axios from "axios";
import SpinnerIndicator from "../../Components/Common/SpinnerIndicator/SpinnerIndicator";
import { toast } from "react-toastify";
import InputBox from "../../Components/Common/InputBox/InputBox";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { CREATE_USER_MUTATION } from "../../gql/signUp";
import OtpInput from "react-otp-input";
import { useTimer } from "../../hooks";
import Tippy from "@tippy.js/react";
import { BiArrowBack } from "react-icons/bi";
import "./SignupPage.scss";

axios.defaults.withCredentials = true;

const OTP_REQUEST = gql`
  query MobileVerificationSendOTP($mobileNumber: BigInt!, $accType: String!) {
    MobileVerificationSendOTP(mobileNumber: $mobileNumber, accType: $accType) {
      message
      sessionId
    }
  }
`;

function SignupPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [signupUser, { loading, error }] = useMutation(CREATE_USER_MUTATION);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const { isTimerRunning, seconds, start: handleStartTimer, stop: handleStopTimer } = useTimer(60);
  const [showOtpContainer, setShowOtpContainer] = useState(false);
  const [OTP, { data: userOtp }] = useLazyQuery(OTP_REQUEST, { fetchPolicy: "no-cache" });
  const [otpLoader, setOtpLoader] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const accType = useSelector((state) => state.signupData.accType);
  const mobileNumber = useSelector((state) => state.signupData.mobileNumber);

  const handleSendOTP = (values) => {
    setOtpLoader(true);
    OTP({ variables: { mobileNumber: values.mobileNumber, accType: values.accType } })
      .then((res) => {
        if (res.error) {
          setNotFoundError(true);
          setOtpLoader(false);
          toast.warn(res.error.message);
        } else {
          handleStartTimer();
          setOtpLoader(false);
          setSessionId(res.data.MobileVerificationSendOTP.sessionId);
          toast.success("OTP sent");
          setShowOtpContainer(true);
        }
      })
      .catch((err) => {
        setOtpLoader(false);
        toast.warn(err);
      });
  };

  const handleVerify = async (data) => {
    // if (otp === "" || otp.length < 4) {
    //   setOtpError("Please Enter 4 digit OTP");
    // } else {
    setOtpLoader(true);
    await signupUser({
      variables: {
        signupInputRootUser: {
          Type_Of_User: "Root_User",
          accType: accType,
          firstName: data.firstName,
          FullName: data.FullName,
          Company_Name: data.Company_Name,
          lastName: data.lastName,
          email: data.email,
          password: data.password,
          mobileNumber: Number(mobileNumber),
        },
        // userInputOtp: Number(otp),
      },
    })
      .then((res) => {
        if (res) {
          // console.log(res, "res");
          if (res.data.signupRootUser.message === "Invalid Credentials!") {
            toast.warn(res.data.signupRootUser.message);
          } else if (res.data.signupRootUser.message === "User Already Exists!") {
            toast.warn(res.data.signupRootUser.message);
          } else {
            Cookies.set("token", res.data.signupRootUser.token);
            Cookies.set(
              "accInfo",
              JSON.stringify({
                id: res.data.signupRootUser.user._id,
                accType: res.data.signupRootUser.user.accType,
                FullName: res.data.signupRootUser.user.FullName,
                firstName: res.data.signupRootUser.user.firstName,
                lastName: res.data.signupRootUser.user.lastName,
                email: res.data.signupRootUser.user.email,
                mobileNumber: res.data.signupRootUser.user.mobileNumber,
                Type_Of_User: res.data.signupRootUser.user.Type_Of_User,
                Root_User: res.data.signupRootUser.user.Root_User,
                Is_Active: res.data.signupRootUser.user.Is_Active,
                Unique_Id: res.data.signupRootUser.user.Unique_Id,
              })
            );
            Cookies.set("accDataId", res.data.signupRootUser.AccountData._id);
            Cookies.set("userAccId", res.data.signupRootUser.AccountData.Unique_Id);
            Cookies.set(
              "accData",
              JSON.stringify({
                Account_Number: res.data.signupRootUser.AccountData.Account_Number,
              })
            );
            dispatch({
              type: "set_userInfo",
              payload: res.data.signupRootUser.user,
            });
            dispatch({
              type: "set_accType",
              payload: res.data.signupRootUser.user.accType,
            });
            dispatch({
              type: "set_userToken",
              payload: res.data.signupRootUser.token,
            });
            if (res.data.signupRootUser.user.accType === "Load Partner Account") {
              navigate("/live");
            } else {
              navigate("/open");
            }
            window.location.reload();
            toast.success(res.data.signupRootUser.message);
          }
        } else {
          setOtpLoader(false);
          toast.success("Profile Created Sucessfully");
          dispatch({ type: "Refetch", payload: true });
        }
      })
      .catch((err) => {
        setOtpLoader(false);
        toast.warn(err.message);
        console.log(err);
      });
    // }
  };

  const initialValues = {
    accType: "",
    FullName: "",
    Company_Name: "",
    firstName: "",
    lastName: "",
    mobileNumber: "",
    email: "",
    password: "",
  };

  const onSubmit = (values) => {
    handleVerify(values);
  };
  const validate = (values) => {
    const errors = {};

    if (!values.FullName) {
      errors.FullName = "Required";
    } else if (values.FullName.length < 3) {
      errors.FullName = "At least 3 letters required";
      // } else if (!values.lastName) {
      //   errors.lastName = "Required";
      // } else if (values.lastName.length < 3) {
      //   errors.lastName = "At least 3 letters required";
      // } else if (!values.mobileNumber) {
      //   errors.mobileNumber = "Required";
      // } else if (String(values.mobileNumber).length < 10 || String(values.mobileNumber).length > 10) {
      //   errors.mobileNumber = "Enter Valid 10 Digit Mobile Number";
      // } else if (!values.password) {
      //   errors.password = "Required";
      // } else if (values.password.search(/[A-Z]/) < 0) {
      //   errors.password = "At least one uppercase letter required";
      // } else if (!/[a-z]/.test(values.password)) {
      //   errors.password = "At least one lowercase letter required";
      // } else if (values.password.search(/[0-9]/) < 0) {
      //   errors.password = "At least one number is required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <div className={accType === "Supply Partner Account" ? "bgImgDivLoginSP" : "bgImgDivLoginLP"}>
      <div className="signuppage">
        <div className="signupbox">
          <div className="emtLogoDiv">
            <Image className="emtlogo" src={logoemt} alt="emtlogo" />
          </div>
          <div className="backDiv">
                <Tippy content={"Back"} placement="bottom">
                  <div
                    className={"IconsDiv"}
                onClick={() => {
                      navigate("/login")
                      // setLogin(false);
                    }}
                  >
                    <BiArrowBack className="backFilters" />
                  </div>
                </Tippy>
          </div>
          <div className="singupHeadingDiv">
          <p className="singupHeading">Create Account</p>
          </div>
          <div className="boxcenter">
            <form className="form" onSubmit={formik.handleSubmit}>
              <div>
                {!showOtpContainer && (
                  <div>
                    {/* <div className="Icon-inside">
                      <SelectTag
                        label="Account Type* :"
                        className="selecttag"
                        id="accType"
                        name="accType"
                        value={formik.values.accType}
                        handleChange={formik.handleChange}
                        options={["Load Partner Account", "Supply Partner Account"]}
                        err={formik.errors.accType}
                        touched={formik.touched.accType ? formik.touched.accType : false}
                      />
                    </div> */}
                    <div className="Icon-inside">
                      <InputBox
                        className="inputbox txtCapitalization"
                        id="FullName"
                        name="FullName"
                        value={formik.values.FullName}
                        handleInputChange={formik.handleChange}
                        placeholder="User Name*"
                        type="text"
                        autoEveryCapital={true}
                        err={formik.errors.FullName}
                        touched={formik.touched.FullName ? formik.touched.FullName : false}
                        autoComplete={"none"}
                      />
                      <i aria-hidden="true">
                        <FaUserAlt className="form_icons" />
                      </i>
                    </div>
                    <div className="Icon-inside">
                      <InputBox
                        className="inputbox txtCapitalization"
                        id="Company_Name"
                        name="Company_Name"
                        value={formik.values.Company_Name}
                        handleInputChange={formik.handleChange}
                        placeholder="Company Name"
                        type="text"
                        autoEveryCapital={true}
                        err={formik.errors.Company_Name}
                        touched={formik.touched.Company_Name ? formik.touched.Company_Name : false}
                        autoComplete={"none"}
                      />
                      <i aria-hidden="true">
                        <FaUserAlt className="form_icons" />
                      </i>
                    </div>
                    {/* <div className="Icon-inside">
                      <InputBox
                        className="inputbox no-spinners"
                        type="number"
                        id="mobileNumber"
                        name="mobileNumber"
                        value={formik.values.mobileNumber}
                        handleInputChange={formik.handleChange}
                        placeholder="Mobile Number*"
                        maxNo={10}
                        err={formik.errors.mobileNumber}
                        touched={formik.touched.mobileNumber ? formik.touched.mobileNumber : false}
                        autoComplete={"none"}
                      />
                      <i aria-hidden="true">
                        <FaPhoneAlt className="form_icons" />
                      </i>
                    </div> */}
                    {/* <div className="Icon-inside">
                      <InputBox
                        className="inputbox"
                        id="password"
                        name="password"
                        value={formik.values.password}
                        handleInputChange={formik.handleChange}
                        type={showPassword ? "text" : "password"}
                        placeholder="Set Password"
                        err={formik.errors.password}
                        touched={formik.touched.password ? formik.touched.password : false}
                      />
                      {showPassword ? (
                        <FaEye className="eyeIcon" onClick={() => setShowPassword(!showPassword)} />
                      ) : (
                        <FaEyeSlash className="eyeIcon" onClick={() => setShowPassword(!showPassword)} />
                      )}
                      <i aria-hidden="true">
                        <RiLockPasswordFill id="passwordicon" className="form_icons" />
                      </i>
                    </div> */}

                    <div className="btnbox">
                      <Button
                        className="btn1"
                        // type={"button"}
                        text={loading || otpLoader ? <SpinnerIndicator /> : "Submit"}
                      />
                      {/* <Link to="/login">
                        <Button className="btn2" text="Go to Login" />
                      </Link> */}
                    </div>
                  </div>
                )}
                {showOtpContainer && (
                  <div>
                    <div className="backDiv">
                      <Tippy content={"Back"} placement="bottom">
                        <div
                          className={"IconsDiv"}
                          onClick={() => {
                            setShowOtpContainer(false);
                          }}
                        >
                          <BiArrowBack className="backFilters" />
                        </div>
                      </Tippy>
                    </div>
                    <div className="mobileVerify">
                      <p className="mobileVerifyP">
                        A OTP(One Time Password) has been sent to your mobile number{" "}
                        <span className="mobileVerifyPSpan">{formik.values.mobileNumber}</span> . Please enter the OTP
                        in the field below.
                      </p>
                    </div>
                    <div className="mobileVerifyInput">
                      <OtpInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={4}
                        renderSeparator={<span>-</span>}
                        renderInput={(props) => <input {...props} />}
                        shouldAutoFocus={true}
                      />
                    </div>
                    {otpError && otp.length < 4 && (
                      <div className="errdivotp">
                        {" "}
                        <p className="errorotp">{otpError}</p>
                      </div>
                    )}
                    <div className="mobileVerifyBtn">
                      <Button
                        type="button"
                        className="acceptBtn"
                        handleClick={() => {
                          handleVerify(formik.values);
                        }}
                        text={loading || otpLoader ? <SpinnerIndicator /> : "Verify & Signup"}
                      />
                    </div>

                    <div className="resendOtp">
                      {isTimerRunning ? (
                        <p className="resendOtpP">Resend OTP in {seconds} seconds.</p>
                      ) : (
                        <p className="resendOtpP">
                          Didn't get OTP?{" "}
                          <span
                            className="resendOtpPspan"
                            onClick={() => {
                              handleSendOTP(formik.values);
                            }}
                          >
                            Resend
                          </span>
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
          <p className="helplineNoSignupText">
            Helpline No -<br></br>
            7066664747 / 7066669090
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;

import { gql } from "@apollo/client";

export const PAYMENT_REQUEST = gql`
  mutation CreatePaymentRequest($createPaymentRequestInput: CreatePaymentRequestInput!) {
    createPaymentRequest(createPaymentRequestInput: $createPaymentRequestInput) {
      _id
      Date
      Party_Name
      Account_Type
      Reference_No
      Bank_Name
      Bank_IFSC_Code
      Account_Number
      Amount
      Remark
      Narration
      createdAt
      updatedAt
      Unique_Id
    }
  }
`;

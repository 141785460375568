import { gql } from "@apollo/client";

export const EDIT_CLAIMS = gql`
  mutation EditClaims_Without_VerifyUserWithBalance(
    $editClaimsWithoutVerifyUserWithBalanceId: ID!
    $userInput: ClaimsInput!
  ) {
    editClaims_Without_VerifyUserWithBalance(id: $editClaimsWithoutVerifyUserWithBalanceId, userInput: $userInput) {
      id
      Is_Accepted
      Is_Rejected
    }
  }
`;

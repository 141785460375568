import { BsFacebook, BsLinkedin, BsFillSquareFill, BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { ImLocation } from "react-icons/im";
import "./Footer.scss";
import { Link } from "react-router-dom";
import playStore from "../../../Assets/Images/playStore.png";
// import appStore from "../../../Assets/Images/appStore.png";

const Footer = () => {
  const handleVisitLinkedIn = () => {
    window.open("https://www.linkedin.com/company/ease-my-transport-pvt-ltd/", "_blank");
  };
  const handleVisitFacebok = () => {
    window.open("https://www.facebook.com/profile.php?id=100070802801079", "_blank");
  };

  const handleVisitSPApp = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.headtler.EMT_SP_App&pcampaignid=web_share",
      "_blank"
    );
  };
  const handleVisitLPApp = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.headtler.EMT_LP_Native_App&pcampaignid=web_share",
      "_blank"
    );
  };
  return (
    <>
      <div className="Footer_Main_div">
        <div className="infoDiv">
          <img src="./Ease-My-Transport-LOGO-1.jpg" alt="" />
          <p className="Aboutus_text">
            We are India’s best Truck Suppliers as we have thousands of trucks attached to us that can be Aligned for
            your trip all over India.
          </p>
          <div>
            <div className="SocialMediaLink">
              <div onClick={handleVisitFacebok}>
                <BsFacebook className="socialMediaIcons" />
              </div>
              {/* <div className="icon">
                <FiInstagram className="socialMediaIcons" size={22} />
              </div> */}
              <div className="socialMediaIcons" onClick={handleVisitLinkedIn}>
                <BsLinkedin />
              </div>
            </div>
          </div>
          <p className="AppHeding">EXPERIENCE OUR APP ON MOBILE</p>
          <div>
            <div className="AppLink">
              <p className="appText">Get LP APP</p>
              <div onClick={handleVisitLPApp}>
                <img className="appimg" src={playStore} alt="hash" />
              </div>
              {/* <div onClick={handleVisitLPApp}>
                <img className="appimg" src={appStore} alt="hash" />
              </div> */}
            </div>
          </div>
          <div>
            <div className="AppLink">
              <p className="appText">Get SP APP</p>
              <div onClick={handleVisitSPApp}>
                <img className="appimg" src={playStore} alt="hash" />
              </div>
              {/* <div onClick={handleVisitLPApp}>
                <img className="appimg" src={appStore} alt="hash" />
              </div> */}
            </div>
          </div>
        </div>

        <div className="Footer_Info_Div">
          <div className="infoDiv">
            <p className="Footer_Head">Company</p>
            <div className="contentsDiv">
              <BsFillSquareFill className="HoverIcon" size={10} />
              <p className="IconHover">Truckers</p>
            </div>

            <div className="contentsDiv">
              <BsFillSquareFill className="HoverIcon" size={10} />
              <p>Careers</p>
            </div>

            <div className="contentsDiv">
              <BsFillSquareFill className="HoverIcon" size={10} />
              <p>Contacts</p>
            </div>

            <div className="contentsDiv">
              <BsFillSquareFill className="HoverIcon" size={10} />
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>

            <div className="contentsDiv">
              <BsFillSquareFill className="HoverIcon" size={10} />
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </div>
          </div>
          <div className="infoDiv">
            <p className="Footer_Head">Quick Contact</p>

            <div className="contentsDiv">
              <BsFillTelephoneFill className="HoverIcon" />
              <p>LP Helpline No - 70 6666 4747</p>
            </div>

            <div className="contentsDiv">
              <BsFillTelephoneFill className="HoverIcon" />
              <p>SP Helpline No - 70 6666 9090</p>
            </div>

            <div className="contentsDiv">
              <GrMail className="HoverIcon" />
              <p>contact@easemytransport.com</p>
            </div>

            <div className="contentsDiv">
              <ImLocation size={60} className="HoverIcon" />
              <p>
                Office No 14, 15, 1st Floor, Shanti complex, Jawaharlal Nehru Road, Narpatgiri Chowk, Mangalwar Peth,
                Pune, Maharashtra – 411 011.
              </p>
            </div>
            <div className="contentsDiv">
              <p className="">Copyright © 2020 Ease Transport All rights reserved.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

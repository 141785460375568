import React, { useState } from "react";
import { MdVerified } from "react-icons/md";
import { handleKeyDown } from "../../../../../../../CommonFunction/common";
import { useDispatch } from "react-redux";
import { useRef } from "react";

function InputContentEditable({
  name,
  value,
  label,
  disabled,
  type,
  errors,
  touched,
  handleInputChange,
  handleBlur,
  uppercase,
  min,
  max,
  MobileVerify,
  EmailVerify,
  readOnly,
  require,
  autoCapital,
  formKey,
}) {
  const [fieldcontent, setfieldcontent] = useState("");
  const dispatchRef = useRef(null);
  const dispatch = useDispatch();

  const formikdatamanage = (e) => {
    handleInputChange({
      target: {
        name: name,
        value:
          isNaN(+e.target.value) === true || e.target.value === ""
            ? e.target.value
            : +e.target.value,
      },
    });

    if (dispatchRef.current) {
      clearTimeout(dispatchRef.current);
    }

    dispatchRef.current = setTimeout(() => {
      dispatch({
        type: "editProfileData",
        payload: {
          key: formKey,
          data: {
            name: name,
            value:
              isNaN(+e.target.value) === true || e.target.value === ""
                ? e.target.value
                : +e.target.value,
          },
          from: "Personal",
        },
      });
    }, 700);
  };

  function convertToUppercase(e) {
    e.target.value = e.target.value.toUpperCase();
  }

  function capitalizeEveryFirstLetter(e) {
    const words = e.target.value.split(" ");

    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    e.target.value = capitalizedWords.join(" ");
  }

  return (
    <>
      <div className="detailsBox">
        <div className="detailsBoxName">
          <p className="contentText">
            {label}
            {require ? "*" : null}
          </p>
        </div>
        <div className="detailsBoxContent">
          {(name === "Email" && EmailVerify === true) ||
          (name === "Mobile_Number" && MobileVerify === true) ? (
            <div className="verifyIcon">
              <MdVerified
                style={{
                  color: "green",
                  marginTop: "5px",
                  marginRight: "-14px",
                  marginLeft: "-5px",
                }}
              />
            </div>
          ) : (
            ""
          )}
          <div className="inputDiv">
            <input
              className={
                name === "Email"
                  ? errors && touched
                    ? `editable1 no-spinners `
                    : `editable no-spinners `
                  : errors && touched
                  ? `editable1 no-spinners txtCapitalization`
                  : `editable no-spinners txtCapitalization`
              }
              name={name}
              // type={type}
              value={value ? value + "" : fieldcontent}
              disabled={disabled}
              onKeyDown={(e) => {
                handleKeyDown(e, type);
              }}
              onChange={(e) => {
                let text = e.target.value + "";
                if (text.length > max) {
                  return;
                }
                if (uppercase) {
                  convertToUppercase(e);
                }
                if (autoCapital) {
                  capitalizeEveryFirstLetter(e);
                }
                setfieldcontent(e.target.value);
                formikdatamanage(e);
              }}
              min={min}
              max={max}
              readOnly={readOnly}
              onBlur={(e) => {
                handleBlur(e);
              }}
            />
            {disabled ? null : <hr className="hrTagEdit" />}
          </div>
        </div>
      </div>
    </>
  );
}

export default InputContentEditable;

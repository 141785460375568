import React from "react";
import { notificationData } from "../../../db/notification.config";
import {IoMdNotifications} from "react-icons/io"
import "./Notification.scss";

function Notification() {
  return (
    <div className="notificationBox">
      {notificationData?.map((el) => (
        <div key={el} className="notificationDiv boxcenter">
            <IoMdNotifications className="notificationIcon"/>
          <p className="notificationText">You have a new Notification {el}!</p>
        </div>
      ))}
    </div>
  );
}

export default Notification;

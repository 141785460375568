import React from "react";
// import { FaEye } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import "./ShowDocsModal.scss";

function ShowDocsModal({ documentsArr }) {
  // const openImageInNewTab = (imageUrl) => {
  //   window.open(imageUrl, "_blank");
  // };
  return (
    <div className="showDocsDiv">
      {documentsArr?.map((el) => (
        <div key={el.name} className="docsDiv">
          {el.url && <img className="docImg" src={el.url} alt={el.name} />}
          <div>
            {el.url && (
              <p className="docTitle">
                {el.name}{" "}
                <a href={el.url}>
                  <FiDownload className="downloadIcon" />
                </a>
                {/* <FaEye className="downloadIcon"  onClick={()=>openImageInNewTab(el.url)}/> */}
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ShowDocsModal;

import React, { useEffect, useState } from "react";
import Reactportal from "../../LpComponents/Reactportal/Reactportal";
import CnfModal from "../AllModals/CnfModal/CnfModal";
import Button from "../Button/Button";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import socket from "../../../CommonFunction/Socket/socketClient";
import { EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE } from "../../../gql/editCommitedVehiclesWithoutVerifyBalance";
import "./CreateButton.scss";

function CreateButton({ el, confirm, finalNoOfVehicleRequired }) {
  const [openVehicleCnfModal, setOpenVehicleCnfModal] = useState(false);
  const [editCommittedVehiclesId, setEditCommittedVehiclesId] = useState();
  const [iseditCommittedVehiclesLoading, setIsEditCommittedVehiclesLoading] = useState(false);
  const [editCommittedVehicles, { loading: editCommittedVehiclesLoading }] = useMutation(
    EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE
  );
  const [btnStatus, setBtnStatus] = useState(false);

  async function editCommittedVehiclesData() {
    await editCommittedVehicles({
      variables: {
        editCommittedVehiclesWithoutVerifyUserWithBalanceId: el.Committed_Vehicles[editCommittedVehiclesId]._id,
        userInput: {
          Is_Confirmed: true,
        },
      },
    })
      .then((res) => {
        socket.emit("EditConfirmVechileswithId", res.data.editCommittedVehicles_Without_VerifyUserWithBalance.LoadId);
        toast.success("Vehicle Confirmed");
        setOpenVehicleCnfModal(false);
      })
      .catch((err) => toast.error(err.message));
  }

  function getBtnText(Is_Accepted, Is_Rejected) {
    if (Is_Accepted) {
      return <>Accepted</>;
    } else if (Is_Rejected) {
      return <>Rejected</>;
    }
    return <>Confirmed</>;
  }

  useEffect(() => {
    if (finalNoOfVehicleRequired === 0) {
      setBtnStatus(true);
    } else {
      setBtnStatus(false);
    }
  }, [finalNoOfVehicleRequired]);

  useEffect(() => {
    setIsEditCommittedVehiclesLoading(editCommittedVehiclesLoading);
  }, [editCommittedVehiclesLoading]);

  // console.log("timee", el);

  return (
    <>
      <div className="btnDivision">
        {el &&
          el.Committed_Vehicles?.map((el, i) => {
            {/* console.log(el, "elll"); */}
            return (
              <Button
                key={el && el.id}
                text={el && el.Is_Confirmed ? getBtnText(el.Is_Accepted, el.Is_Rejected) : "Confirm"}
                className={(el && el.Is_Confirmed) || el.Time_Out_Expired || btnStatus ? "cnfDisabledBtn" : "cnfBtn"}
                disabled={(el && el.Is_Confirmed) || el.Time_Out_Expired || btnStatus ? true : false}
                handleClick={() => {
                  setOpenVehicleCnfModal(true);
                  setEditCommittedVehiclesId(i);
                }}
              />
            );
          })}
      </div>
      <Reactportal
        open={openVehicleCnfModal}
        onClose={() => setOpenVehicleCnfModal(false)}
        modalClassName="confirmationModal"
        contentClassName="confirmationModal-content"
        modalHeading={"Confirmation"}
      >
        <CnfModal
          onClose={() => setOpenVehicleCnfModal(false)}
          cnfMessage={"Do you want to confirm this vehicle?"}
          editCommittedVehiclesData={editCommittedVehiclesData}
          loading={iseditCommittedVehiclesLoading}
        />
      </Reactportal>
    </>
  );
}

export default CreateButton;

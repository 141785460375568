import "./TermsAndConditions.scss";
import LandingPageTopNavBar from "../LandingPage/LandingPageTopNavBar/LandingPageTopNavBar";
import LandingPageNavBar from "../LandingPage/LandingPageNavBar/LandingPageNavBar";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import Footer from "../LandingPage/Footer/Footer";
import Spinner from "../../Components/Common/Spinner/Spinner";
import { GET_TERMS_AND_CONDITIONS } from "../../gql/termCondition";

const RenderListContainer = ({ data, title, className }) => {
  return (
    <div className={className}>
      <h2>{title}</h2>
      {data.map((term, index) => (
        <div key={index} className="termBox">
          <h3>
            {index + 1}. {term.Title}
          </h3>
          <p>{term.Description}</p>
        </div>
      ))}
    </div>
  );
};

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_TERMS_AND_CONDITIONS);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LandingPageTopNavBar />
      <LandingPageNavBar />

      <main className="termsNContainerDiv">
        <BsArrowLeft size={20} onClick={() => navigate("/")} />
        {loading ? (
          <Spinner size={50} />
        ) : (
          <div className="termsMain">
            <RenderListContainer
              data={data?.GetAllTermsAndConditionsByTypeOfAccount.spData}
              title="Terms and Conditions for Supply Partners"
              className="termsContainer"
            />

            <RenderListContainer
              data={data?.GetAllTermsAndConditionsByTypeOfAccount.lpData}
              title="Terms and Conditions for Load Partners"
              className="termsContainer"
            />
          </div>
        )}
      </main>

      <Footer />
    </>
  );
};

export default TermsAndConditions;

import { useEffect, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import "./Stepper.scss";
import { AllUserProfileModal } from "../../../Pages/LpPages/ProfilePage/ProfilePageDashboard/DashboardPages/UserProfilePage/UserProfileModal.config";
import { useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";

const Stepper = ({ children, height, handleClickSave, edit, handleBankVerifiy, handlePanVerify }) => {
  const {
    fielddata: { Verified_Details },
  } = useSelector((store) => store.editProfile);
  const [loading, setLoading] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({
    0: Verified_Details?.Is_Aadhar_Verified,
    1: Verified_Details?.Is_Pan_Verified,
    2: Verified_Details?.Is_Bank_Verified,
    3: Verified_Details?.Is_Document_Verified,
  });

  useEffect(() => {
    setCompleted({
      0: Verified_Details?.Is_Aadhar_Verified,
      1: Verified_Details?.Is_Pan_Verified,
      2: Verified_Details?.Is_Bank_Verified,
      3: Verified_Details?.Is_Document_Verified,
    });
  }, [Verified_Details]);

  useEffect(() => {
    if (!edit) {
      const newStep = children.findIndex((child, index) => !completed[index]);
      if (newStep === -1) {
        setActiveStep(0);
      } else setActiveStep(newStep);
    }
  }, [edit]);

  const [childModalPanel, setChildModalPanel] = useState(Array(children.length).fill(false));

  const totalSteps = () => {
    return children.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? children.findIndex((child, index) => !(index in completed))
        : activeStep + 1;

    setActiveStep(newActiveStep > totalSteps() - 1 ? 0 : newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleComplete = () => {
    if (activeStep === 1) {
      handlePanVerify(handleToggleLoading, handleNext);
    } else if (activeStep === 2) {
      handleBankVerifiy(handleToggleLoading, handleNext);
    } else if (activeStep === totalSteps() - 1) {
      handleClickSave(handleToggleLoading, handleNext);
    }
    setChildModalPanel(childModalPanel.map((bool, index) => (activeStep === index ? !bool : bool)));
  };

  const handleToggleLoading = () => {
    setLoading((prev) => !prev);
  };

  // Kiran Aadhaar
  // 348374426697

  return (
    <div className="stepper-container">
      <div className="stepper-nav">
        {children.map((child, index) => (
          <>
            <div
              className={`${index === activeStep || completed[index] ? "step-nav-item-active" : "step-nav-item"}`}
              style={{
                backgroundColor: index === activeStep && "rgb(234, 236, 238)",
              }}
            >
              <span
                className={`${index === activeStep || completed[index] ? "step-nav-icon-active" : "step-nav-icon"}`}
              >
                {completed[index] ? <BsCheckLg /> : index + 1}
              </span>
              <span>{child.props.title.split("_").join(" ")}</span>
            </div>
            <hr style={{ border: completed[index] && "1px solid red" }} />
          </>
        ))}
      </div>

      <div className="children" style={{ height }}>
        {children[activeStep]}

        {AllUserProfileModal[children[activeStep]?.props?.title]?.renderModal({
          open: childModalPanel[activeStep],
          modalHeading: `${children[activeStep].props.title.split("_").join(" ")} Confirmation`,
          modalClassName: "confirmationModal",
          contentClassName: "confirmationModal-content",
          onClose: () =>
            setChildModalPanel(childModalPanel.map((bool, index) => (activeStep === index ? !bool : bool))),
        })}
      </div>

      <div className="stepper-btn-container">
        <div></div>
        <div>
          <button onClick={handleBack} disabled={activeStep === 0 || loading}>
            Back
          </button>
          {!edit ? (
            isLastStep() ? (
              <button onClick={() => handleComplete()} disabled={loading}>
                {loading ? <Spinner /> : "Update"}
              </button>
            ) : completed[activeStep] ? (
              <button onClick={handleNext}>Next</button>
            ) : (
              <button onClick={() => handleComplete()} disabled={loading}>
                Verify And Next
              </button>
            )
          ) : (
            <button onClick={handleNext}>Next</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Stepper;

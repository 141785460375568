import React from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import { useState } from "react";
import "./UserProfileModal.scss";
import ModalCard from "../ModalCard/ModalCard";
import { useMemo } from "react";
import Spinner from "../../Spinner/Spinner";
import InputBox from "../../InputBox/InputBox";
import { gql, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

const BankAccount_Verify = gql`
  mutation ($bankAccountVerifyUserInput2: Kyc_Input) {
    BankAccount_Verify(UserInput: $bankAccountVerifyUserInput2) {
      message
    }
  }
`;

const BankVerificationModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const [formDetails, setFormDetails] = useState({
    Acc_Number: "",
    IFSC: "",
  });
  const [verifyBank, { loading: bankVerifyLoading }] = useMutation(BankAccount_Verify);

  const handleFormChange = (event) => {
    const { value, name, type } = event.target;
    const inputValue = type === "number" ? Number(value) : value;
    setFormDetails({
      ...formDetails,
      [name]: name === "acc_no" ? inputValue.replace(/\D/g, "") : inputValue.toUpperCase(),
    });
  };
  const {
    fielddata: { Verified_Details },
  } = useSelector((store) => store.editProfile);

  const isFormFilled = useMemo(
    () =>
      Object.values(formDetails).every((value) => formDetails.Acc_Number.length >= 9 && formDetails.IFSC.length === 11),
    [formDetails]
  );

  const handleBankVerifiy = async (bankDetails) => {
    await verifyBank({
      variables: {
        bankAccountVerifyUserInput2: bankDetails,
      },
    })
      .then((response) => {
        toast.success("Bank Details Verified!");
        onClose();
        dispatch({
          type: "editProfileData",
          payload: {
            key: "Verified_Details",
            data: {
              name: "Is_Bank_Verified",
              value: true,
            },
          },
        });
      })
      .catch(({ message }) => {
        if (message === "FAILURE INVALID BENEFICIARY MOBILE NO/MAS") {
          toast.error("Invalid bank details!");
        }
        console.log("Error:", message);
      });
  };

  return (
    <>
      <div className="modal-body">
        <ModalCard>
          <InputBox
            type="number"
            placeholder="Enter Bank Ac.no"
            maxNo={18}
            minlength={9}
            name="Acc_Number"
            value={formDetails.Acc_Number}
            handleInputChange={handleFormChange}
            handleBlur={() => {}}
            autoComplete="off"
          />
          <InputBox
            type="text"
            placeholder="Enter ISFC code"
            maxNo={11}
            name="IFSC"
            value={formDetails.IFSC}
            handleInputChange={handleFormChange}
            handleBlur={() => {}}
            autoComplete="off"
          />
        </ModalCard>
      </div>
      <PortalFooter>
        {!Verified_Details.Is_Bank_Verified && (
          <Button
            text={bankVerifyLoading ? <Spinner /> : "Submit"}
            disabled={!isFormFilled}
            className={isFormFilled ? "acceptBtn" : "disabledBtnBank"}
            handleClick={() => handleBankVerifiy(formDetails)}
          />
        )}
        <Button text="Ok" className="rejectBtn" handleClick={onClose} />
      </PortalFooter>
    </>
  );
};

export default BankVerificationModal;

import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { AccountTypeReducer } from "./AccountType/AccountType.reducer";
import { AddLoadReducer } from "./AddloadFormData/Addload.reducer";
import { ArrCountsReducer } from "./ArrCounts/ArrCount.reducer";
import { InputDropdownState } from "./InputDropdownState/InputDropdownState.reducer";
import { InputTextStateReducer } from "./InputTextState/InputTextState.reducer";
import { LoadDataReducer } from "./LoadData/LoadData.reducer";
import { NavbarStateReducer } from "./NavbarState/NavbarState.reducer";
import { UserInfoReducer } from "./UserInfo/UserInfo.reducer";
import { UserTokenReducer } from "./UserToken/UserToken.reducer";
import { CustomSelectValueReducer } from "./CustomSelectValue/customSelectValue.reducer";
import { EditProfileReducer } from "./EditProfile/EditProfile.reducer";
import { FilterDataReducer } from "./FilterBox/FilterData.reducer";
import StoreFiles from "./StoreFiles/StoreFiles";
import { AccountBalanceReducer } from "./AccountBalance/AccountBalance";
import { AccountStatementSearchReducer } from "./AccountStatementSearch/AccountStatementSearch.reducer";
import { KYCProcessReducer } from "./KYCReducer/KYCReducer";
import { SignupDataReducer } from "./SignupData/SignupData";

const rootReducer = combineReducers({
  accType: AccountTypeReducer,
  sidebarOpen: NavbarStateReducer,
  dropdownOpen: InputDropdownState,
  inputText: InputTextStateReducer,
  Addload: AddLoadReducer,
  userToken: UserTokenReducer,
  userInfo: UserInfoReducer,
  loadData: LoadDataReducer,
  arrCounts: ArrCountsReducer,
  customSelectValue: CustomSelectValueReducer,
  editProfile: EditProfileReducer,
  filterData: FilterDataReducer,
  storeFiles: StoreFiles,
  accBalance: AccountBalanceReducer,
  accountStatementSearch: AccountStatementSearchReducer,
  kycData: KYCProcessReducer,
  signupData: SignupDataReducer,
});

// const createCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// export const store = createStore(rootReducer, createCompose(applyMiddleware(thunk)));


export const store = createStore(rootReducer, applyMiddleware(thunk));
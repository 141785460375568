import React from 'react'
import "./TR.scss"

function TR({className,children}) {
  return (
   <tr className={className}>
    {children}
   </tr>
  )
}

export default TR

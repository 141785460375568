import { gql } from "@apollo/client";

export const CREATE_COMMITTED_VEHICLE = gql`
mutation CreateCommittedVehicles($createInput: CommittedVehiclesInput!) {
  createCommittedVehicles(createInput: $createInput) {
    _id
    Vehicle_Number
    Vehicle_Capacity
  }
}
`;
import React, { useEffect, useState } from "react";
import EventTimeFormat from "../../../../../../Components/Common/EventTimeFormat/EventTimeFormat";
import "./DateDetails.scss";

function DateDetailsBox({
  Booking_Confirmed_Time,
  Vehicle_Reached_At_Loading_Point_Time,
  Loading_Completed_Time,
  Advance_Processed_Time,
  Vehicle_Reach_At_Unloading_Point_Time,
  Unloading_Completed_Time,
  Clear_Delevery_Confirmation_Time,
  POD_Submitted_To_LP_Time,
  POD_Collected_From_SP_Time,
}) {
  const [DateDetailsArr, setDateDetailsArr] = useState([]);

  useEffect(() => {
    setDateDetailsArr([
      Booking_Confirmed_Time,
      Vehicle_Reached_At_Loading_Point_Time,
      Loading_Completed_Time,
      Advance_Processed_Time,
      Vehicle_Reach_At_Unloading_Point_Time,
      Unloading_Completed_Time,
      Clear_Delevery_Confirmation_Time,
      POD_Submitted_To_LP_Time,
      POD_Collected_From_SP_Time,
    ]);
  }, [
    Booking_Confirmed_Time,
    Vehicle_Reached_At_Loading_Point_Time,
    Loading_Completed_Time,
    Advance_Processed_Time,
    Vehicle_Reach_At_Unloading_Point_Time,
    Unloading_Completed_Time,
    Clear_Delevery_Confirmation_Time,
    POD_Submitted_To_LP_Time,
    POD_Collected_From_SP_Time,
  ]);

  return (
    <div className="dateDetailsEachBox">
      <div className="heading">
        <p className="headingText">Event Time</p>
      </div>
      <div className="belowbox">
        {DateDetailsArr?.map((el) => (
          <div className="dateDetailsTextDiv" key={el}>
              <EventTimeFormat date={el} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default DateDetailsBox;

import React, { useEffect, useState } from "react";
import "./FileUploadBox.scss";
import FileUpload from "./FileUpload/FileUpload";
import Cookies from "js-cookie";

function FileUploadBox({
  err,
  UploadFields,
  allFieldsData,
  format,
  sendFilesFun,
  disabled,
  pageName
}) {
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const [files, setFiles] = useState({});
  const [isFilesUploaded, setIsFilesUploaded] = useState(false);

  useEffect(() => {
    sendFilesFun(files);
  }, [files]);

  useEffect(() => {
    const isUploaded = Object.values(files).every((file) => !!file);
    setIsFilesUploaded(isUploaded);
  }, [files]);
  // console.log(disabled);

  return (
    <>
      {UploadFields.map((e) => {
        if ("forView" in e && !e.forView.includes(accInfo.accType)) return null;

        return (
          <div key={e.id}>
            <FileUpload
              format={format}
              fileName={e.lable}
              fieldName={e.name}
              allFieldsData={allFieldsData}
              disabled={disabled}
              e={e}
              sendFile={(link) => {
                setFiles((prevFiles) => ({
                  ...prevFiles,
                  [e.name]: link,
                }));
              }}
              pageName={pageName}
            />
          </div>
        );
      })}

      {isFilesUploaded && err && (
        <p className="errText">Please Provide All Documents</p>
      )}
    </>
  );
}

export default FileUploadBox;

import React from "react";
import "./SelectTag.scss";


function SelectTag({
  className,
  id,
  label,
  options,
  placeholder,
  handleChange,
  required,
  err
}) {
  return (
    <div className="selecttagbox">
      <label className="label">{label}</label>
      <select
        id={id}
        className={className}
        placeholder={placeholder}
        onChange={handleChange}
        required={required}
      >
        <option value="">Select</option>
        {options?.map((el) => (
          <option key={el} value={el}>
            {el}
          </option>
        ))}
      </select>
      <div className="errdiv">
        {err ? <p className="error">{err}</p> : null}
      </div>
    </div>
  );
}

export default SelectTag;

import React, { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./LandingPageNavBar.scss";
import { Link } from "react-router-dom";

const LandingPageNavBar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <header>
      <nav ref={navRef}>
        <Link to="/">
          <div className="imgDiv">
            <img className="Logo" src="./Ease-My-Transport-LOGO-1.jpg" alt="" />
          </div>
        </Link>

        <div style={{ "margin-right": "-14px" }}>
          {/* <Link to={"/signup"}>
            <button className="Navbar_Button">Sign Up</button>
          </Link> */}
          <Link to={"/login"}>
            <button className="Navbar_Button"> Login / Signup </button>
          </Link>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </div>
      </nav>
      <button className="nav-btn" onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
};

export default LandingPageNavBar;

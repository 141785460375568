import React, { useEffect, useState } from "react";
import { awardedAcceptedData } from "../../../../db/cnfModalData.config";
import Button from "../../Button/Button";
import InputBox from "../../InputBox/InputBox";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Reactportal from "../../../SpComponents/Reactportal/Reactportal";
import { useFormik } from "formik";
import CustomTimePicker from "../../CustomTimePicker/CustomTimePicker";
import CustomSelect from "../../CustomSelect/CustomSelect";
import { useMutation, useQuery } from "@apollo/client";
import CnfModal from "../../../SpComponents/CnfModal/CnfModal";
import { toast } from "react-toastify";
import socket from "../../../../CommonFunction/Socket/socketClient";
import GetVehicleNo from "../../GetVehicleNo/GetVehicleNo";
import "./AwardedAcceptedModal.scss";
import { EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE } from "../../../../gql/editCommitedVehiclesWithoutVerifyBalance";
import { GET_VEHICLES_DATA } from "../../../../gql/getVehicles";

function AwardedAcceptedModal({ ID, onClose, RefetchUpdated, loadId }) {
  const [isEditCommittedVehicles, setIsEditCommittedVehicles] = useState(false);
  const [editCommittedVehicles, { loading: editCommittedVehiclesLoading }] = useMutation(
    EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE
  );
  const { data: getVehiclesDataD } = useQuery(GET_VEHICLES_DATA);

  const [vehiclesDropDown, setVehiclesDropDown] = useState([]);

  useEffect(() => {
    if (getVehiclesDataD && getVehiclesDataD.GetVehicleManagementDetailsByUserId) {
      setVehiclesDropDown(getVehiclesDataD.GetVehicleManagementDetailsByUserId);
    }
  }, [getVehiclesDataD]);

  let vehicleNumbersData;

  vehicleNumbersData = vehiclesDropDown?.map(({ Vehicle_No, _id }) => ({
    value: Vehicle_No,
    label: Vehicle_No,
    id: _id,
  }));

  // console.log("awaesedee", vehicleNumbersData);
  const [cnfModalTwo, setCnfModalTwo] = useState(false);
  const [formData, setFormData] = useState({});

  const initialValues = {
    Vehicle_Number: "",
    Driver_Name: "",
    Driver_Number: "",
    Reporting_Time: "",
  };

  const validate = (values) => {
    // console.log(values, "validate");
    setFormData(values);

    let errors = {};

    if (!values.Driver_Name) {
      errors.Driver_Name = "Driver name required";
    } else if (!values.Driver_Number) {
      errors.Driver_Number = "Driver number required";
    } else if (!/^\d{10}$/.test(values.Driver_Number)) {
      errors.Driver_Number = "Driver number must be 10 digits";
    } else if (!values.Reporting_Time) {
      errors.Reporting_Time = "Reporting time required";
    }

    return errors;
  };

  const onSubmit = async (values) => {
    // console.log(values, "on Submit");
    await updateCommittedVehicles(ID);
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });
  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };
  async function updateCommittedVehicles(id) {
    delete formData.Vehicle_Number;

    await editCommittedVehicles({
      variables: {
        editCommittedVehiclesWithoutVerifyUserWithBalanceId: id,
        userInput: {
          ...formData,
          Is_Accepted: true,
          Is_Rejected: false,
          Current_Stage: "Booking Confirmed",
          Booking_Confirmed: true,
          Booking_Confirmed_Time: new Date(),
        },
      },
    })
      .then(() => {
        onClose();
        toast.success("Awarded Vehicle Accepted Successfully");
        socket.emit("EditConfirmVechileswithId", loadId);
        RefetchUpdated();
      })
      .catch((err) => toast.error(err.message));
  }

  const handleValidation = () => {
    formik.setTouched({
      Vehicle_Number: true,
      Driver_Name: true,
      Driver_Number: true,
      Reporting_Time: true,
    });
    if (formik.isValid) {
      // Check if there are no formik errors
      if (Object.keys(formik.errors).length === 0) {
        setCnfModalTwo(true);
      }
    } else {
      formik.setTouched({
        Vehicle_Number: true,
        Driver_Name: true,
        Driver_Number: true,
        Reporting_Time: true,
      });
    }
  };

  // console.log(formik.errors, "erros2");

  useEffect(() => {
    if (editCommittedVehiclesLoading) setIsEditCommittedVehicles(editCommittedVehiclesLoading);
  }, [editCommittedVehiclesLoading]);

  return (
    <div className="awardedAcceptedModal">
      <form onSubmit={formik.handleSubmit}>
        <div className="eachModalContentDiv">
          <p className="modalText">Vehicle Number:</p>
          <CustomSelect
            options={vehicleNumbersData}
            className="react-select"
            name="Vehicle_Number"
            id="Vehicle_Number"
            type="inputdrop"
            value={<GetVehicleNo vehicleNoId={formik.values["Vehicle_Number"]} />}
            handleCustomSelectChange={formik.handleChange}
            handleformikdata={handleformikdata}
            err={formik.errors.Vehicle_Number}
          />
        </div>
        <div className="eachModalContentDiv">
          <p className="modalText">Driver Name* :</p>
          <InputBox
            className={"cnfQtyInput"}
            type="text"
            name="Driver_Name"
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            autoComplete={"off"}
            autoCapital={true}
            err={formik.errors.Driver_Name}
            touched={formik.touched.Driver_Name ? formik.touched.Driver_Name : false}
          />
        </div>
        <div className="eachModalContentDiv">
          <p className="modalText">Driver Number* :</p>
          <InputBox
            className={"cnfQtyInput no-spinners"}
            type="number"
            name="Driver_Number"
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            autoComplete={"off"}
            maxNo={10}
            err={formik.errors.Driver_Number}
            touched={formik.touched.Driver_Number ? formik.touched.Driver_Number : false}
          />
        </div>
        <div className="eachModalContentDiv lastDiv">
          <p className="modalText">Reporting Time* :</p>
          <CustomTimePicker
            name={"Reporting_Time"}
            handleCustomTimePickerChange={formik.handleChange}
            handleformikdata={handleformikdata}
            err={formik.errors.Reporting_Time}
            touched={formik.touched.Reporting_Time ? formik.touched.Reporting_Time : false}
          />
        </div>

        <PortalFooter>
          <Button text="Confirm" type="button" className={"acceptBtn"} handleClick={handleValidation} />

          <Reactportal
            open={cnfModalTwo}
            modalHeading="Bid Confirmation"
            onClose={() => setCnfModalTwo(false)}
            modalClassName={"cnfModal"}
            contentClassName={"cnfModal-content"}
          >
            <CnfModal
              newBidDetails={formik.handleSubmit}
              listArr={awardedAcceptedData}
              onClose={() => setCnfModalTwo(false)}
              loading={isEditCommittedVehicles}
            />
          </Reactportal>

          <Button text="Back" className={"rejectBtn"} handleClick={onClose} />
        </PortalFooter>
      </form>
    </div>
  );
}

export default AwardedAcceptedModal;

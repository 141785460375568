import React, { useEffect, useState } from "react";
import PortalFooter from "../../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../../Button/Button";
import FileUploadBox from "../../../../LpComponents/FileUploadBox/FileUploadBox";
import { vehicleReachedAtUnloadingPointFileUpload } from "../../../../../db/FileUploadConfig/FileUpload.config";
import { useFormik } from "formik";
import "./VehicleReachedAtUnloadingPointModal.scss";
import Spinner from "../../../Spinner/Spinner";

function VehicleReachedAtUnloadingPointModal({
  editCommittedVehiclesData,
  onClose,
  loading,
}) {
  const [isModalLoading, setIsModalLoading] = useState(false);

  let initialValues = {
    Entry_Gate_Img: "",
  };

  const validate = (values) => {
    let errors = {};

    // if (!values.Entry_Gate_Img) {
    //   errors.Entry_Gate_Img = "Please upload entry gate image";
    // }

    return errors;
  };

  const onSubmit = (values) => {
    // console.log("onSubmit", values);
    editCommittedVehiclesData(values);
    onClose();
  };

  // console.log(isModalLoading,"isModalLoadingisModalLoadingisModalLoading")
  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  useEffect(() => {
    setIsModalLoading(loading);
  }, [loading]);

  const updateFilesStore = (newFiles) => {
    const updatedFilesStore = { ...initialValues };

    Object.keys(newFiles).forEach((key) => {
      formik.setFieldValue(key, newFiles[key]);
      if (updatedFilesStore.hasOwnProperty(key)) {
        updatedFilesStore[key] = newFiles[key];
      }
    });

    initialValues = {
      ...updatedFilesStore,
    };
  };

  return (
    <div className="VRAUPModalDiv">
      <form onSubmit={formik.handleSubmit}>
        <div className="eachContentDiv">
          <p className="contentMainHeading">Documents -</p>
        </div>
        <div className="fileUploadDiv">
          <FileUploadBox
            UploadFields={vehicleReachedAtUnloadingPointFileUpload}
            format={true}
            sendFilesFun={(e) => updateFilesStore(e)}
            err={formik.errors.Entry_Gate_Img}
            pageName={"OngoingPage"}

          />
        </div>

        <PortalFooter>
          {isModalLoading ? (
            <Button
              text={<Spinner />}
              className="loadingAcceptBtn"
              type="button"
            />
          ) : (
            <Button text={"Confirm"} className="acceptBtn" type="Submit" />
          )}

          <Button text={"Cancel"} className="rejectBtn" handleClick={onClose} />
        </PortalFooter>
      </form>
    </div>
  );
}

export default VehicleReachedAtUnloadingPointModal;

const initial = {
  id: "",
  accType: "",
  firstName: "",
  FullName: "",
  lastName: "",
  email: "",
  mobileNumber: 0,
};

export function UserInfoReducer(state = initial, { type, payload }) {
  switch (type) {
    case "set_userInfo": {
      return {
        ...state,
        id: payload.id,
        accType: payload.accType,
        firstName: payload.firstName,
        FullName: payload.FullName,
        lastName: payload.lastName,
        email: payload.email,
        mobileNumber: payload.mobileNumber,
      };
    }
    default: {
      return state;
    }
  }
}

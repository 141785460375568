import { gql } from "@apollo/client";

export const load_by_search_open = gql`
  query getAllLoadsWithSearchForSp($userInput: SearchInputForLoads) {
    getAllLoadsWithSearchForSp(userInput: $userInput) {
      _id
      Unique_Id
      UserId
      Load_Post_Type
      Auction_Date
      Auction_Time
      Dangerous_Goods
      Exact_Content
      Expected_Price
      Target_Price
      From_City
      Load_Live_Duration
      Load_Manager
      Loading_Area
      Loading_By
      Loading_City
      Loading_Date
      Loading_Landmark
      Loading_Name
      Loading_Pincode
      Loading_Scope
      Loading_State
      Loading_Time
      Loading_Address_Line_1
      Loading_Address_Line_2
      Material_Category
      Material_Dimension
      Material_Dimension_In
      Material_Insurence
      Material_Image
      Material_Image_2
      Material_Image_3
      No_Of_Vehicels_Required
      No_Of_Vehicels_Loading_Completed
      Notes
      Over_Dimension_Cargo
      Payment_Type
      Rate_per
      To_City
      Total_Weight
      Unloading_Area
      Unloading_City
      Unloading_Landmark
      Unloading_Name
      Unloading_Pincode
      Unloading_Scope
      Unloading_State
      Unloading_Address_Line_1
      Unloading_Address_Line_2
      Vehicle_Type_String
      Weight_Category_String
      Vehicle_Type {
        _id
        Unique_Id
        Vehicle_Type
        User_ID
        Search_Value
        createdAt
        updatedAt
      }
      Weight_Category {
        _id
        Unique_Id
        Name
        Search_Value
        Vehicle_Type
        User_ID
        createdAt
        updatedAt
      }
      Length_Catagory {
        _id
        length
        Search_Value
        createdAt
        updatedAt
      }
      Is_Live
      Is_Auction_Started
      Is_Archive
      BidDetails {
        _id
        LoadId
        UserId
        Price
        Unique_Id
        No_Of_Vehicle
        Confirm_Qty
        Committed_Vehicles
        Bid_Note
        Bid_History
        createdAt
        updatedAt
      }
      Total_Weight_In
      createdAt
      updatedAt
    }
  }
`;

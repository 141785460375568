const initial = {
  accType: "",
  mobileNumber: "",
};

export function SignupDataReducer(state = initial, { type, payload }) {
  switch (type) {
    case "set_signupdata": {
      return {
        ...state,
        accType: payload.accType,
        mobileNumber: payload.mobileNumber,
      };
    }
    default: {
      return state;
    }
  }
}

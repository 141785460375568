import React from "react";
import ReactDom from "react-dom";
import PortalHeader from "./PortalHeader";
import "./Reactportal.scss";

export default function Reactportal({ open, modalClassName, contentClassName, modalHeading, children, onClose }) {
  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <div
        className={open ? `${modalClassName} boxcenter` : "modal boxcenter"}
        // onClick={onClose}
      >
        <div className={contentClassName} onClick={(e) => e.stopPropagation()}>
          <PortalHeader modalHeading={modalHeading} onClose={onClose} />
          <div>{children}</div>
        </div>
      </div>
    </>,
    document.getElementById("modal-root")
  );
}

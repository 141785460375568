import React from "react";
import InputBox from "../../../../../../../Components/Common/InputBox/InputBox";
import "./EachStatusBox.scss";

function EachStatusBox({
  status,
  statusName,
  showLoadingCompletedDoc,
  showClaim,
  setOpenClaimViewBox,
  setOpenLoadingCompletedDocs,
  setOpenVehicleReachedAtUnloadingPointDocs,
  setUnloadingCompletedDocs,
  showVehicleReachedAtUnloadingPointDoc,
  showUnloadingCompletedDoc,
  Loading_Completed,
  Vehicle_Reach_At_Unloading_Point,
  Unloading_Completed,
  Clear_Delevery_Confirmation,
}) {
  return (
    <div className="statusBoxLP">
      <InputBox
        className="stausBoxLPCheckbox"
        type="checkbox"
        checked={status}
        handleInputChange={() => console.log()}
      />
      <p className="contentText">{statusName}</p>
      {showLoadingCompletedDoc && Loading_Completed && (
        <div>
          <p
            className="showText"
            onClick={() => setOpenLoadingCompletedDocs(true)}
          >
            View
          </p>
        </div>
      )}
      {showVehicleReachedAtUnloadingPointDoc &&
        Vehicle_Reach_At_Unloading_Point && (
          <div>
            <p
              className="showText"
              onClick={() => setOpenVehicleReachedAtUnloadingPointDocs(true)}
            >
              View
            </p>
          </div>
        )}
      {showUnloadingCompletedDoc && Unloading_Completed && (
        <div>
          <p
            className="showText"
            onClick={() => setUnloadingCompletedDocs(true)}
          >
            View
          </p>
        </div>
      )}
      {showClaim && Clear_Delevery_Confirmation && (
        <div>
          <p className="showText" onClick={() => setOpenClaimViewBox(true)}>
            View
          </p>
        </div>
      )}
    </div>
  );
}

export default EachStatusBox;

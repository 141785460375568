import ColumnFilter from "../../../../../../Components/Common/ReactTable/ReactTableComponents/ColumnFilter";

export const COLOUMNS = [
  {
    Header: "Name",
    accessor: "FullName",
    Cell: ({ row }) => {
      return `${row.original.FullName ? row.original.FullName : row.original.firstName}`;
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Contact Number",
    accessor: "mobileNumber",
    Filter: ColumnFilter,
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ row }) => {
      return `${row.original.email ? row.original.email : "-"}`;
    },
    Filter: ColumnFilter,
  },
  {
    Header: "Is Active?",
    accessor: "Is_Active",
    Filter: "",
  },
  {
    Header: "Edit",
    accessor: "edit",
    Filter: "",
  },
];

import React, { useEffect, useState } from "react";
import "./ProfilePageDashboard.scss";
import { FaUserAlt, FaUserCog } from "react-icons/fa";
import { RiBuilding2Fill } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import { useQuery } from "@apollo/client";
import { get_coustomer } from "../../../../gql/getCoustomer";

function ProfilePageDashboard() {
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const [isAccVerified, setIsAccVerified] = useState("");
  const { data } = useQuery(get_coustomer);

  useEffect(() => {
    if (data && data.AccountSearchFromCookies) {
      setIsAccVerified(data.AccountSearchFromCookies.Is_Verified);
    }
  }, [data]);

  return (
    <div className="dashboard">
      {accInfo.Type_Of_User === "Root_User" ? (
        <>
          <NavLink to="/profile-settings/" className={({ isActive }) => (isActive ? "activePage" : "navlink")}>
            <div className="dashboardComponent boxcenter">
              <FaUserAlt className="profileIcon" />
              <p className="dashboardText">Profile</p>
            </div>
          </NavLink>
          {isAccVerified && (
            <NavLink
              to="/profile-settings/user-management"
              className={({ isActive }) => (isActive ? "activePage" : "navlink")}
            >
              <div className="dashboardComponent boxcenter">
                <FaUserCog className="profileIcon" />
                <p className="dashboardText">User Management</p>
              </div>
            </NavLink>
          )}
        </>
      ) : null}
      <NavLink to="/profile-settings/user-compnay" className={({ isActive }) => (isActive ? "activePage" : "navlink")}>
        <div className="dashboardComponent boxcenter">
          <RiBuilding2Fill className="profileIcon" />
          <p className="dashboardText">Company</p>
        </div>
      </NavLink>
    </div>
  );
}

export default ProfilePageDashboard;

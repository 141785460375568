export const NotificationData = [
  {
    id: 1,
    name: "Add User",
    fields: [
      {
        id: Math.random() * 10,
        name: "FullName",
        lable: "User Name*",
        type: "text",
        inputtype: "input",
        min: 2,
        max: Infinity,
        userdata: true,
        capital: true,
      },
      {
        id: Math.random() * 10,
        name: "mobileNumber",
        lable: "Mobile Number*",
        type: "number",
        inputtype: "input",
        verify: true,
        min: 10,
        max: 10,
        userdata: true,
        maxNo: 10,
      },
      // {
      //   id: Math.random() * 10,
      //   name: "email",
      //   lable: "Email Address",
      //   type: "email",
      //   inputtype: "input",
      //   min: 3,
      //   max: Infinity,
      //   userdata: true,
      // },
      // {
      //   id: Math.random() * 10,
      //   name: "password",
      //   lable: "Password",
      //   type: "password",
      //   min: 2,
      //   max: Infinity,
      //   userdata: true,
      // },
      {
        id: Math.random() * 10,
        name: "Is_Active",
        lable: "Is Active",
        type: "checkbox",
        emailnot: true,
      },
    ],
  },
  {
    id: 2,
    name: "Email Notification",
    fields: [
      {
        id: Math.random() * 10,
        name: "Email_Load_Post",
        lable: "Load Post",
        type: "checkbox",
        emailnot: true,
      },
      {
        id: Math.random() * 10,
        name: "Email_Auction_Start",
        lable: "Auction Start",
        type: "checkbox",
        emailnot: true,
      },
      {
        id: Math.random() * 10,
        name: "Email_Allotment",
        lable: "Allotment",
        type: "checkbox",
        emailnot: true,
      },
    ],
  },
  {
    id: 3,
    name: "SMS Notification",
    fields: [
      {
        id: Math.random() * 10,
        name: "SMS_Load_Post",
        lable: "Load Post",
        type: "checkbox",
        phonenot: true,
      },
      {
        id: Math.random() * 10,
        name: "SMS_Auction_Start",
        lable: "Auction Start",
        type: "checkbox",
        phonenot: true,
      },
      {
        id: Math.random() * 10,
        name: "SMS_Allotment",
        lable: "Allotment",
        type: "checkbox",
        phonenot: true,
      },
    ],
  },
];

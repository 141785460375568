const unloadingCompletedModalData = [
  {
    contentHeading: "Holding Charges (₹)",
    type: "number",
    name: "Holding_Charges",
    autoComplete: "off",
    placeholder: "",
    by: "lp",
  },
  {
    contentHeading: "Hamali Charges (₹)",
    type: "number",
    name: "Hamali_Charges",
    autoComplete: "off",
    placeholder: "",
    by: "lp",
  },
  {
    contentHeading: "Extra Running/Km's (₹)",
    type: "number",
    name: "Extra_Running",
    autoComplete: "off",
    placeholder: "",
    by: "lp",
  },
  {
    contentHeading: "Challan Charges (₹)",
    type: "number",
    name: "Challan_Charges",
    autoComplete: "off",
    placeholder: "",
    by: "lp",
  },
  {
    contentHeading: "Other Charges (₹)",
    type: "number",
    name: "Other_Charges",
    autoComplete: "off",
    placeholder: "",
    by: "lp",
  },
  {
    contentHeading: "Other Charges Reason*",
    type: "text",
    name: "Other_Charges_Reason",
    autoComplete: "off",
    placeholder: "Reason",
    by: "lp",
  },
  {
    contentHeading: "Shortage Charges (₹)",
    type: "number",
    name: "Shortage_Charges",
    autoComplete: "off",
    placeholder: "",
    by: "sp",
  },
  {
    contentHeading: "Damage Charges (₹)",
    type: "number",
    name: "Damage_Charges",
    autoComplete: "off",
    placeholder: "",
    by: "sp",
  },
  {
    contentHeading: "Narration*",
    type: "text",
    name: "Narration",
    autoComplete: "off",
    placeholder: "",
    by: "sp",
  },
];

const addToAccountModalData = [
  {
    contentHeading: "Type Of Payment*",
    type: "inputdrop",
    name: "Type_Of_Payment",
    autoComplete: "off",
    placeholder: "",
    dropdowncontent: ["UPI", "IFT", "NEFT", "IMPS", "RTGS"],
  },
  {
    contentHeading: "UTR Number*",
    type: "text",
    name: "UTR_Number",
    autoComplete: "off",
    placeholder: "",
  },
  {
    contentHeading: "Amount*",
    type: "number",
    name: "Amount",
    autoComplete: "off",
    placeholder: "",
  },
];

export { unloadingCompletedModalData, addToAccountModalData };

import Tippy from "@tippy.js/react";
import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import emt_logo from "../../../Assets/Logo/logoemt.png";
import InputBox from "../InputBox/InputBox";
import { useFormik } from "formik";
import Button from "../Button/Button";
import { useLazyQuery } from "@apollo/client";
import { OTP_REQUEST } from "../../../gql/otpRequest";
import { toast } from "react-toastify";
import SpinnerIndicator from "../SpinnerIndicator/SpinnerIndicator";
import CheckOtp from "./CheckOtp";

function ForgetPassword({ loginType, setForgetPassState, setLogin, content }) {
  const [sessionId, setSessionId] = useState("");
  const [checkOtpState, setCheckOtpState] = useState(false);
  const [otpRequest, { loading, data, error }] = useLazyQuery(OTP_REQUEST, {
    fetchPolicy: "no-cache",
  });
  const [username, setUsername] = useState(content);

  useEffect(() => {
    if (data) {
      setSessionId(data.sendOtpForPassword.sessionId);
      toast.success(data.sendOtpForPassword.message);
      setCheckOtpState(true);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const initialValues = {
    username: username,
  };

  const validate = (values) => {
    let errors = {};

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const mobileRegex = /^[0-9]{10}$/;

    if (values.username === "") {
      errors.username = "Mobile Number or Email is required";
    } else if (!emailRegex.test(values.username) && !mobileRegex.test(values.username)) {
      errors.username = "Invalid Mobile Number or Email format";
    }

    return errors;
  };

  const onSubmit = (values) => {
    otpRequest({
      variables: {
        emailOrNumber: values.username,
        accType: loginType,
      },
    });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  function handleNavigate() {
    if (checkOtpState) {
      setCheckOtpState(false);
    } else {
      setForgetPassState(false);
      setLogin(false);
    }
  }

  return (
    <div className={loginType === "Supply Partner Account" ? "bgImgDivLoginSP" : "bgImgDivLoginLP"}>
      <div className="loginpage">
        <div className="loginbox">
          <div className="boxcenter">
            <img className="emtlogo" src={emt_logo} alt="emtlogo" />
          </div>
          <div className="backDiv">
            <Tippy content={checkOtpState ? "Back" : "Back to login"} placement="bottom">
              <div className={"IconsDiv"} onClick={() => handleNavigate()}>
                <BiArrowBack className="backFilters" />
              </div>
            </Tippy>
          </div>
          {checkOtpState ? (
            <CheckOtp sessionId={sessionId} setLogin={setLogin} loginType={loginType} content={username} />
          ) : (
            <div className="forgetPassFormDiv">
              <p className="forgetPassFormHeading">Forgot Password</p>
              <p className="forgetPassFormSubHeading">
                Enter your mobile number or email for the verification process, we will send you 4 digit OTP.{" "}
              </p>
              <form onSubmit={formik.handleSubmit} autoComplete="off">
                <div className="inputboxdiv">
                  <InputBox
                    className={"filterInputBox  no-spinners"}
                    placeholder={"Enter Mobile Number or Email"}
                    id={"username"}
                    name={"username"}
                    value={username}
                    handleInputChange={(e) => {
                      formik.handleChange(e);
                      setUsername(e.target.value);
                    }}
                    err={formik.errors.username}
                    formtype="requestOtp"
                    touched={formik.touched.username ? formik.touched.username : false}
                    autoComplete={"none"}
                  />
                </div>

                <div className="submitBtnDiv">
                  <Button
                    className={loading ? "disabledBtnBank" : "acceptBtn"}
                    type={"submit"}
                    text={loading ? <SpinnerIndicator /> : "Send OTP"}
                    disabled={loading}
                  />
                </div>
              </form>
            </div>
          )}

          <p className="helplineNoLoginText">
            Helpline No -<br></br>
            7066664747 / 7066669090
          </p>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;

import React, { useEffect, useState } from "react";
import emt_logo from "../../../Assets/Logo/logoemt.png";
import Tippy from "@tippy.js/react";
import { BiArrowBack } from "react-icons/bi";
import Button from "../Button/Button";
import { useFormik } from "formik";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_USER_MUTATION_LOGIN, GET_LOGIN_OTP } from "../../../gql/login";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import InputBox from "../InputBox/InputBox";
import { MdEmail } from "react-icons/md";
import { FaEye, FaEyeSlash, FaPhoneAlt } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";
import SpinnerIndicator from "../SpinnerIndicator/SpinnerIndicator";
import ForgetPassword from "./ForgetPassword";
import CheckLoginOtp from "./CheckLoginOtp";

function LoginForm({ loginType, setLogin }) {
  const [forgetPassState, setForgetPassState] = useState(false);
  const [withOtpState, setWithOtpState] = useState(false);
  const [checkOtpState, setCheckOtpState] = useState(false);
  const [isEmailInput, setIsEmailInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [sessionId, setSessionId] = useState("");

  const [initialValues, setInitialValues] = useState({
    email: "",
    mobileNumber: "",
    password: "",
  });
  const [login, { loading, error, data }] = useMutation(CREATE_USER_MUTATION_LOGIN);
  const [getOtp, { loading: getOtpLoading, data: getOtpData, error: getOtpError }] = useLazyQuery(GET_LOGIN_OTP, {
    fetchPolicy: "no-cache",
  });

  const dispatch = useDispatch();

  // function emailInputHandler() {
  //   setInitialValues({
  //     email: "",
  //     mobileNumber: "",
  //     password: "",
  //   });
  //   setIsEmailInput(true);
  // }

  // function otpInputHandler() {
  //   setInitialValues({
  //     email: "",
  //     mobileNumber: "",
  //     password: "",
  //   });
  //   setIsEmailInput(false);
  // }

  const postlogin = ({ email, mobileNumber, password }) => {
    withOtpState
      ? login({
          variables: {
            loginInput: {
              email_or_mobilenumber: email || mobileNumber,
              accType: loginType,
              password,
            },
          },
        })
      : getOtp({
          variables: {
            mobileNumber: mobileNumber || email,
            accType: loginType,
          },
        });
  };

  useEffect(() => {
    if (getOtpData) {
      setSessionId(getOtpData.MobileVerificationSendOTP.sessionId);
      toast.success(getOtpData.MobileVerificationSendOTP.message);
      setCheckOtpState(true);
    }
  }, [getOtpData]);

  useEffect(() => {
    if (getOtpError) {
      toast.error(getOtpError.message);
    }
  }, [getOtpError]);

  const navigate = useNavigate();

  // function handleNavigateToSignup() {
  //   navigate("/signup");
  // }

  useEffect(() => {
    if (data) {
      if (data.login.message === "Invalid Credentials!") {
        toast.warn(data.login.message);
      } else {
        Cookies.set("token", data.login.token);
        Cookies.set(
          "accInfo",
          JSON.stringify({
            id: data.login.user.id,
            accType: data.login.user.accType,
            firstName: data.login.user.firstName,
            lastName: data.login.user.lastName,
            FullName: data.login.user.FullName,
            email: data.login.user.email,
            mobileNumber: data.login.user.mobileNumber,
            Type_Of_User: data.login.user.Type_Of_User,
            Root_User: data.login.user.Root_User,
            Is_Active: data.login.user.Is_Active,
            Unique_Id: data.login.user.Unique_Id,
          })
        );
        Cookies.set("accDataId", data.login.AccountData[0]?._id);
        Cookies.set("userAccId", data.login.AccountData[0]?.Unique_Id);
        Cookies.set(
          "accData",
          JSON.stringify({
            Account_Number: data.login.AccountData[0]?.Account_Number,
          })
        );
        dispatch({ type: "set_userInfo", payload: data.login.user });
        dispatch({ type: "set_accType", payload: data.login.user.accType });
        dispatch({ type: "set_userToken", payload: data.login.token });
        if (data.login.user.accType === "Load Partner Account") {
          navigate("/live");
        } else {
          navigate("/open");
        }
        window.location.reload();
        toast.success(data.login.message);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const onSubmit = (values) => {
    // console.log("submit", values);
    postlogin(values);
  };

  const validate = (values) => {
    // console.log("validate", values);
    let errors = {};

    if (!values.email && !values.mobileNumber) {
      errors.email = "Required";
      errors.mobileNumber = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) &&
      (String(values.mobileNumber).length < 10 || String(values.mobileNumber).length > 10)
    ) {
      errors.email = "Invalid Email Format";
      errors.mobileNumber = "Enter Valid 10 Digit Mobile Number";
    } else if (!values.password && withOtpState) {
      errors.password = "Required";
    }

    console.log(errors, "errors");

    return errors;
  };

  function handleForgetPassword() {
    setForgetPassState(true);
  }

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <>
      {forgetPassState ? (
        <ForgetPassword
          loginType={loginType}
          setLogin={setLogin}
          setForgetPassState={setForgetPassState}
          content={formik.values.mobileNumber || formik.values.email}
        />
      ) : (
        <div className={loginType === "Supply Partner Account" ? "bgImgDivLoginSP" : "bgImgDivLoginLP"}>
          <div className="loginpage">
            <div className="loginbox">
              <div className="boxcenter">
                <img className="emtlogo" src={emt_logo} alt="emtlogo" />
              </div>
              <div className="backDiv">
                <Tippy content={"Back"} placement="bottom">
                  <div
                    className={"IconsDiv"}
                    onClick={() => {
                      setLogin(false);
                    }}
                  >
                    <BiArrowBack className="backFilters" />
                  </div>
                </Tippy>
              </div>
              {!withOtpState && !checkOtpState && (
                <div className="mobHeadingDiv">
                  <p className="mobHeading">Enter your mobile number we will sent you OTP to verify</p>
                </div>
              )}
              {checkOtpState ? (
                <CheckLoginOtp
                  sessionId={sessionId}
                  content={formik.values.mobileNumber || formik.values.email}
                  loginType={loginType}
                />
              ) : (
                <>
                  {/* <div className="selctiondiv">   
                    <Button
                      handleClick={otpInputHandler}
                      className={isEmailInput ? "selectionbtn" : "selected"}
                      text="By Mobile"
                    />
                    <Button
                      handleClick={emailInputHandler}
                      className={isEmailInput ? "selected" : "selectionbtn"}
                      text="By Email"
                    />
                  </div> */}
                  <div className="boxcenter">
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                      <div className="Icon-inside">
                        {isEmailInput ? (
                          <>
                            <InputBox
                              className="inputbox"
                              type="email"
                              placeholder="Email"
                              id="email"
                              name="email"
                              value={formik.values.email}
                              handleInputChange={formik.handleChange}
                              err={formik.errors.email}
                              formtype="loginForm"
                              touched={formik.touched.email ? formik.touched.email : false}
                              autoComplete={"none"}
                            />
                          </>
                        ) : (
                          <>
                            <InputBox
                              className="inputbox no-spinners"
                              type="number"
                              placeholder="Mobile No*"
                              id="mobileNumber"
                              name="mobileNumber"
                              value={formik.values.mobileNumber}
                              handleInputChange={formik.handleChange}
                              maxNo={10}
                              err={formik.errors.mobileNumber}
                              formtype="loginForm"
                              touched={formik.touched.mobileNumber ? formik.touched.mobileNumber : false}
                              autoComplete={"none"}
                              autoFocus={true}
                            />
                          </>
                        )}

                        <i aria-hidden="true">
                          {isEmailInput ? <MdEmail className="form_icons" /> : <FaPhoneAlt className="form_icons" />}
                        </i>
                      </div>

                      {withOtpState && (
                        <>
                          <div className="Icon-inside">
                            <InputBox
                              className="inputbox"
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              id="password"
                              name="password"
                              value={formik.values.password}
                              handleInputChange={formik.handleChange}
                              err={formik.errors.password}
                              formtype="loginForm"
                              touched={formik.touched.password ? formik.touched.password : false}
                              autoComplete={"none"}
                            />
                            {showPassword ? (
                              <FaEye className="eyeIcon" onClick={() => setShowPassword(!showPassword)} />
                            ) : (
                              <FaEyeSlash className="eyeIcon" onClick={() => setShowPassword(!showPassword)} />
                            )}
                            <i aria-hidden="true">
                              <RiLockPasswordFill id="passwordicon" className="form_icons" />
                            </i>
                          </div>

                          <div className="forgetPassDiv">
                            <p className="forgetPassText" onClick={handleForgetPassword}>
                              Forget Password?
                            </p>
                          </div>
                        </>
                      )}
                      <div className="btndiv">
                        {withOtpState ? (
                          <Button type="submit" className="btn1" text={loading ? <SpinnerIndicator /> : "Login"} />
                        ) : (
                          <Button
                            className="btn1"
                            type="submit"
                            text={getOtpLoading ? <SpinnerIndicator /> : "Get OTP"}
                          />
                        )}
                        {/* <Button
                          className="btn2"
                          type={"button"}
                          handleClick={handleNavigateToSignup}
                          text="Go to Signup"
                        /> */}
                      </div>
                    </form>
                  </div>
                  {/* <div className="alternateLogin">
                    <p className="orLoginText" onClick={() => setWithOtpState(!withOtpState)}>
                      {withOtpState ? "or Login with OTP" : "or Login with Password"}
                    </p>
                  </div> */}
                </>
              )}
              <p className="helplineNoLoginText">
                Helpline No -<br></br>
                7066664747 / 7066669090
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LoginForm;

import React, { useEffect, useRef, useState } from "react";
import uploadFile from "../../../../Assets/Icons/fileupload.png";
import Reactportal from "../../Reactportal/Reactportal";
import PortalFooter from "../../Reactportal/PortalFooter";
import FileUploadFooter from "../FileUploadBoxComponents/FileUploadFooter";
import axios from "axios";
import { FaEye } from "react-icons/fa";
import { MdAddCircle } from "react-icons/md";
import { GiCheckMark } from "react-icons/gi";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import "./FileUpload.scss";

function FileUpload({ e, sendFile, allFieldsData, format, fileName, disabled, fieldName, pageName }) {
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const [toggle, settoggle] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const dispatch = useDispatch();
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const fileInputRef = useRef();
  const token = Cookies.get("token");

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };
  const [img, setImg] = useState(allFieldsData && allFieldsData[e.name] ? allFieldsData[e.name] : "");

  useEffect(() => {
    setImg(allFieldsData && allFieldsData[e.name] ? allFieldsData[e.name] : "");
  }, [allFieldsData]);

  const handleFileChange = async (e) => {
    let selectedFile = e.target.files[0];

    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setLoadingState(true);
      const { data } = await axios.post(`${process.env.REACT_APP_GRAPHQL_URI}/upload/single`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          pageName: pageName,
          authorization: token,
        },
      });
      sendFile(data.fileURL);
      setImg(data.fileURL);
      toast.success("File Added Sucess");

      dispatch({
        type: "editProfileData",
        payload: {
          key: "Documents",
          data: {
            value: data.fileURL,
            name: fieldName,
          },
        },
      });

      setLoadingState(false);
    } catch (error) {
      console.error("Error uploading file:", error.message);
      setLoadingState(false);
    }
  };
  const deleteFile = async () => {
    try {
      setLoadingState(true);
      await axios.delete(`${process.env.REACT_APP_GRAPHQL_URI}/upload/file/delete`, {
        data: {
          url: img,
        },
      });
      sendFile("");
      setImg("");
      toast.success("File Deleted Sucess");
      setLoadingState(false);
    } catch (error) {
      console.error("Error while deleting resource:", error);
      setLoadingState(false);
    }
  };

  const openFileDialog = () => {
    fileInputRef.current.click();
  };

  if (format) {
    return (
      <div className="file-upload-container">
        <div className="formatFileUpload">
          <p className="uploadFileHeading">
            <span style={{ color: img ? "#000" : "gray" }}>{fileName}</span>{" "}
            {img ? <GiCheckMark className="checkIcon" /> : ""}
          </p>
          {img ? (
            <FaEye className="formatFileUploadIcon" onClick={() => settoggle(!toggle)} />
          ) : (
            <MdAddCircle
              className="formatFileUploadIcon"
              onClick={() => {
                if (!disabled) {
                  settoggle(!toggle);
                }
              }}
            />
          )}
        </div>
        <Reactportal
          open={toggle}
          modalClassName={"fileUploadModal"}
          contentClassName="fileUpload-content"
          modalHeading={"File Upload"}
          onClose={() => settoggle(!toggle)}
          TypeOfPortal={"File Upload"}
          TypeOfId={""}
        >
          <div className="childrenDiv">
            {fileName === "Self Declaration video" ? (
              <>
                {img && (
                  <video controls className="videoPlayer">
                    <source src={img} type="video/mp4" autoplay={false} />
                  </video>
                )}

                {!img && (
                  <div className="filterUploadBoxContent2">
                    <div className="iconDiv">
                      <input ref={fileInputRef} type="file" onChange={handleFileChange} disabled={disabled} />
                      <div className="selectLangTag">
                        <label htmlFor="languageSelect">Select Language: </label>
                        <select id="languageSelect" value={selectedLanguage} onChange={handleLanguageChange}>
                          <option value="English">English</option>
                          <option value="Hindi">Hindi</option>
                          <option value="Marathi">Marathi</option>
                          <option value="Gujarati">Gujarati</option>
                          <option value="Telugu">Telugu</option>
                          {/* Add more options for other languages */}
                        </select>
                      </div>

                      {selectedLanguage === "English" && (
                        <>
                          <h4>Please record a video with reading below text for video KYC and upload it below-</h4>
                          <p className="videoText">
                            {`I, ${
                              accInfo.FullName ? accInfo.FullName : `${accInfo.firstName} ${accInfo.lastName}`
                            }, hereby apply for opening a Supply
                            Partner Account with Ease My Transport Pvt Ltd. I confirm that
                            all the submitted documents are authentic and accurate.
                            Furthermore, I am fully accountable for the vehicle associated
                            with this account number : ${
                              accInfo.Unique_Id ? accInfo.Unique_Id : "XXXXXXX"
                            }. I assure prompt resolution
                            of any issues and timely delivery of goods according to the
                            contractual terms. I acknowledge that any wrongdoing in
                            relation to this account will result in severe legal action
                            against me.`}
                          </p>
                        </>
                      )}

                      {selectedLanguage === "Hindi" && (
                        <>
                          <h4 className="videoText">
                            कृपया वीडियो केवाईसी के लिए नीचे दिए गए पाठ को पढ़ते हुए एक वीडियो रिकॉर्ड करें और इसे नीचे
                            अपलोड करें-
                          </h4>
                          <p className="videoText">
                            {" "}
                            {`मैं, ${
                              accInfo.FullName ? accInfo.FullName : `${accInfo.firstName} ${accInfo.lastName}`
                            }, यहाँ ईज माय ट्रांसपोर्ट प्राइवेट लिमिटेड के साथ एक सप्लाई पार्टनर खाता खोलने के लिए आवेदन करती हूँ। मैं सत्यापित और सटीक होने वाले सभी दस्तावेज़ों की पुष्टि करती हूँ। इसके अलावा, मैं इस खाता नंबर:  ${
                              accInfo.Unique_Id ? accInfo.Unique_Id : "XXXXXXX"
                            }.  से जुड़े वाहन के लिए पूर्ण जवाबदार हूँ। मैं वादानुसार समय पर सामग्री की विचारशीलता और समय पर वस्तुओं का वितरण सुनिश्चित करती हूँ। मैं स्वीकार करती हूँ कि इस खाते से संबंधित किसी भी गलत गतिविधि पर मुझ पर कठोर कानूनी कार्रवाई की जाएगी।`}
                          </p>
                        </>
                      )}

                      {selectedLanguage === "Marathi" && (
                        <>
                          <h4 className="videoText">
                            कृपया व्हिडिओ KYC साठी खालील मजकूर वाचून व्हिडिओ रेकॉर्ड करा आणि तो खाली अपलोड करा-
                          </h4>
                          <p className="videoText">
                            {" "}
                            {`मी, ${
                              accInfo.FullName ? accInfo.FullName : `${accInfo.firstName} ${accInfo.lastName}`
                            }, Ease My Transport Pvt Ltd सह पुरवठा भागीदार खाते उघडण्यासाठी अर्ज करते. मी पुष्टी करतो की सबमिट केलेले सर्व कागदपत्रे प्रामाणिक आणि अचूक आहेत. शिवाय, या खाते क्रमांकाशी संबंधित असलेल्या वाहनासाठी मी पूर्णपणे जबाबदार आहे:  ${
                              accInfo.Unique_Id ? accInfo.Unique_Id : "XXXXXXX"
                            }.  मी कराराच्या अटींनुसार कोणत्याही समस्येचे त्वरित निराकरण आणि वेळेवर वस्तूंचे वितरण करण्याचे आश्वासन देतो. मी कबूल करतो की या खात्याशी संबंधित कोणतेही चुकीचे काम माझ्याविरुद्ध कठोर कायदेशीर कारवाई करेल.`}
                          </p>
                        </>
                      )}
                      {selectedLanguage === "Gujarati" && (
                        <>
                          <h4 className="videoText">
                            કૃપા કરીને વિડિયો KYC માટે નીચે લખાણ વાંચીને વિડિયો રેકોર્ડ કરો અને તેને નીચે અપલોડ કરો-
                          </h4>
                          <p className="videoText">
                            {" "}
                            {`હું, ${
                              accInfo.FullName ? accInfo.FullName : `${accInfo.firstName} ${accInfo.lastName}`
                            }, Ease My Transport Pvt Ltd સાથે સપ્લાય પાર્ટનર ખાતું ખોલવા માટે અરજી કરું છું. હું પુષ્ટિ કરું છું કે સમર્પિત થયેલા દસ્તાવેજો માન્ય અને સાચા છે. વધુમાં, આ એકાઉન્ટ નંબર: ${
                              accInfo.Unique_Id ? accInfo.Unique_Id : "XXXXXXX"
                            }. સાથે સંબંધિત વાહન માટે હું પૂરી જવાબદાર છું. હું વચનો પ્રમાણે કોઈપણ સમસ્યાના તટસ્થ નિરાકરણ અને સમયસર માલની વિતરણ આપીશ. હું સ્વીકારું છું કે આ ખાતાથી સંબંધિત કોઈપણ દુરાચાર મારી વિરુદ્ધ ગંભીર કાનૂની કાર્યવાહી જ પરિણામશે.`}
                          </p>
                        </>
                      )}

                      {selectedLanguage === "Telugu" && (
                        <>
                          <h4 className="videoText">
                            దయచేసి వీడియో KYC కోసం దిగువ వచనాన్ని చదవడం ద్వారా వీడియోను రికార్డ్ చేయండి మరియు దానిని
                            క్రింద అప్‌లోడ్ చేయండి-
                          </h4>
                          <p className="videoText">
                            {" "}
                            {`నేను, ${
                              accInfo.FullName ? accInfo.FullName : `${accInfo.firstName} ${accInfo.lastName}`
                            }, ఈజ్ మై ట్రాన్స్‌పోర్ట్ ప్రైవేట్ లిమిటెడ్‌తో సప్లై పార్టనర్ ఖాతాను తెరవడం కోసం దరఖాస్తు చేస్తున్నాను. సమర్పించిన అన్ని పత్రాలు ప్రామాణికమైనవి మరియు ఖచ్చితమైనవి అని నేను ధృవీకరిస్తున్నాను. ఇంకా, ఈ ఖాతా నంబర్‌తో అనుబంధించబడిన వాహనానికి నేను పూర్తిగా జవాబుదారీగా ఉన్నాను: ${
                              accInfo.Unique_Id ? accInfo.Unique_Id : "XXXXXXX"
                            }.ఏవైనా సమస్యలకు సత్వర పరిష్కారం మరియు ఒప్పంద నిబంధనల ప్రకారం వస్తువులను సకాలంలో అందజేస్తామని నేను హామీ ఇస్తున్నాను. ఈ ఖాతాకు సంబంధించి ఏదైనా తప్పు జరిగితే నాపై తీవ్రమైన చట్టపరమైన చర్య తీసుకోబడుతుందని నేను అంగీకరిస్తున్నాను.`}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="filterUploadBoxContent2">
                <div className="iconDiv">
                  <input ref={fileInputRef} type="file" onChange={(e) => handleFileChange(e)} disabled={disabled} />
                  <img className="fileUploadIcon" src={img || uploadFile} alt="uploadFile" onClick={openFileDialog} />
                </div>
              </div>
            )}
          </div>
          <PortalFooter>
            <FileUploadFooter
              submitform={openFileDialog}
              onClose={() => settoggle(!toggle)}
              downloadableFile={img}
              downloadBtn={img ? true : false}
              deleteFile={deleteFile}
              disabled={disabled}
              loading={loadingState}
            />
          </PortalFooter>
        </Reactportal>
      </div>
    );
  }

  return (
    <div className="file-upload-container">
      <img className="fileUploadIcon2" onClick={() => settoggle(!toggle)} src={img || uploadFile} alt="fileupload" />
      <p>{e.lable}</p>
      <Reactportal
        open={toggle}
        modalClassName={"fileUploadModal"}
        contentClassName="fileUpload-content"
        modalHeading={"File Upload"}
        onClose={() => settoggle(!toggle)}
        TypeOfPortal={"File Upload"}
        TypeOfId={""}
      >
        <div className="childrenDiv">
          <div className="filterUploadBoxContent2">
            <div className="iconDiv">
              <input ref={fileInputRef} type="file" accept=".jpg, .png" onChange={handleFileChange} />
              <img className="fileUploadIcon" src={img || uploadFile} alt="uploadFile" onClick={openFileDialog} />
            </div>
          </div>
        </div>
        <PortalFooter>
          <FileUploadFooter
            submitform={openFileDialog}
            onClose={() => settoggle(!toggle)}
            downloadableFile={img}
            downloadBtn={img ? true : false}
            deleteFile={deleteFile}
            loading={loadingState}
          />
        </PortalFooter>
      </Reactportal>
    </div>
  );
}

export default FileUpload;

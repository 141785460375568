const initialState = {
  uploadedFiles: {},
};

const StoreFiles = (state = initialState, { type, payload }) => {
  switch (type) {
    case "SET_UPLOADED_FILES":
      return {
        ...state,
        uploadedFiles: payload,
      };
    default:
      return state;
  }
};

export default StoreFiles;

import React from "react";
import "./BidConfirmationModal.scss";
import PortalFooter from "../../../SpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import Spinner from "../../Spinner/Spinner";
import Table from "../../../LpComponents/Table/Table";
import THead from "../../../LpComponents/Table/TableComponents/THead/THead";
import TH from "../../../LpComponents/Table/TableComponents/TH/TH";
import TBody from "../../../LpComponents/Table/TableComponents/TBody/TBody";
import TR from "../../../LpComponents/Table/TableComponents/TR/TR";
import TD from "../../../LpComponents/Table/TableComponents/TD/TD";
import { NumberFormatter } from "../../../../CommonFunction/NumberFormatter/NumberFormatter";

function BidConfirmationModal({ listArr, newBidDetails, onClose, loading, RatePer, data }) {
  // console.log(RatePer, data);
  return (
    <div className="bidCModalContentBox">
      <div className="divtable">
        <Table>
          <THead>
            <TR>
              <TH>Vehicle No</TH>
              {RatePer === "Per MT" && <TH>MT X Price</TH>}
              <TH>Amount</TH>
            </TR>
          </THead>
          <TBody>
            <TR>
              <TD>{data.Committed_Vehicles_0}</TD>
              {RatePer === "Per MT" && (
                <TD>
                  ({data.Vehicle_Quantity_0} X {data.Price})
                </TD>
              )}
              {RatePer === "Per MT" ? (
                <TD>{NumberFormatter(data.Vehicle_Quantity_0 * Number(data.Price))}</TD>
              ) : (
                <TD>{NumberFormatter(data.Price)}</TD>
              )}
            </TR>
            {data.Committed_Vehicles_1 && (
              <TR>
                <TD>{data.Committed_Vehicles_1}</TD>
                {RatePer === "Per MT" && (
                  <TD>
                    ({data.Vehicle_Quantity_1} X {data.Price})
                  </TD>
                )}
                {RatePer === "Per MT" ? (
                  <TD>{NumberFormatter(data.Vehicle_Quantity_1 * Number(data.Price))}</TD>
                ) : (
                  <TD>{NumberFormatter(data.Price)}</TD>
                )}{" "}
              </TR>
            )}
            {data.Committed_Vehicles_2 && (
              <TR>
                <TD>{data.Committed_Vehicles_2}</TD>
                {RatePer === "Per MT" && (
                  <TD>
                    ({data.Vehicle_Quantity_2} X {data.Price})
                  </TD>
                )}
                {RatePer === "Per MT" ? (
                  <TD>{NumberFormatter(data.Vehicle_Quantity_2 * Number(data.Price))}</TD>
                ) : (
                  <TD>{NumberFormatter(data.Price)}</TD>
                )}{" "}
              </TR>
            )}
          </TBody>
        </Table>
      </div>

      <p className="bidCModalText">
        <ul>
          {listArr?.map((el) => (
            <li key="el">{el}</li>
          ))}
        </ul>
      </p>

      <PortalFooter>
        {loading ? (
          <Button text={<Spinner />} type="submit" className={"loadingAcceptBtn"} />
        ) : (
          <Button
            text={"Confirm"}
            type="submit"
            className={"acceptBtn"}
            handleClick={() => {
              newBidDetails();
              onClose();
            }}
            disabled={loading}
          />
        )}

        <Button handleClick={onClose} text="Back" className={"rejectBtn"} disabled={loading} />
      </PortalFooter>
    </div>
  );
}

export default BidConfirmationModal;

export const cnfVehicleData = [
  "Bid once placed can't be withdrawn.",
  "Vehicels commited can be changed in the later stage.",
  "If the load is awarded then you have to place the vehicle, if vehicle not placed then a penalty of Rs. 500/- will be deducted from your account and your rating will also be downgraded.",
];

export const awardedAcceptedData = [
  "The vehicle number commited cannot be changed here after.",
  "If vehicle not placed an amount of Rs. 500/- will be deducted and your rating will be downgraded.",
];

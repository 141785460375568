import React from "react";
import InputBox from "../../InputBox/InputBox";
import "./ColumnFilter.scss";

function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column;

  return (
    <div className="columnFilterDiv">
      <InputBox
        className={"filterInputBox"}
        value={filterValue || ""}
        handleInputChange={(e) => setFilter(e.target.value || undefined)}
        autoComplete="none"
      />
    </div>
  );
}

export default ColumnFilter;

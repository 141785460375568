import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../Pages/LpPages/Layout/Layout";
import LoginPage from "../Pages/LoginPage/LoginPage";
import Completed from "../Pages/LpPages/Booking/Completed/Completed";
import Ongoing from "../Pages/LpPages/Booking/Ongoing/Ongoing";
import Live from "../Pages/LpPages/Loads/Live/Live";
import Resulted from "../Pages/LpPages/Loads/Resulted/Resulted";
import ProfilePage from "../Pages/LpPages/ProfilePage/ProfilePage";
import AccountStatement from "../Pages/LpPages/Reports/AccountStatment/AccountStatement";
import Analytics from "../Pages/LpPages/Reports/Analytics/Analytics";
import SignupPage from "../Pages/SignUpPage/SignupPage";
import PrivateRoute from "./PrivateRoute";
import LandingPage from "../Pages/LandingPage/LandingPage";
import ArchiveLP from "../Pages/LpPages/Loads/ArchiveLP/ArchiveLP";
import PrivacyPolicy from "../Pages/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions";

const Allroutes = () => {
  return (
    <Routes>
      <Route path={"/login"} element={<LoginPage />} />
      <Route path={"/signup"} element={<SignupPage />} />
      <Route path="/" element={<LandingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route
        path="/live"
        element={
          <PrivateRoute>
            <Layout filters={true}>
              <Live />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/resulted"
        element={
          <PrivateRoute>
            <Layout filters={true}>
              <Resulted />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/archive"
        element={
          <PrivateRoute>
            <Layout filters={true}>
              <ArchiveLP />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/ongoing"
        element={
          <PrivateRoute>
            <Layout filters={false}>
              <Ongoing />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/completed"
        element={
          <PrivateRoute>
            <Layout filters={false}>
              <Completed />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/accountstatment"
        element={
          <PrivateRoute>
            <Layout>
              <AccountStatement />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/analytics"
        element={
          <PrivateRoute>
            <Layout>
              <Analytics />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/profile-settings/*"
        element={
          <PrivateRoute>
            <Layout>
              <ProfilePage />
            </Layout>
          </PrivateRoute>
        }
      />
      <Route
        path="/*"
        element={<Navigate to="/login" />} // Redirect to login page
      />
    </Routes>
  );
};

export default Allroutes;

import React from "react";
import AutoScrollingLine from "../AutoScrollingLine/AutoScrollingLine";
import SpFilterBox from "../../SpComponents/SpFilterBox/SpFilterBox";
import Cookies from "js-cookie";
import "./HeaderRowTwo.scss";

function HeaderRowTwo({ spFliterBox }) {
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");

  return (
    <div className="headerRowTwoDiv">
      <AutoScrollingLine
        speed={0.75}
        text={
          accInfo.accType === "Load Partner Account" ? (
            <p>
              Welcome to Ease My Transport Pvt Ltd. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No
              Charges Applied till 31 December, 2023. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* Auction timings are 8:00 Am, 9:00 Am, 10:00 Am, 12:00 Pm, 2:00 Pm and 7:00 Pm. */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All Loads posted by the LP account are under
              the full responsibility of account holder. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              Please use the platform wisely.
            </p>
          ) : (
            <p>
              Welcome to Ease My Transport Pvt Ltd. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; No
              Charges Applied till 31 December, 2023. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {/* Auction timings are 8:00 Am, 9:00 Am, 10:00 Am, 12:00 Pm, 2:00 Pm and 7:00 Pm. */}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All vehicles placed by the SP account are
              under the full responsibility of account holder.
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please ensure complete safety and on time
              delivery. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Please use the platform wisely.
            </p>
          )
        }
      />
      {spFliterBox && <SpFilterBox />}
    </div>
  );
}

export default HeaderRowTwo;

import React from "react";
import { GrMail } from "react-icons/gr";
import { BsFacebook, BsLinkedin, BsFillTelephoneFill } from "react-icons/bs";
import "./LandingPageTopNavBar.scss";

const LandingPageTopNavBar = () => {
  const handleVisitLinkedIn = () => {
    window.open(
      "https://www.linkedin.com/company/ease-my-transport-pvt-ltd/",
      "_blank"
    );
  };
  const handleVisitFacebok = () => {
    window.open(
      "https://www.facebook.com/profile.php?id=100070802801079",
      "_blank"
    );
  };
  return (
    <div className="LandingPage_TopNavBar_mainDiv">
      <div className="TopNavBar_ContactDiv">
        <div>
          <BsFillTelephoneFill className="icon" />
          <p>LP Helpline No : 70 6666 4747</p>
        </div>

        <div>
          <BsFillTelephoneFill className="icon" />
          <p> SP Helpline No : 70 6666 9090</p>
        </div>

        <div>
          <GrMail className="icon" />
          <p>contact@easemytransport.com</p>
        </div>
      </div>
      <div className="SocialMediaLink">
        <div>
          <div onClick={handleVisitFacebok}>
            <BsFacebook className="socialMediaIcons" />
          </div>
        </div>
        {/* <div>
          <FiInstagram className="socialMediaIcons" />
        </div> */}
        <div className="socialMediaIcons">
          <div onClick={handleVisitLinkedIn}>
            <BsLinkedin />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPageTopNavBar;

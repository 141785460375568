import React from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import "./ProfileStatusModal.scss";

function ProfileStatusModal({ onClose, closeModalWithGoToProfile, ProfoleData, accBalance }) {
  // console.log(ProfoleData, "ProfoleData");
  return (
    <>
      <div className="ProfileStatusModalDiv">
        <div className="ProfileStatusModalMessageDiv">
          {ProfoleData.Is_Mobile_Number_Verified === false ? (
            <div className="profileErrDiv">Mobile Verification Pending</div>
          ) : (
            ""
          )}
          {ProfoleData.Is_Email_Verified === false ? (
            <div className="profileErrDiv">Email Verification Pending </div>
          ) : (
            ""
          )}
          {ProfoleData.Is_Verified === false ? <div className="profileErrDiv">Profile Details Incomplete</div> : ""}
          {/* {ProfoleData.Account_Type === "Customer Account" && accBalance < 100 ? (
            <div className="profileErrDiv">Insufficient Balance (minimum balance required is ₹.100)</div>
          ) : (
            ""
          )} */}
        </div>
        <PortalFooter>
          {ProfoleData.Is_Verified === false ||
          ProfoleData.Is_Mobile_Number_Verified === false ||
          ProfoleData.Is_Email_Verified === false ? (
            <Button text={"Go To Profile"} className={"acceptBtn"} handleClick={closeModalWithGoToProfile} />
          ) : (
            <></>
          )}

          <Button text={"Ok"} className="rejectBtn" handleClick={onClose} />
        </PortalFooter>
      </div>
    </>
  );
}

export default ProfileStatusModal;

import React from 'react'
import "./Box.scss"
const Box = ({children}) => {
  return (
    <div className='Box'  >
      {children}
    </div>
  )
}

export default Box

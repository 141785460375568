import React from "react";
import DateToString from "../../../Common/DateToString/DateToString";
import "./AuctionTime.scss";
const AuctionTime = ({ auctiondate, auctiontime }) => {
  auctiontime = auctiontime.trim().split(" ");

  return (
    <div className="auctiontimebody">
      <h5>Auction Start</h5>
      <li>
        <DateToString date={auctiondate} time={auctiontime[1]} />
      </li>
      <li>30 Mins</li>
    </div>
  );
};

export default AuctionTime;

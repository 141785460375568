import { gql } from "@apollo/client";

export const CREATE_POD = gql`
  mutation CreatePOD_Management($createPodManagementInput: CreatePOD_ManagementInput!) {
    createPOD_Management(createPOD_ManagementInput: $createPodManagementInput) {
      _id
      Unique_Id
      Booking_Id
      POD_Copy_Front
      POD_Copy_Back
      Status
      Vehicle_Number
      UserId
      LoadId
      Final_Invoice_Copy
      Driver_Name
      Driver_Number
      createdAt
      updatedAt
    }
  }
`;

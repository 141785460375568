import { gql } from "@apollo/client";

export const GET_AWARDED_DATA = gql`
  query GetBidsWithLoadForUser($getBidsWithLoadForUserUserInput2: SearchInputForLoads) {
    getBidsWithLoadForUser(userInput: $getBidsWithLoadForUserUserInput2) {
      _id
      LoadId {
        _id
        Unique_Id
        UserId
        Load_Post_Type
        Auction_Date
        Auction_Time
        Dangerous_Goods
        Exact_Content
        Expected_Price
        From_City
        Load_Live_Duration
        Load_Manager
        Loading_Area
        Loading_By
        Loading_City
        Loading_Date
        Loading_Landmark
        Loading_Name
        Loading_Pincode
        Loading_Scope
        Loading_State
        Loading_Time
        Loading_Address_Line_1
        Loading_Address_Line_2
        Material_Category
        Material_Dimension
        Material_Dimension_In
        Material_Insurence
        Material_Image
        Material_Image_2
        Material_Image_3
        No_Of_Vehicels_Required
        Notes
        Over_Dimension_Cargo
        Payment_Type
        Rate_per
        Target_Price
        To_City
        Total_Weight
        Unloading_Area
        Unloading_City
        Unloading_Landmark
        Unloading_Name
        Unloading_Pincode
        Unloading_Scope
        Unloading_State
        Unloading_Address_Line_1
        Unloading_Address_Line_2
        Vehicle_Type_String
        Weight_Category_String
        Is_Live
        Is_Auction_Started
        Is_Archive
        createdAt
        updatedAt
      }
      UserId
      Price
      Unique_Id
      No_Of_Vehicle
      Confirm_Qty
      Committed_Vehicles
      Bid_Note
      createdAt
    }
  }
`;

import React, { useState, useEffect } from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import "./BankDetailsModal.scss";

function BankDetailsModal({ bankMessage, editBankData, onClose, bankdata }) {
  const [acNo, setAcNo] = useState("");
  const [check, setCheck] = useState(false);

  // console.log(bankdata.Account_Number, acNo, "accounts")

  useEffect(() => {
    if (acNo == bankdata.Account_Number) {
      setCheck(true);
    } else {
      setCheck(false);
    }
  }, [acNo]);

  return (
    <div className="BankModalDiv">
      <div className="BankModalMessageDiv">
        <div className="reinputdiv">
          <label className="acLabel">Re-Enter Account Number:</label>
          <input
            type="text"
            className="acInput"
            value={acNo}
            onChange={(e) => {
              setAcNo(e.target.value);
            }}
          />
        </div>

        {check && (
          <div className="bankdetaisDiv">
            <p>
              Bank Name: <b>{bankdata.Bank_Name}</b>
            </p>
            <p>
              Account Type: <b>{bankdata.BankAccount_Type}</b>
            </p>
            <p>
              IFSC Code: <b>{bankdata.IFSC_Code}</b>
            </p>
            <p>
              Account Number: <b>{bankdata.Account_Number}</b>
            </p>
          </div>
        )}
      </div>
      <PortalFooter>
        <Button
          text={"OK"}
          className={check ? "acceptBtn" : "disabledBtnBank"}
          handleClick={editBankData}
          disabled={!check}
        />
        <Button text={"Cancel"} className="rejectBtn" handleClick={onClose} />
      </PortalFooter>
    </div>
  );
}

export default BankDetailsModal;

import { gql } from "@apollo/client";

export const GET_TRANSSACTION = gql`
  query GetVoucherEntriesRelatedToAccountID($AccountID: ID, $Search: String, $FromDate: String, $To_Date: String) {
    GetVoucherEntriesRelatedToAccountID(
      AccountID: $AccountID
      Search: $Search
      FromDate: $FromDate
      To_Date: $To_Date
    ) {
      _id
      Unique_Id
      Entry_Type
      Voucher_Type
      UserId
      Doc_No
      Date
      Doc_Date
      Narration
      createdAt
      updatedAt

      specificTransactions {
        _id
        Unique_Id
        Amount
        Transaction_Type
        Account {
          _id
        }
      }
      closingBalance
    }
  }
`;

import React from "react";
import "./EventTimeFormat.scss";

function EventTimeFormat({ date }) {
  const formatDate = (inputDateString) => {
    const inputDate = new Date(inputDateString);

    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = inputDate.getDate();
    const monthIndex = inputDate.getMonth();
    const year = inputDate.getFullYear();
    const suffix =
      day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th";

    let hours = inputDate.getHours();
    const minutes = String(inputDate.getMinutes()).padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";

    hours %= 12;
    hours = hours === 0 ? 12 : hours;
    hours = String(hours).padStart(2, "0");

    return `${day}${suffix} ${monthNames[monthIndex]} ${year} (${hours}:${minutes} ${amPm})`;
  };

  return (
    <p className="dateDetailsFormatText">
      {date ? formatDate(date) : ""}
    </p>
  );
}

export default EventTimeFormat;

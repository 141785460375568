const initial = {
  liveLoadArr: 0,
  resultedLoadArr: 0,
};

export function ArrCountsReducer(state = initial, { type, payload }) {
  switch (type) {
    case "set_arrCounts": {
      return {
        ...state,
        liveLoadArr: payload.liveLoadArr,
        resultedLoadArr: payload.resultedLoadArr,
      };
    }
    default: {
      return state;
    }
  }
}

import React, { useEffect, useState } from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import InputBox from "../../InputBox/InputBox";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import socket from "../../../../CommonFunction/Socket/socketClient";
import { EDIT_VEHICLE_MANAGEMENT_DATA } from "../../../../gql/editVehicle";

function EditVehicleModal({ cells, onClose }) {
  const [editVehicleManagement] = useMutation(EDIT_VEHICLE_MANAGEMENT_DATA);
  const [vehicleId, setVehicleId] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setVehicleId(cells.row.original._id);
  }, [cells]);

  const initialValues = {
    Vehicle_No: cells.row.original.Vehicle_No,
  };

  const validate = (values) => {
    // console.log("validate", values);
  };

  const onSubmit = async (values) => {
    // console.log("submit", values);

    await editVehicleManagement({
      variables: {
        editVehicleManagementId: vehicleId,
        userInput: values,
      },
    })
      .then((res) => {
        toast.success("Vehicle Edited");
        onClose();
      })
      .catch((err) => toast.error(err.message));
    dispatch({ type: "set_loadData", payload: true });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };
  useEffect(() => {
    socket.on("event", (data) => {
      // console.log("Received event:", data);
    });
    // socket.on("hello", () => {
    //   console.log("this is madhusai");
    // });
    socket.emit("sendrequest", "madhu");

    // Clean up the socket connection when the component is unmounted
  }, []);

  return (
    <div className="addVehicleModal">
      <form onSubmit={formik.handleSubmit}>
        <div className="addVehicleModalContentDiv boxcenter">
          <div className="addVehicleModalHeaderDiv">
            <p className="addVehicleModalHeader">Vehicle Number :</p>
          </div>
          <InputBox
            className={"filterInputBox"}
            type="text"
            autoComplete="off"
            name="Vehicle_No"
            handleInputChange={formik.handleChange}
            handleformikdata={handleformikdata}
            uppercase={true}
            maxNo={10}
            placeholder={cells.row.original.Vehicle_No}
          />
        </div>
        <PortalFooter>
          <Button text="Update" className={"acceptBtn"} type="submit" />
          <Button text="Cancel" className={"rejectBtn"} handleClick={onClose} />
        </PortalFooter>
      </form>
    </div>
  );
}

export default EditVehicleModal;

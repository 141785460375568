import React from "react";
import "./Button.scss";

const Button = ({ className, text, handleClick, icon, type, disabled }) => {
  return (
    <div>
      <button type={type} className={className} onClick={handleClick} disabled={disabled} onKeyDown={handleClick}>
        <span>{icon}</span>
        {text}
      </button>
    </div>
  );
};

export default Button;

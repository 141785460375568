import "./PrivacyPolicy.scss";
import LandingPageTopNavBar from "../LandingPage/LandingPageTopNavBar/LandingPageTopNavBar";
import LandingPageNavBar from "../LandingPage/LandingPageNavBar/LandingPageNavBar";
import { BsArrowLeft } from "react-icons/bs";
import { PrivacyAndPolicy } from "./privacyPolicy.config";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Footer from "../LandingPage/Footer/Footer";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <LandingPageTopNavBar />
      <LandingPageNavBar />

      <main className="privacyPolicyDiv">
        <BsArrowLeft size={20} onClick={() => navigate("/")} />
        <h1>Privacy Policy</h1>
        <div className="termsContainer">
          {PrivacyAndPolicy.map((term, index) => (
            <div key={index} className="termBox">
              <h3>
                {index + 1}. {term.title}
              </h3>
              <p>{term.description}</p>
            </div>
          ))}
        </div>
      </main>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;

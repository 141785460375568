import React, { useEffect, useState } from "react";
import PortalFooter from "../../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../../Button/Button";
import Reactportal from "../../../../LpComponents/Reactportal/Reactportal";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import GetTotal from "../../../GetTotal/GetTotal";
import InputBox from "../../../InputBox/InputBox";
import socket from "../../../../../CommonFunction/Socket/socketClient";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import { getTotal } from "../../../../../CommonFunction/GetTotal/GetTotal";
import AcceptClaimCnfModal from "../AcceptClaimCnfModal/AcceptClaimCnfModal";
import Spinner from "../../../Spinner/Spinner";
import "./ViewClaimModal.scss";
import { CREATE_VOUCHER_ENTRIES } from "../../../../../CommonFunction/gql/createVoucherEntries";
import { EDIT_COMMITTED_VEHICLES_DATA } from "../../../../../gql/editCommitedVehicle";
import { EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE } from "../../../../../gql/editCommitedVehiclesWithoutVerifyBalance";
import { GET_ACCOUNT_DETAILS_BY_USERID } from "../../../../../gql/getAccountDetailsByUserId";
import { GET_CLAIMS_BY_COMMITTEDVEHICLESID } from "../../../../../gql/getClaimsBYCommitedVehicles";
import { EDIT_CLAIMS } from "../../../../../gql/editClaims";

function ViewClaimModal({
  LpId,
  SpId,
  CommittedVehicleId,
  CommittedVehicleUniqueId,
  VehicleNo,
  LpClaim,
  SpClaim,
  Lp_Claim_Cleared,
  Sp_Claim_Cleared,
}) {
  const { data } = useQuery(GET_CLAIMS_BY_COMMITTEDVEHICLESID, {
    variables: {
      findByInputs: {
        CommittedVechicleID: CommittedVehicleId,
      },
    },
  });

  const [editCommittedVehicleData, { loading: editCommittedVehicleDataLoading }] =
    useMutation(EDIT_COMMITTED_VEHICLES_DATA);
  const [
    editCommittedVehicleDataWithoutVerifyBalance,
    { loading: editCommittedVehicleDataWithoutVerifyBalanceLoading },
  ] = useMutation(EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE);

  const [editClaimData, { loading: editClaimDataLoading }] = useMutation(EDIT_CLAIMS);
  const [createVoucherEntries, { loading: createVoucherEntriesLoading }] = useMutation(CREATE_VOUCHER_ENTRIES);

  const [isEditCommittedVehicleDataLoading, setIsEditCommittedVehicleDataLoading] = useState(false);
  const selfAccountBalanceID = Cookies.get("AccountBalanceId");
  const [oppositeUserAccountBalanceId, setOppositeUserAccountBalanceId] = useState("");
  const { data: accDetailsData } = useQuery(GET_ACCOUNT_DETAILS_BY_USERID, {
    variables: {
      userId: LpClaim ? SpId : LpId,
    },
  });

  useEffect(() => {
    if (accDetailsData && accDetailsData.GetAccountBalanceIdWithUserId[0]) {
      setOppositeUserAccountBalanceId(accDetailsData.GetAccountBalanceIdWithUserId[0].id);
    }
  }, [accDetailsData]);

  const initialValues = {
    Is_Rejected: true,
    Reject_Reason: "",
  };

  const validate = (values) => {
    // console.log(values, "validate");

    let errors = {};

    if (!values.Reject_Reason) {
      errors.Reject_Reason = "Please give reason for rejection";
    }
    return errors;
  };

  const onSubmit = async (values) => {
    // console.log(values, "onSubmit");

    await handleRejectClaimData({ CommittedVehicleId, claimId, values });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  const handleformikdata = (Value, id) => {
    let data = Value;

    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };

  const [lpClaimArr, setLpClaimArr] = useState([]);
  const [spClaimArr, setSpClaimArr] = useState([]);

  useEffect(() => {
    if (data && data.ClaimsByCommittedVehiclesID) {
      setLpClaimArr(data.ClaimsByCommittedVehiclesID.Lp);
      setSpClaimArr(data.ClaimsByCommittedVehiclesID.Sp);
    }
  }, [data]);

  const [ChargesArr, setChargesArr] = useState();
  const [getTotalArr, setGetTotalArr] = useState([]);
  const [claimId, setClaimId] = useState();

  useEffect(() => {
    if (SpClaim && lpClaimArr[0]) {
      setChargesArr([
        {
          name: "Shortage Charges (₹)",
          price: lpClaimArr[lpClaimArr.length - 1].Shortage_Charges,
        },
        {
          name: "Damage Charges (₹)",
          price: lpClaimArr[lpClaimArr.length - 1].Damage_Charges,
        },
        {
          name: "Narration",
          price: lpClaimArr[lpClaimArr.length - 1].Narration,
        },
      ]);
      setGetTotalArr([
        lpClaimArr[lpClaimArr.length - 1].Shortage_Charges,
        lpClaimArr[lpClaimArr.length - 1].Damage_Charges,
      ]);
      setClaimId(lpClaimArr[lpClaimArr.length - 1].id);
    } else if (LpClaim && spClaimArr[0]) {
      setChargesArr([
        {
          name: "Holding Charges (₹)",
          price: spClaimArr[spClaimArr.length - 1].Holding_Charges,
        },
        {
          name: "Hamali Charges (₹)",
          price: spClaimArr[spClaimArr.length - 1].Hamali_Charges,
        },
        {
          name: "Extra Running Charges (₹)",
          price: spClaimArr[spClaimArr.length - 1].Extra_Running,
        },
        {
          name: "Challan Charges (₹)",
          price: spClaimArr[spClaimArr.length - 1].Challan_Charges,
        },
        {
          name: "Other Charges (₹)",
          price: spClaimArr[spClaimArr.length - 1].Other_Charges,
        },
        {
          name: "Other Charges Reason",
          price: spClaimArr[spClaimArr.length - 1].Other_Charges_Reason,
        },
      ]);
      setGetTotalArr([
        spClaimArr[spClaimArr.length - 1].Holding_Charges,
        spClaimArr[spClaimArr.length - 1].Hamali_Charges,
        spClaimArr[spClaimArr.length - 1].Extra_Running,
        spClaimArr[spClaimArr.length - 1].Challan_Charges,
        spClaimArr[spClaimArr.length - 1].Other_Charges,
      ]);
      setClaimId(spClaimArr[spClaimArr.length - 1].id);
    }
  }, [lpClaimArr, spClaimArr]);

  const [acceptModal, setAcceptModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);

  async function handleRejectClaimData({ CommittedVehicleId, claimId, values }) {
    let obj;

    if (LpClaim) {
      obj = {
        Sp_Claim_Raised: false,
      };
    } else {
      obj = {
        Lp_Claim_Raised: false,
      };
    }

    await editCommittedVehicleDataWithoutVerifyBalance({
      variables: {
        editCommittedVehiclesWithoutVerifyUserWithBalanceId: CommittedVehicleId,
        userInput: obj,
      },
    }).then(
      async () =>
        await editClaimData({
          variables: {
            editClaimsWithoutVerifyUserWithBalanceId: claimId,
            userInput: values,
          },
        })
          .then(() => {
            toast.success("Claim Rejected");
            socket.emit("Update_CurrentTask", LpId || SpId, CommittedVehicleId);
          })
          .catch((err) => toast.error(err.message))
    );
    setRejectModal(false);
  }

  function handleAcceptClaimData({ CommittedVehicleId, claimId }) {
    editClaimData({
      variables: {
        editClaimsWithoutVerifyUserWithBalanceId: claimId,
        userInput: {
          Is_Accepted: true,
        },
      },
    })
      .then(() => {
        let obj;

        if (LpClaim) {
          if (Lp_Claim_Cleared) {
            obj = {
              Sp_Claim_Cleared: true,
              Clear_Delevery_Confirmation: true,
              Current_Stage: "Clear Delevery Confirmation",
              Clear_Delevery_Confirmation_Time: new Date(),
            };
          } else {
            obj = {
              Sp_Claim_Cleared: true,
            };
          }
        } else {
          if (Sp_Claim_Cleared) {
            obj = {
              Lp_Claim_Cleared: true,
              Clear_Delevery_Confirmation: true,
              Current_Stage: "Clear Delevery Confirmation",
              Clear_Delevery_Confirmation_Time: new Date(),
            };
          } else {
            obj = {
              Lp_Claim_Cleared: true,
            };
          }
        }

        editCommittedVehicleData({
          variables: {
            editCommittedVehiclesId: CommittedVehicleId,
            userInput: obj,
          },
        })
          .then(() => {
            socket.emit("Update_CurrentTask", LpId || SpId, CommittedVehicleId);
            toast.success("Claim Accepted");
          })
          .catch((err) => toast.error(err.message));
      })
      .catch((err) => toast.error(err.message));

    setRejectModal(false);
  }

  useEffect(() => {
    if (
      editCommittedVehicleDataLoading ||
      editCommittedVehicleDataWithoutVerifyBalanceLoading ||
      editClaimDataLoading ||
      createVoucherEntriesLoading
    )
      setIsEditCommittedVehicleDataLoading(
        editCommittedVehicleDataLoading ||
          editCommittedVehicleDataWithoutVerifyBalanceLoading ||
          editClaimDataLoading ||
          createVoucherEntriesLoading
      );
  }, [
    editCommittedVehicleDataLoading,
    editCommittedVehicleDataWithoutVerifyBalanceLoading,
    editClaimDataLoading,
    createVoucherEntriesLoading,
  ]);
  return (
    <div className="viewClaimModalDiv">
      <div className="eachContentDiv">
        <p className="contentMainHeading">Claim Charges -</p>
      </div>

      {ChargesArr?.map((el) => (
        <div key={el} className="eachContentDiv">
          {el.price ? (
            <>
              <p className="contentHeading">{el.name} :</p>
              <p className="contentText">{el.price}</p>
            </>
          ) : (
            ""
          )}
        </div>
      ))}
      <div className="eachContentDiv totalDiv">
        <p className="contentHeading">Total (₹) :</p>
        <p className="contentText totalText">
          <GetTotal values={getTotalArr} />
        </p>
      </div>

      <PortalFooter>
        <Button text="Accept Claim" className={"acceptBtn"} handleClick={() => setAcceptModal(true)} />
        <Reactportal
          open={acceptModal}
          modalHeading={"Accept Claim"}
          onClose={() => setAcceptModal(false)}
          modalClassName={"confirmationModal"}
          contentClassName={"confirmationModal-content"}
        >
          <AcceptClaimCnfModal
            editCommittedVehiclesData={() =>
              handleAcceptClaimData({
                CommittedVehicleId,
                claimId,
                lpClaimArr,
                spClaimArr,
              })
            }
            onClose={() => setAcceptModal(false)}
            deductingAmount={getTotal(getTotalArr)}
            loading={isEditCommittedVehicleDataLoading}
          />
        </Reactportal>
        <Button text="Reject Claim" className={"rejectBtn"} handleClick={() => setRejectModal(true)} />
        <Reactportal
          open={rejectModal}
          modalHeading={"Reject Claim"}
          onClose={() => setRejectModal(false)}
          modalClassName={"confirmationModal"}
          contentClassName={"confirmationModal-content"}
        >
          <form onSubmit={formik.handleSubmit}>
            <div className="rejectClaimModalDiv">
              <div className="eachContentDiv">
                <p className="contentHeading">Reject Claim Reason* :</p>
                <InputBox
                  type={"text"}
                  name={"Reject_Reason"}
                  id={"Reject_Reason"}
                  className={"cnfQtyInput no-spinners"}
                  autoComplete={"off"}
                  handleInputChange={formik.handleChange}
                  handleformikdata={handleformikdata}
                  err={formik.errors.Reject_Reason}
                  touched={formik.touched.Reject_Reason ? formik.touched.Reject_Reason : false}
                />
              </div>
              <div className="eachContentDiv">
                <p className="contentText">Do you want to Reject this Claim ?</p>
              </div>
            </div>

            <PortalFooter>
              {isEditCommittedVehicleDataLoading ? (
                <Button text={<Spinner />} className="loadingAcceptBtn" type="button" />
              ) : (
                <Button text={"Confirm"} className="acceptBtn" type="submit" />
              )}

              <Button text={"Cancel"} className="rejectBtn" handleClick={() => setRejectModal(false)} />
            </PortalFooter>
          </form>
        </Reactportal>
      </PortalFooter>
    </div>
  );
}

export default ViewClaimModal;

import React from "react";
import "./PageFooter.scss";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Cookies from "js-cookie";
import { MobileNumber } from "../../../db/MobileNumber/MobileNumber";

function PageFooter() {
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const accType = accInfo.accType;

  return (
    <div className="footer">
      <div className="footerContent boxcenter">
        <p className="phonedetails boxcenter">
          <FaPhoneAlt className="phoneIcon" />
          {accType === "Supply Partner Account"
            ? "+91 " + MobileNumber.MobileNumberForSP
            : "+91 " + MobileNumber.MobileNumberForLP}
        </p>
        <p className="emaildetails boxcenter">
          <MdEmail className="emailIcon" /> contact@easemytransport.com
        </p>
      </div>

      <div className="lastDiv">
        <p className="lastDivText">@2023 Easemytransport.com</p>
      </div>
    </div>
  );
}

export default PageFooter;

import React from "react";
import { useSelector } from "react-redux";
import PageFooter from "../../../Components/Common/PageFooter/PageFooter";
import "./Layout.scss";
import TopNavbar from "../../../Components/Common/TopNavbar/TopNavbar";
import Header from "../../../Components/Common/Header/Header";
import HeaderRowTwo from "../../../Components/Common/HeaderRowTwo/HeaderRowTwo";

function Layout({ spFliterBox, children, filters }) {
  const isOpen = useSelector((store) => store.sidebarOpen.sidebarOpen);
  return (
    <div className="mainDiv">
      <Header />
      <TopNavbar filters={filters}/>
      <HeaderRowTwo spFliterBox={spFliterBox} />
      <div className={filters && isOpen ? "adjustPage" : "layout"}>{children}</div>
      <PageFooter />
    </div>
  );
}

export default Layout;

import React, { useMemo, useState } from "react";
import { useTable, useSortBy, useFilters, usePagination } from "react-table";
import { FaPencilAlt } from "react-icons/fa";
import Pagination from "../Pagination/Pagination";
import Reactportal from "../../LpComponents/Reactportal/Reactportal";
import EditUserModal from "../AllModals/EditUserModal/EditUserModal";
import InputBox from "../InputBox/InputBox";
import EditVehicleModal from "../AllModals/EditVehicleModal/EditVehicleModal";
import "./ReactTable.scss";

function ReactTable({
  DATA,
  COLOUMNS,
  editUserModal,
  editVehicleModal,
  pagination,
}) {
  const [editModal, setEditModal] = useState(false);
  const [cells, setCells] = useState({});
  const columns = useMemo(() => COLOUMNS, [COLOUMNS]);
  const data = useMemo(() => DATA, [DATA]);

  // console.log("att", data);
  // console.log(columns);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const { pageIndex, pageSize } = state;

  return (
    <>
      <table className="addUserTable" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th key={column}>
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " ↑"
                          : " ↓"
                        : ""}
                    </span>
                  </div>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  {
                    {
                      /* console.log(cell); */
                    }
                  }
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render("Cell")}
                      {cell.render("Header") === "Is Active?" ? (
                        <InputBox
                          type="checkbox"
                          checked={cell.row.original.Is_Active}
                          handleInputChange={() => console.log()}
                        />
                      ) : (
                        ""
                      )}
                      {cell.render("Header") === "Market Load Notification?" ? (
                        <InputBox
                          type="checkbox"
                          handleInputChange={() => console.log()}
                        />
                      ) : (
                        ""
                      )}
                      {cell.render("Header") === "Edit" ? (
                        <FaPencilAlt
                          className="editIcon"
                          onClick={() => {
                            setEditModal(true);
                            setCells(cell);
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          <Reactportal
            open={editModal}
            onClose={() => setEditModal(false)}
            modalHeading={
              editUserModal ? "Edit User Details" : "Edit Vehicle Details"
            }
            modalClassName={"editUserModal"}
            contentClassName="editUserModal-content"
          >
            {editUserModal && (
              <EditUserModal
                cells={cells}
                onClose={() => setEditModal(false)}
              />
            )}
            {editVehicleModal && (
              <EditVehicleModal
                cells={cells}
                onClose={() => setEditModal(false)}
              />
            )}
          </Reactportal>
        </tbody>
      </table>

      {pagination ? (
        <Pagination
          pageIndex={pageIndex}
          pageOptions={pageOptions}
          gotoPage={gotoPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          nextPage={nextPage}
          previousPage={previousPage}
          pageCount={pageCount}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default ReactTable;

import React, { useEffect, useState } from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import { unloadingCompletedModalData } from "../../../../db/ModalsData/ModalsData.config";
import InputBox from "../../InputBox/InputBox";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import socket from "../../../../CommonFunction/Socket/socketClient";
import Spinner from "../../Spinner/Spinner";
import "./RaiseClaimModal.scss";
import { EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE } from "../../../../gql/editCommitedVehiclesWithoutVerifyBalance";
import { CREATE_NEW_CLAIM } from "../../../../gql/createClaims";

function RaiseClaimModal({ LpId, SpId, CommittedVehicleId, LpRaise, SpRaise, onClose }) {
  const [createNewClaim, { loading: createNewClaimLoading }] = useMutation(CREATE_NEW_CLAIM);
  const [editCommittedVehicles, { loading: editCommittedVehiclesLoading }] = useMutation(
    EDIT_COMMITTED_VEHICLES_DATA_WITHOUT_VERIFY_BALANCE
  );

  const [isEditCommittedVehicles, setIsEditCommittedVehicles] = useState(false);

  useEffect(() => {
    if (editCommittedVehiclesLoading || createNewClaimLoading) {
      setIsEditCommittedVehicles(editCommittedVehiclesLoading || createNewClaimLoading);
    }
  }, [editCommittedVehiclesLoading, createNewClaimLoading]);

  const [total, setTotal] = useState(0);

  let initialValues;

  if (SpRaise) {
    initialValues = {
      Holding_Charges: 0,
      Hamali_Charges: 0,
      Extra_Running: 0,
      Challan_Charges: 0,
      Other_Charges: 0,
      Other_Charges_Reason: "",
      CommittedVehicleId: CommittedVehicleId,
    };
  } else {
    initialValues = {
      Shortage_Charges: 0,
      Damage_Charges: 0,
      Narration: "",
      CommittedVehicleId: CommittedVehicleId,
    };
  }
  const validate = (values) => {
    // console.log("validate", values);

    let errors = {};

    if (SpRaise) {
      if (
        !values.Holding_Charges &&
        !values.Hamali_Charges &&
        !values.Extra_Running &&
        !values.Challan_Charges &&
        !values.Other_Charges
      ) {
        errors.Holding_Charges = "Please fill at least one field";
        errors.Hamali_Charges = "Please fill at least one field";
        errors.Extra_Running = "Please fill at least one field";
        errors.Challan_Charges = "Please fill at least one field";
        errors.Other_Charges = "Please fill at least one field";
      } else if (values.Holding_Charges === "0") {
        errors.Holding_Charges = "Field can't be 0";
      } else if (values.Hamali_Charges === "0") {
        errors.Hamali_Charges = "Field can't be 0";
      } else if (values.Extra_Running === "0") {
        errors.Extra_Running = "Field can't be 0";
      } else if (values.Challan_Charges === "0") {
        errors.Challan_Charges = "Field can't be 0";
      } else if (values.Other_Charges === "0") {
        errors.Other_Charges = "Field can't be 0";
      }

      if (values.Other_Charges && !values.Other_Charges_Reason) {
        errors.Other_Charges_Reason = "Please Enter Reason";
      }
    } else {
      if (!values.Shortage_Charges && !values.Damage_Charges) {
        errors.Shortage_Charges = "Please fill at least one field";
        errors.Damage_Charges = "Please fill at least one field";
      } else if (values.Shortage_Charges === "0") {
        errors.Shortage_Charges = "Field can't be 0";
      } else if (values.Damage_Charges === "0") {
        errors.Damage_Charges = "Field can't be 0";
      }

      if (!values.Narration) {
        errors.Narration = "Please Enter Narration";
      }
    }

    return errors;
  };
  const onSubmit = (values) => {
    // console.log("onSubmit", values);
    let copyValues = values;
    let obj;
    if (LpRaise) {
      obj = {
        Lp_Claim_Raised: true,
      };
    }
    if (SpRaise) {
      obj = {
        Sp_Claim_Raised: true,
      };
    }

    let newClaimObj;

    if (SpRaise) {
      newClaimObj = {
        ...copyValues,
        Holding_Charges: +values.Holding_Charges,
        Hamali_Charges: +values.Hamali_Charges,
        Extra_Running: +values.Extra_Running,
        Challan_Charges: +values.Challan_Charges,
        Other_Charges: +values.Other_Charges,
      };
    }

    if (LpRaise) {
      newClaimObj = {
        ...copyValues,
        Shortage_Charges: +values.Shortage_Charges,
        Damage_Charges: +values.Damage_Charges,
      };
    }

    // console.log(obj, "objjjj")

    editCommittedVehicles({
      variables: {
        editCommittedVehiclesWithoutVerifyUserWithBalanceId: CommittedVehicleId,
        userInput: obj,
      },
    })
      .then(() =>
        createNewClaim({
          variables: {
            createInput: {
              ...newClaimObj,
            },
          },
        })
          .then(() => {
            toast.success("Claim Raised");
            socket.emit("Update_CurrentTask", LpId || SpId, CommittedVehicleId);
            onClose();
          })
          .catch((err) => toast.error(err.message))
      )
      .catch((err) => toast.error(err.message));
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });
  useEffect(() => {
    let sum = 0;

    if (SpRaise) {
      sum =
        (formik.values.Holding_Charges && !isNaN(formik.values.Holding_Charges)
          ? parseFloat(formik.values.Holding_Charges)
          : 0) +
        (formik.values.Hamali_Charges && !isNaN(formik.values.Hamali_Charges)
          ? parseFloat(formik.values.Hamali_Charges)
          : 0) +
        (formik.values.Extra_Running && !isNaN(formik.values.Extra_Running)
          ? parseFloat(formik.values.Extra_Running)
          : 0) +
        (formik.values.Challan_Charges && !isNaN(formik.values.Challan_Charges)
          ? parseFloat(formik.values.Challan_Charges)
          : 0) +
        (formik.values.Other_Charges && !isNaN(formik.values.Other_Charges)
          ? parseFloat(formik.values.Other_Charges)
          : 0);
    }
    if (LpRaise) {
      sum =
        (formik.values.Shortage_Charges && !isNaN(formik.values.Shortage_Charges)
          ? parseFloat(formik.values.Shortage_Charges)
          : 0) +
        (formik.values.Damage_Charges && !isNaN(formik.values.Damage_Charges)
          ? parseFloat(formik.values.Damage_Charges)
          : 0);
    }

    setTotal(sum);
  }, [formik.values, SpRaise, LpRaise]);

  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="raiseClaimModalDiv">
        {unloadingCompletedModalData?.map((el) =>
          el.by === "lp" && SpRaise ? (
            <div key={el.name} className="eachContentDiv">
              <p className="contentHeading">{el.contentHeading}</p> :
              <InputBox
                className={"cnfQtyInput no-spinners"}
                type={el.type}
                name={el.name}
                handleInputChange={formik.handleChange}
                handleformikdata={handleformikdata}
                autoComplete={el.autoComplete}
                placeholder={el.placeholder}
                err={formik.errors[el.name]}
                touched={formik.touched[el.name] ? formik.touched[el.name] : false}
                isRequired={false}
              />
            </div>
          ) : (
            ""
          )
        )}
        {unloadingCompletedModalData?.map((el) =>
          el.by === "sp" && LpRaise ? (
            <div key={el.name} className="eachContentDiv">
              <p className="contentHeading">{el.contentHeading}</p> :
              <InputBox
                className={"cnfQtyInput no-spinners"}
                type={el.type}
                name={el.name}
                handleInputChange={formik.handleChange}
                handleformikdata={handleformikdata}
                autoComplete={el.autoComplete}
                placeholder={el.placeholder}
                err={formik.errors[el.name]}
                touched={formik.touched[el.name] ? formik.touched[el.name] : false}
                isRequired={false}
              />
            </div>
          ) : (
            ""
          )
        )}

        <div className="eachContentDiv totalDiv">
          <p className="contentHeading">Total (₹) </p> :<p className="contentText totalText">{total}</p>
        </div>

        <PortalFooter>
          {isEditCommittedVehicles ? (
            <Button text={<Spinner />} className={"loadingAcceptBtn"} type={"button"} />
          ) : (
            <Button text="Confirm" className={"acceptBtn"} type={"submit"} handleClick={formik.handleSubmit} />
          )}

          <Button text="Cancel" className={"rejectBtn"} handleClick={onClose} />
        </PortalFooter>
      </div>
    </form>
  );
}

export default RaiseClaimModal;

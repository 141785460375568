import { gql } from "@apollo/client";

export const GET_SUB_USERS = gql`
  query getAllSubUsersForRootUser($Root_User_Id: ID!) {
    getAllSubUsersForRootUser(Root_User_Id: $Root_User_Id) {
      id
      Unique_Id
      accType
      firstName
      FullName
      Type_Of_User
      mobileNumber
      email
      password
      Is_Active
    }
  }
`;

import { useQuery } from "@apollo/client";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { GET_LPPAGE_COUNT, GET_SPPAGE_COUNT } from "../../../gql/getCount";
import socket from "../../../CommonFunction/Socket/socketClient";
import { useDispatch, useSelector } from "react-redux";

function TopNavbarSection({ img, name, fieldsdata }) {
  const [countData, setCountData] = useState([]);
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const accType = accInfo.accType;
  const dispatch = useDispatch();
  const isLoadData = useSelector((store) => store.loadData.loadData);

  const { data, refetch } = useQuery(accType === "Load Partner Account" ? GET_LPPAGE_COUNT : GET_SPPAGE_COUNT, {
    variables: {
      Is_Accepted: true,
    },
  });

  useEffect(() => {
    if (data) {
      setCountData(accType === "Load Partner Account" ? data.GetLpPageData_Count : data.GetSpPageData_Count);
    }
  }, [data, accType]);

  useEffect(() => {
    socket.on("NewLoad_Recived", () => {
      refetch();
    });
  }, []);

  useEffect(() => {
    socket.on("CurrentTask_Update", (id) => {
      refetch();
    });
  }, []);

  useEffect(() => {
    socket.on("Refetch_EditConfirmVechileswithId", (LoadIdfrombackend) => {
      refetch();
    });
  }, []);

  useEffect(() => {
    if (isLoadData) {
      refetch();
      dispatch({ type: "set_loadData", payload: false });
    }
  }, [isLoadData, refetch]);

  return (
    <div className="topNavbarSectionDiv">
      <div className="line"></div>
      <img src={img} alt="boxload" />
      <div className="navonetitle">
        <p>{name}</p>
      </div>

      {fieldsdata.map((e) => {
        let count;
        if (e.name === "Live") {
          count = countData.Live_Count;
        }
        if (e.name === "Resulted") {
          count = countData.Resulted_Count;
        }
        if (e.name === "Archive") {
          count = countData.Archive_Count;
        }
        if (e.name === "Ongoing") {
          count = countData.OnGoing;
        }
        if (e.name === "Completed") {
          count = countData.Completed;
        }
        if (e.name === "Open") {
          count = countData.Live_Count;
        }
        if (e.name === "Awarded") {
          count = countData.Awarded;
        }
        return (
          <div key={e.name}>
            <NavLink to={e.path} className={({ isActive }) => (isActive ? "activePage" : "routelink")}>
              <button className="links boxcenter">
                <p>
                  {e.name}
                  {e.name === "Account Statement" ? null : count ? ` (${count})` : ` (0)`}
                </p>
              </button>
            </NavLink>
          </div>
        );
      })}
    </div>
  );
}

export default TopNavbarSection;

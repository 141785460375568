// socketClient.js

import { useEffect } from "react";
import { io } from "socket.io-client";

// const SOCKET_SERVER_URL = "http://ec2-15-206-81-66.ap-south-1.compute.amazonaws.com:4000"; //Server URL
const SOCKET_SERVER_URL = process.env.REACT_APP_GRAPHQL_URI; //Server URL

const socket = io(SOCKET_SERVER_URL); //Socket connect

// console.log(document.cookie, "document.cookie",document);

export default socket;
  
import { useLazyQuery } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { GET_LOGIN_OTP, VERIFY_LOGIN_OTP } from "../../../gql/login";
import OTPInput from "react-otp-input";
import { useFormik } from "formik";
import Button from "../Button/Button";
import SpinnerIndicator from "../SpinnerIndicator/SpinnerIndicator";
import { useTimer } from "../../../hooks";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

function CheckLoginOtp({ sessionId, content, loginType }) {
  const [otp, setOtp] = useState("");
  const { isTimerRunning, seconds, start: handleStartTimer, stop: handleStopTimer } = useTimer(59);
  const [verifyLoginOtp, { loading, data, error }] = useLazyQuery(VERIFY_LOGIN_OTP);
  const [getOtp] = useLazyQuery(GET_LOGIN_OTP, {
    fetchPolicy: "no-cache",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    handleStartTimer();
  }, []);

  useEffect(() => {
    if (data && data.MobileVerificationCheckOTP) {
      if (data.MobileVerificationCheckOTP && data.MobileVerificationCheckOTP.User_Exist) {
        Cookies.set("token", data.MobileVerificationCheckOTP.UserLoginOutput.token);
        Cookies.set(
          "accInfo",
          JSON.stringify({
            id: data.MobileVerificationCheckOTP.UserLoginOutput.user.id,
            accType: data.MobileVerificationCheckOTP.UserLoginOutput.user.accType,
            firstName: data.MobileVerificationCheckOTP.UserLoginOutput.user.firstName,
            lastName: data.MobileVerificationCheckOTP.UserLoginOutput.user.lastName,
            FullName: data.MobileVerificationCheckOTP.UserLoginOutput.user.FullName,
            email: data.MobileVerificationCheckOTP.UserLoginOutput.user.email,
            mobileNumber: data.MobileVerificationCheckOTP.UserLoginOutput.user.mobileNumber,
            Type_Of_User: data.MobileVerificationCheckOTP.UserLoginOutput.user.Type_Of_User,
            Root_User: data.MobileVerificationCheckOTP.UserLoginOutput.user.Root_User,
            Is_Active: data.MobileVerificationCheckOTP.UserLoginOutput.user.Is_Active,
            Unique_Id: data.MobileVerificationCheckOTP.UserLoginOutput.user.Unique_Id,
          })
        );
        Cookies.set("accDataId", data.MobileVerificationCheckOTP.UserLoginOutput.AccountData[0]?._id);
        Cookies.set("userAccId", data.MobileVerificationCheckOTP.UserLoginOutput.AccountData[0]?.Unique_Id);
        Cookies.set(
          "accData",
          JSON.stringify({
            Account_Number: data.MobileVerificationCheckOTP.UserLoginOutput.AccountData[0]?.Account_Number,
          })
        );
        dispatch({ type: "set_userInfo", payload: data.MobileVerificationCheckOTP.UserLoginOutput.user });
        dispatch({ type: "set_accType", payload: data.MobileVerificationCheckOTP.UserLoginOutput.user.accType });
        dispatch({ type: "set_userToken", payload: data.MobileVerificationCheckOTP.UserLoginOutput.token });
        if (data.MobileVerificationCheckOTP.UserLoginOutput.user.accType === "Load Partner Account") {
          navigate("/live");
        } else {
          navigate("/open");
        }
        window.location.reload();
        toast.success("Login Success");
        handleStopTimer();
      } else {
        dispatch({
          type: "set_signupdata",
          payload: {
            accType: loginType,
            mobileNumber: content,
          },
        });
        navigate("/signup");
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const initialValues = { otp: "", sessionId: sessionId };

  const validate = (values) => {
    const errors = {};

    return errors;
  };

  const onSubmit = (values) => {
    if (otp.length === 4) {
      verifyLoginOtp({
        variables: {
          mobileNumber: +content,
          userInputOtp: +otp,
        },
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  return (
    <div className="checkOtpDiv">
      <p className="checkOtpHeading">Enter 4 Digit OTP Code</p>
      <p className="checkOtpSubHeading">Enter the 4 digits code that you recceived on {content}.</p>
      <div className="otpDiv">
        <form onSubmit={formik.handleSubmit} autoComplete="off">
          <div className="enterOtpDiv">
            <div className="otp-container">
              <OTPInput
                value={otp}
                onChange={setOtp}
                shouldAutoFocus={true}
                numInputs={4}
                renderSeparator={<span></span>}
                inputType="tel"
                containerStyle={{ gap: 5 }}
                inputStyle={{
                  MozAppearance: "textfield",
                  padding: 5,
                  fontSize: 20,
                  border: "1px solid gray",
                  borderRadius: 5,
                  height: 25,
                }}
                renderInput={(props) => <input {...props} />}
              />
            </div>
          </div>
          <div className="resendOtpTextDiv">
            {isTimerRunning ? (
              <p className="resendOtpText">
                Resend OTP in <span className="highlightSecText">{seconds}</span> seconds
              </p>
            ) : (
              <p className="resendOtpText">
                Dont receive the OTP?{" "}
                <span
                  className="highlightText"
                  onClick={() => {
                    getOtp({
                      variables: {
                        mobileNumber: content,
                        accType: loginType,
                      },
                    });
                    handleStartTimer();
                  }}
                >
                  RESEND OTP
                </span>
              </p>
            )}
          </div>

          <div className="submitBtnDiv">
            <Button
              className={otp.length !== 4 || loading ? "disabledBtnBank" : "acceptBtn"}
              type={"submit"}
              text={loading ? <SpinnerIndicator /> : "Verify OTP"}
              disabled={loading || otp.length !== 4}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CheckLoginOtp;

import React from "react";
import Button from "../../../Common/Button/Button";
import Spinner from "../../../Common/Spinner/Spinner";
import "./FileUploadFooter.scss";

function FileUploadFooter({
  submitform,
  onClose,
  downloadBtn,
  downloadableFile,
  deleteFile,
  disabled,
  loading,
}) {
  return (
    <>
      <Button
        className={disabled ? "modalbuttonDisable" : "modalbutton"}
        text={loading ? <Spinner /> : "Upload"}
        handleClick={submitform}
        disabled={loading ? loading : disabled}
      />
      {downloadBtn && (
        <a href={downloadableFile} download>
          <Button className={"modalbutton"} text="Download" />
        </a>
      )}
      {downloadBtn && (
        <Button
          className={disabled ? "modalbuttonDisable" : "modalbutton"}
          text="Delete"
          handleClick={deleteFile}
          disabled={disabled}
        />
      )}

      <Button className={"rejectBtn"} text="Okay" handleClick={onClose} />
    </>
  );
}

export default FileUploadFooter;

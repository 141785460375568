const Loadfilds = [
  {
    id: Math.random() * 10,
    name: "Load_Details",
    fields: [
      {
        id: Math.random() * 10,
        name: "From_City",
        label: "From City",
        placeholder: "From City",
        type: "inputdrop",
        autoComplete: "off",
        // customSelect:true,
        isSearchable: false,
        // dropdowncontent: [],
        searchType: true,
        require: true,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "To_City",
        label: "To City",
        placeholder: "To City",
        type: "inputdrop",
        autoComplete: "off",
        isSearchable: false,
        // customSelect:true,
        // dropdowncontent: [],
        searchType: true,
        require: true,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Payment_Type",
        label: "Payment Type",
        placeholder: "Payment Type",
        type: "inputdrop",
        autoComplete: "off",
        isSearchable: true,
        require: true,
        autoEveryCapital: true,
        // readOnly: true,
        dropdowncontent: [
          "Advance 90%",
          // "Advance 100%", "To Pay"
        ],
      },
      {
        id: Math.random() * 10,
        name: "Loading_Date",
        label: "Loading Date",
        type: "date",
        require: true,
        isSearchable: false,
        mindate: true,
      },
      {
        id: Math.random() * 10,
        name: "Loading_Time",
        label: "Loading Time",
        placeholder: "Loading Time",
        type: "time",
        autoComplete: "off",
        require: true,
        step: 900,
        isSearchable: false,
      },
      {
        id: Math.random() * 10,
        name: "Loading_By",
        label: "Loading By",
        placeholder: "Loading By",
        type: "inputdrop",
        autoComplete: "off",
        isSearchable: true,
        require: true,
        autoEveryCapital: true,
        dropdowncontent: ["Hand", "Fork Lift", "Crane"],
      },
      // {
      //   id: Math.random() * 10,
      //   name: "Loading_Scope",
      //   label: "Loading Scope",
      //   placeholder: "Loading Scope",
      //   type: "inputdrop",
      //   autoComplete: "off",
      //   isSearchable: true,
      //   require: true,
      //   autoEveryCapital: true,
      //   dropdowncontent: ["Consignor", "Transporter"],
      // },
      // {
      //   id: Math.random() * 10,
      //   name: "Unloading_Scope",
      //   label: "Unloading Scope",
      //   placeholder: "Unloading Scope",
      //   type: "inputdrop",
      //   autoComplete: "off",
      //   isSearchable: true,
      //   require: true,
      //   autoEveryCapital: true,
      //   dropdowncontent: ["Consignee", "Transporter"],
      // },
    ],
  },
  {
    id: Math.random() * 10,
    name: "Loading_Point",
    label: "Loading_Point",
    autoComplete: "none",
    fields: [
      {
        id: Math.random() * 10,
        name: "Loading_Name",
        label: "Loading_Name",
        lable: "Name",
        type: "text",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Loading_Address_Line_1",
        label: "Loading Address Line 1",
        lable: "Name",
        type: "text",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      // {
      //   id: Math.random() * 10,
      //   name: "Loading_Address_Line_2",
      //   label: "Loading Address Line 2",
      //   lable: "Name",
      //   type: "text",
      //   require: false,
      //   isSearchable: false,
      //   autoEveryCapital: true,
      // },
      {
        id: Math.random() * 10,
        name: "Loading_Area",
        label: "Loading Area",
        type: "text",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Loading_Landmark",
        label: "Loading Landmark",
        type: "text",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Loading_Pincode",
        label: "Loading Pincode",
        autoComplete: "none",
        type: "number",
        hasApiCallback: "true",
        require: true,
        min: 6,
        max: 6,
        maxNo: 6,
        isSearchable: false,
      },

      {
        id: Math.random() * 10,
        name: "Loading_City",
        label: "Loading City",
        setvalue: "City",
        autoComplete: "none",
        type: "text",
        readOnly: true,
        require: true,

        isSearchable: false,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Loading_State",
        label: "Loading State",
        setvalue: "State",
        autoComplete: "none",
        type: "text",
        readOnly: true,
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
    ],
  },
  {
    id: Math.random() * 10,
    name: "Unloading_Point",
    label: "Unloading Point",

    fields: [
      {
        id: Math.random() * 10,
        name: "Unloading_Name",
        label: "Unloading Name",
        lable: "Name",
        type: "text",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Unloading_Address_Line_1",
        label: "Unloading Address Line 1",
        lable: "Name",
        type: "text",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      // {
      //   id: Math.random() * 10,
      //   name: "Unloading_Address_Line_2",
      //   label: "Unloading Address Line 2",
      //   lable: "Name",
      //   type: "text",
      //   require: false,
      //   isSearchable: false,
      //   autoEveryCapital: true,
      // },
      {
        id: Math.random() * 10,
        name: "Unloading_Area",
        label: "Unloading Area",
        type: "text",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Unloading_Landmark",
        label: "Unloading Landmark",
        type: "text",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Unloading_Pincode",
        label: "Unloading Pincode",
        autoComplete: "none",
        type: "number",
        hasApiCallback: "true",
        require: true,
        min: 6,
        max: 6,
        maxNo: 6,
        isSearchable: false,
      },
      {
        id: Math.random() * 10,
        name: "Unloading_City",
        label: "Unloading City",
        setvalue: "City",
        autoComplete: "none",
        type: "text",
        readOnly: true,
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Unloading_State",
        label: "Unloading State",
        setvalue: "State",
        autoComplete: "none",
        type: "text",
        readOnly: true,
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
    ],
  },
  {
    id: Math.random() * 10,
    name: "Material_Details",
    label: "Material Details",
    fields: [
      {
        id: Math.random() * 10,
        name: "Material_Category",
        label: "Material Category",
        placeholder: "Material Category",
        type: "inputdrop",
        autoComplete: "off",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
        dropdowncontent: [
          "Industrial Machinery",
          "Household Goods",
          "Part Load / Parcel / luggage transport",
          "Fresh Fruits / Vegetables",
          "FMCG / Packed Food Products",
          "Healthcare / Pharmacy Products / Medicines",
          "Rice/Wheat/Grains",
          "Chemicals Powder / Liquid Barrels",
          "Fertilizer",
          "Electronic Goods / Home Appliances",
          "Paper / Packaging / Printed Material",
          "Electrical Transformer / Panels / Equipments / Spare Parts",
          "Solar / Battery / Inverter Products",
          "Ceramic / Hardware Supplies",
          "Electrical Wires / Cables",
          "Books / Stationery / Toys / Gifts",
          "Aluminium / Steel / Metal Products",
          "Building / Construction Material",
          "Paint / Houseware Supplies",
          "Textile / Garments",
          "Plastic / Pvc / Rubber Products",
          "Machine / Auto Parts",
          "Exhibitions / Event Supplies",
          "Furniture / Plywood / Laminates",
          "Car / Bike",
          "Live Animals",
          "Scrap",
          "Plants",
          "Others",
        ],
      },
      {
        id: Math.random() * 10,
        name: "Exact_Content",
        label: "Exact Content",
        type: "text",
        require: true,
        isSearchable: false,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Total_Weight",
        label: "Total Weight (MT)",
        type: "number",
        step: "0.01",
        min: 2,
        max: Infinity,
        require: true,
        isSearchable: false,
      },
      {
        id: Math.random() * 10,
        name: "Over_Dimension_Cargo",
        label: "Over Dimension Cargo",
        placeholder: "Over Dimension Cargo",
        type: "inputdrop",
        autoComplete: "off",
        require: true,
        isSearchable: true,
        dropdowncontent: ["Yes", "No"],
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Material_Dimension",
        label: "Material Dimension (L × W × H)",
        type: "mask",
        mask: true,
        require: false,
        isSearchable: false,
      },
      {
        id: Math.random() * 10,
        name: "Material_Dimension_In",
        label: "Material Dimension's In",
        type: "inputdrop",
        autoComplete: "off",
        dropdowncontent: ["Feet (Ft)", "Meter (Mt)"],
        require: false,
        isSearchable: true,
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Dangerous_Goods",
        label: "Dangerous Goods",
        placeholder: "Dangerous Goods",
        isSearchable: true,
        type: "inputdrop",
        autoComplete: "off",
        require: true,
        dropdowncontent: ["Yes", "No"],
        autoEveryCapital: true,
      },
      {
        id: Math.random() * 10,
        name: "Material_Insurence",
        label: "Is Material Insured ?",
        placeholder: "Material Insurence",
        type: "inputdrop",
        autoComplete: "off",
        isSearchable: true,
        require: true,
        dropdowncontent: ["Yes", "No"],
        autoEveryCapital: true,
      },
    ],
  },
  {
    id: Math.random() * 10,
    name: "Vehicle_Details",
    label: "Vehicle Details",
    fields: [
      {
        id: Math.random() * 10,
        name: "Vehicle_Type_String",
        label: "Vehicle Type",
        placeholder: "Vehicle Type",
        type: "inputdrop",
        autoComplete: "off",
        isSearchable: true,
        customSelect: true,
        require: true,
        autoEveryCapital: true,
        dropdowncontent: [
          { value: "Trailer Dala Body", label: "Trailer Dala Body" },
          { value: "Trailer Flat Bed", label: "Trailer Flat Bed" },
          { value: "Open Body Truck", label: "Open Body Truck" },
          { value: "Container", label: "Container" },
          { value: "Tanker", label: "Tanker" },
          { value: "Tipper", label: "Tipper" },
          { value: "Dumper", label: "Dumper" },
          { value: "Bulker", label: "Bulker" },
          { value: "LVC Open", label: "LVC Open" },
          { value: "LVC Container", label: "LVC Container" },
          { value: "Mini/Pickup", label: "Mini/Pickup" },
        ],
      },
      {
        id: Math.random() * 10,
        name: "Weight_Category_String",
        label: "Weight Category",
        placeholder: "Weight Category",
        type: "inputdrop",
        autoComplete: "off",
        customSelect: true,
        isMulti: true,
        // closeMenuOnSelect: false,
        isSearchable: true,
        require: true,
        autoEveryCapital: true,
      },
    ],
  },
];

const AuctionLoadData = {
  id: Math.random() * 10,
  name: "Auction_Load",
  label: "Auction_Load",
  fields: [
    {
      id: Math.random() * 10,
      name: "Auction_Date",
      label: "Auction Date",
      type: "date",
      require: true,
      isSearchable: false,
      mindate: true,
    },
    {
      id: Math.random() * 10,
      name: "Auction_Time",
      label: "Auction Time",
      placeholder: "Auction Time",
      type: "inputdrop",
      autoComplete: "off",
      require: true,
      isSearchable: true,
      dropdowncontent: [
        "08:00 - 08:30 AM",
        "09:00 - 09:30 AM",
        "10:00 - 10:30 AM",
        "12:00 - 12:30 PM",
        "02:00 - 02:30 PM",
        "07:00 - 07:30 PM",
      ],
    },
    {
      id: Math.random() * 10,
      name: "Load_Manager",
      label: "Load Manager",
      placeholder: "Load Manager",
      type: "inputdrop",
      autoComplete: "off",
      isSearchable: true,
      require: true,
      searchType: true,
      autoEveryCapital: true,
      // dropdowncontent: ["Manish", "Madhu"],
    },
    {
      id: Math.random() * 10,
      name: "Rate_per",
      label: "Rate per",
      placeholder: "Rate per",
      type: "inputdrop",
      autoComplete: "off",
      isSearchable: true,
      require: true,
      autoEveryCapital: true,
      dropdowncontent: ["Per Vehicle", "Per MT"],
    },
    {
      id: Math.random() * 10,
      name: "No_Of_Vehicels_Required",
      label: "No Of Vehicles Required",
      placeholder: "No Of Vehicels Required",
      type: "inputdrop",
      autoComplete: "off",
      isSearchable: true,
      require: true,
      dropdowncontent: [1, 2, 3],
    },
    {
      id: Math.random() * 10,
      name: "Expected_Price",
      label: "Expected Price (₹)",
      type: "number",
      isSearchable: false,
      require: true,
    },
  ],
};

const OpenLoadData = {
  id: Math.random() * 10,
  name: "Open_Load",
  label: "Open_Load",
  fields: [
    {
      id: Math.random() * 10,
      name: "Load_Live_Duration",
      label: "Load Live Duration",
      placeholder: "Load Live Duration",
      type: "inputdrop",
      autoComplete: "off",
      require: true,
      isSearchable: true,
      dropdowncontent: ["1HR", "2HR", "4HR", "6HR", "12HR", "24HR"],
    },
    {
      id: Math.random() * 10,
      name: "Load_Manager",
      label: "Load Manager",
      placeholder: "Load Manager",
      type: "inputdrop",
      autoComplete: "off",
      isSearchable: true,
      require: true,
      searchType: true,
      autoEveryCapital: true,
      // dropdowncontent: ["Manish", "Madhu"],
    },
    {
      id: Math.random() * 10,
      name: "Rate_per",
      label: "Rate per",
      placeholder: "Rate per",
      type: "inputdrop",
      autoComplete: "off",
      isSearchable: true,
      require: true,
      autoEveryCapital: true,
      dropdowncontent: ["Per Vehicle", "Per MT"],
    },
    {
      id: Math.random() * 10,
      name: "No_Of_Vehicels_Required",
      label: "No Of Vehicels Required",
      placeholder: "No Of Vehicels Required",
      type: "inputdrop",
      autoComplete: "off",
      isSearchable: true,
      require: true,
      dropdowncontent: [1, 2, 3],
    },
    {
      id: Math.random() * 10,
      name: "Target_Price",
      label: "Target Price (₹)",
      type: "number",
      require: true,
      isSearchable: false,
    },
  ],
};
const FixedLoadData = {
  id: Math.random() * 10,
  name: "Fixed_Price_Load",
  label: "Fixed_Load",
  fields: [
    {
      id: Math.random() * 10,
      name: "Load_Live_Duration",
      label: "Load Live Duration",
      placeholder: "Load Live Duration",
      type: "inputdrop",
      autoComplete: "off",
      require: true,
      isSearchable: true,
      dropdowncontent: ["1HR", "2HR", "4HR", "6HR", "12HR", "24HR"],
    },
    {
      id: Math.random() * 10,
      name: "Load_Manager",
      label: "Load Manager",
      placeholder: "Load Manager",
      type: "inputdrop",
      autoComplete: "off",
      isSearchable: true,
      require: true,
      searchType: true,
      autoEveryCapital: true,
      // dropdowncontent: ["Manish", "Madhu"],
    },
    {
      id: Math.random() * 10,
      name: "Rate_per",
      label: "Rate per",
      placeholder: "Rate per",
      type: "inputdrop",
      autoComplete: "off",
      isSearchable: true,
      require: true,
      autoEveryCapital: true,
      dropdowncontent: ["Per Vehicle", "Per MT"],
    },
    {
      id: Math.random() * 10,
      name: "No_Of_Vehicels_Required",
      label: "No Of Vehicels Required",
      placeholder: "No Of Vehicels Required",
      type: "inputdrop",
      autoComplete: "off",
      isSearchable: true,
      require: true,
      dropdowncontent: [1, 2, 3],
    },
    {
      id: Math.random() * 10,
      name: "Target_Price",
      label: "Target Price (₹)",
      type: "number",
      require: true,
      isSearchable: false,
    },
  ],
};

const DocUpload = [
  {
    id: Math.random() * 10,
    name: "Material_Image",
    lable: "Material Image",
    require: true,
  },
  {
    id: Math.random() * 10,
    name: "Material_Image_2",
    lable: "Material Image 2",
    require: true,
  },
  {
    id: Math.random() * 10,
    name: "Material_Image_3",
    lable: "Material Image 3",
    require: true,
  },
];

export { Loadfilds, AuctionLoadData, OpenLoadData, FixedLoadData, DocUpload };

import React from "react";
import "./AwardedCard.scss";
import FromAndTo from "../../../../../../Components/SpComponents/CardComponents/FromAndTo/FromAndTo";
import Id from "../../../../../../Components/SpComponents/CardComponents/Id/Id";
import LoadingPoint from "../../../../../../Components/SpComponents/CardComponents/LoadingPoint/LoadingPoint";
import LoadingDetails from "../../../../../../Components/SpComponents/CardComponents/LoadingDetails/LoadingDetails";
import Note from "../../../../../../Components/SpComponents/CardComponents/Note/Note";
import Pointofcontact from "../../../../../../Components/SpComponents/CardComponents/PointOfContact/PointOfContact";
import Typeofload from "../../../../../../Components/SpComponents/CardComponents/TypeOfLoad/TypeOfLoad";
import UnloadingPoint from "../../../../../../Components/SpComponents/CardComponents/UnloadingPoint/UnloadingPoint";
import VehicleDetails from "../../../../../../Components/SpComponents/CardComponents/VehicleDetails/MaterialDetails";
import CardHeader from "../../../../../../Components/SpComponents/CardComponents/CardHeader/CardHeader";
import CardContent from "../../../../../../Components/SpComponents/CardComponents/CardContent/CardContent";
import CardFooter from "../../../../../../Components/SpComponents/CardComponents/CardFooter/CardFooter";
import SpFinalResultBox from "../../../Resulted/ResultedComponents/SpFinalResultBox/SpFinalResultBox";
import ResultedBox from "../../../../../../Components/SpComponents/ResultedBox/ResultedBox";
import Card from "../../../../../../Components/Common/Card/Card";

const AwardedCard = ({
  _id,
  loadId,
  Load_Post_Type,
  From_City,
  To_City,
  Total_Distance,
  Consignor_Area,
  Consignor_Landmark,
  Consignor_City,
  Consignor_State,
  Consignor_Pincode,
  Consignee_Area,
  Consignee_Landmark,
  Consignee_City,
  Consignee_State,
  Consignee_Pincode,
  No_Of_Vehicels_Required,
  Vehicle_Type_String,
  Weight_Category_String,
  Payment_Type,
  Material_Insurence,
  Loading_By,
  // Loading_Scope,
  // Unloading_Scope,
  Over_Dimension_Cargo,
  Total_Weight,
  Exact_Content,
  Loading_Date,
  Loading_time,
  Material_Category,
  Dangerous_Goods,
  Material_Dimension,
  Material_Dimension_In,
  Auction_Time,
  Auction_Date,
  Load_Live_Duration,
  Rate_Per,
  Notes,
  LPUserId,
  createdAt,
}) => {
  return (
    <Card>
      <div className="SpContainer">
        <CardHeader>
          <Id loadId={loadId} createdAt={createdAt} />
          <FromAndTo fromCity={From_City} toCity={To_City} totalDistance={Total_Distance} />
          <Typeofload Load_Post_Type={Load_Post_Type} Rate_Per={Rate_Per} />
        </CardHeader>
        <CardContent>
          <div className="detailssection">
            <LoadingPoint
              area={Consignor_Area}
              landmark={Consignor_Landmark}
              city={Consignor_City}
              state={Consignor_State}
              pincode={Consignor_Pincode}
            />
            <UnloadingPoint
              area={Consignee_Area}
              landmark={Consignee_Landmark}
              city={Consignee_City}
              state={Consignee_State}
              pincode={Consignee_Pincode}
            />
          </div>

          <div className="detailssection">
            <LoadingDetails
              fromcity={From_City}
              tocity={To_City}
              loadingdate={Loading_Date}
              loadingtime={Loading_time}
              paymenttype={Payment_Type}
              loadingby={Loading_By}
              // loadingscope={Loading_Scope}
              // unloadingscope={Unloading_Scope}
            />
          </div>
          <div className="detailssection">
            <VehicleDetails
              ODC={Over_Dimension_Cargo}
              Dangerous_Goods={Dangerous_Goods}
              matcat={Material_Category}
              exactcontent={Exact_Content}
              totalweight={Total_Weight}
              materialinsurence={Material_Insurence}
              Material_Dimension={Material_Dimension}
              noofvechile={No_Of_Vehicels_Required}
              vehicletype={Vehicle_Type_String}
              weightcategory={Weight_Category_String}
              Material_Dimension_In={Material_Dimension_In}
            />
          </div>
          <div className="auctionquoteDiv quoteandtimer">
            <SpFinalResultBox loadId={_id} bidNote={true} Load_Post_Type={Load_Post_Type} />
          </div>
        </CardContent>
        <CardFooter>
          <div className="flexbox">
            <Note Notes={Notes} />
            <Pointofcontact
              createdAt={createdAt}
              Load_Live_Duration={Load_Live_Duration}
              Auction_Date={Auction_Date}
              Auction_Time={Auction_Time}
            />
          </div>
          <ResultedBox loadId={_id} LPUserId={LPUserId} />
        </CardFooter>
      </div>
    </Card>
  );
};

export default AwardedCard;

import React from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import Spinner from "../../Spinner/Spinner";
import "./CnfModal.scss";

function CnfModal({ cnfMessage, editCommittedVehiclesData, onClose, loading }) {
  return (
    <div className="confirmModalDiv">
      <div className="confirmModalMessageDiv">
        <p className="confirmModalMessage">{cnfMessage}</p>
      </div>
      <PortalFooter>
        {loading ? (
          <Button
            text={<Spinner />}
            type="submit"
            className={"loadingAcceptBtn"}
          />
        ) : (
          <Button
            text={"Confirm"}
            type="submit"
            className={"acceptBtn"}
            handleClick={() => {
              editCommittedVehiclesData();
              onClose();
            }}
            disabled={loading}
          />
        )}

        <Button
          text={"Cancel"}
          className="rejectBtn"
          type="button"
          handleClick={onClose}
        />
      </PortalFooter>
    </div>
  );
}

export default CnfModal;

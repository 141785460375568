import React from "react";
import Cookies from "js-cookie";
import "./Greeting.scss";

function Greeting() {
  const UserInfo = JSON.parse(Cookies.get("accInfo") || "{}");

  return (
    <div className="greetingDiv">
      <p className="greetingText">Hello, {UserInfo.FullName || UserInfo.firstName}</p>
    </div>
  );
}

export default Greeting;

import { gql } from "@apollo/client";

export const GET_TERMS_AND_CONDITIONS = gql`
  query GetAllTermsAndConditionsByTypeOfAccount($typeOfUser: String) {
    GetAllTermsAndConditionsByTypeOfAccount(Type_of_User: $typeOfUser) {
      lpData {
        Title
        Description
        Serial_No
      }

      spData {
        Title
        Description
        Serial_No
      }
    }
  }
`;
const data = {
    fielddata: {},
    slectedFilter : "",

  };
  export function FilterDataReducer(state = data, { type, payload }) {
    // console.log(state);
    switch (type) {
      case "addfields": {
        return {
          ...state,
          fielddata: {
            ...state.fielddata,
            [payload.key]: payload.data,
          },
        };
      }
      
      default: {
        return state;
      }
    }
  }
  
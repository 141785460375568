import React from "react";
import "./ShowNoDataFound.scss";

function ShowNoDataFound({ msg }) {
  return (
    <div className="noDataDiv">
      <p className="noDataText">{msg}</p>
    </div>
  );
}

export default ShowNoDataFound;

import React from "react";
import Card from "../../../../../../Components/Common/Card/Card";
import AuctionTime from "../../../../../../Components/LpComponents/CardComponents/AuctionTime/AuctionTime";
import CardContent from "../../../../../../Components/LpComponents/CardComponents/CardContent/CardContent";
import CardFooter from "../../../../../../Components/LpComponents/CardComponents/CardFooter/CardFooter";
import CardHeader from "../../../../../../Components/LpComponents/CardComponents/CardHeader/CardHeader";
import FromAndTo from "../../../../../../Components/LpComponents/CardComponents/FromAndTo/FromAndTo";
import Id from "../../../../../../Components/LpComponents/CardComponents/Id/Id";
import LoadingDetails from "../../../../../../Components/LpComponents/CardComponents/LoadingDetails/LoadingDetails";
import LoadingPoint from "../../../../../../Components/LpComponents/CardComponents/LoadingPoint/LoadingPoint";
import Note from "../../../../../../Components/LpComponents/CardComponents/Note/Note";
import Pointofcontact from "../../../../../../Components/LpComponents/CardComponents/PointOfContact/PointOfContact";
import Typeofload from "../../../../../../Components/LpComponents/CardComponents/TypeOfLoad/TypeOfLoad";
import UnloadingPoint from "../../../../../../Components/LpComponents/CardComponents/UnloadingPoint/UnloadingPoint";
import VehicleDetails from "../../../../../../Components/LpComponents/CardComponents/VehicleDetails/MaterialDetails";
import Timer from "../../../../../../Components/Common/BiddingTimer/Timer";
import { NumberFormatter } from "../../../../../../CommonFunction/NumberFormatter/NumberFormatter";
import "./LiveCard.scss";

function LiveCard({
  _id,
  loadId,
  Load_Post_Type,
  From_City,
  To_City,
  Total_Distance,
  Consignor_Area,
  Consignor_Landmark,
  Consignor_City,
  Consignor_State,
  Consignor_Pincode,
  Consignee_Area,
  Consignee_Landmark,
  Consignee_City,
  Consignee_State,
  Consignee_Pincode,
  No_Of_Vehicels_Required,
  Vehicle_Type_String,
  Weight_Category_String,
  Payment_Type,
  Material_Insurence,
  Load_Live_Duration,
  Loading_By,
  // Loading_Scope,
  // Unloading_Scope,
  Over_Dimension_Cargo,
  Total_Weight,
  Exact_Content,
  Loading_Date,
  Loading_time,
  Material_Category,
  Expected_Price,
  Dangerous_Goods,
  Material_Dimension,
  Material_Dimension_In,
  Load_Type,
  Rate_Per,
  Target_Price,
  Notes,
  Auction_Date,
  Auction_Time,
  fielddata,
  Is_Live,
  Material_Image,
  Material_Image_2,
  Material_Image_3,
  createdAt,
}) {
  return (
    <>
      <Card>
        <div className="LpContainer">
          <CardHeader>
            <Id _id={_id} loadId={loadId} editOptions={true} fielddata={fielddata} createdAt={createdAt} />
            <FromAndTo fromCity={From_City} toCity={To_City} totalDistance={Total_Distance} />
            <Typeofload Load_Post_Type={Load_Post_Type} Rate_Per={Rate_Per} />
          </CardHeader>
          <CardContent>
            <div className="detailssection">
              <LoadingPoint
                area={Consignor_Area}
                landmark={Consignor_Landmark}
                city={Consignor_City}
                state={Consignor_State}
                pincode={Consignor_Pincode}
              />
              <UnloadingPoint
                area={Consignee_Area}
                landmark={Consignee_Landmark}
                city={Consignee_City}
                state={Consignee_State}
                pincode={Consignee_Pincode}
              />
            </div>
            <div className="detailssection">
              <LoadingDetails
                fromcity={From_City}
                tocity={To_City}
                paymenttype={Payment_Type}
                loadingdate={Loading_Date}
                loadingtime={Loading_time}
                loadingby={Loading_By}
                // loadingscope={Loading_Scope}
                // unloadingscope={Unloading_Scope}
              />
            </div>
            <div className="detailssection">
              <VehicleDetails
                totalweight={Total_Weight}
                matcat={Material_Category}
                exactcontent={Exact_Content}
                totaldistance={Total_Distance}
                materialinsurence={Material_Insurence}
                Material_Dimension={Material_Dimension}
                Dangerous_Goods={Dangerous_Goods}
                ODC={Over_Dimension_Cargo}
                noofvechile={No_Of_Vehicels_Required}
                vehicletype={Vehicle_Type_String}
                weightcategory={Weight_Category_String}
                Material_Dimension_In={Material_Dimension_In}
                Material_Image={Material_Image}
                Material_Image_2={Material_Image_2}
                Material_Image_3={Material_Image_3}
              />
            </div>

            <div className="detailssection quoteandtimer">
              {/* ***************** Render Timer depends on condition*********************** */}
              {Load_Type === "Auction Load" ? (
                <AuctionTime auctiondate={Auction_Date} auctiontime={Auction_Time} />
              ) : (
                ""
              )}
              {Expected_Price ? <Timer Auction_Time={Auction_Time} Auction_Date={Auction_Date} /> : ""}
              <p className="ceilingprice">
                {Expected_Price ? "Expected" : Load_Post_Type === "Open Load" ? "Target" : "Fixed"} Price:{" "}
                {NumberFormatter(Expected_Price || Target_Price)}/-
              </p>
            </div>
          </CardContent>
          <CardFooter>
            <div className="liveflexbox">
              <Note Notes={Notes} />
              <Pointofcontact
                createdAt={createdAt}
                Load_Live_Duration={Load_Live_Duration}
                Auction_Time={Auction_Time}
                Auction_Date={Auction_Date}
              />
            </div>
          </CardFooter>
        </div>
      </Card>
    </>
  );
}

export default LiveCard;

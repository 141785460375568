import React, { useEffect, useState } from "react";
import AccountStatementTable from "./AccountStatementComponents/AccountStatementTable/AccountStatementTable";
import { useQuery } from "@apollo/client";
import Cookies from "js-cookie";
import CustomDatePicker from "../../../../Components/Common/CustomDatePicker/CustomDatePicker";
import { useSelector } from "react-redux";
import ShowNoDataFound from "../../../../Components/Common/ShowNoDataFound/ShowNoDataFound";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { GET_TRANSSACTION } from "../../../../gql/getTranssaction";
import { GET_USER_ACCOUNT_BALANCE } from "../../../../gql/getUserAccountBalance";
import "./AccountStatement.scss";

function AccountStatement() {
  const [alldata, setalldata] = useState([]);
  const [startDate, setStartDate] = useState();
  const [reset, setReset] = useState(false);
  const [endDate, setEndDate] = useState();
  const [accBalance, setAccBalance] = useState(0);
  const [limit, setLimit] = useState(15);
  const [page, setPage] = useState(1);
  const walletBalanceID = Cookies.get("AccountBalanceId");
  const { fielddata } = useSelector((store) => store.accountStatementSearch);

  const filterFields = fielddata.SearchDescription;
  let accDataId = Cookies.get("accDataId");

  const { data } = useQuery(GET_TRANSSACTION, {
    variables: {
      AccountID: walletBalanceID,
      Search: filterFields,
      FromDate: startDate,
      To_Date: endDate,
    },
  });

  const handleClickGo = () => {
    setEndDate("");
    setStartDate("");
    setReset(true);
  };
  const { data: accountData } = useQuery(GET_USER_ACCOUNT_BALANCE, {
    variables: {
      accountTypeId: accDataId,
    },
  });
  useEffect(() => {
    if (data && data.GetVoucherEntriesRelatedToAccountID) {
      setalldata(data.GetVoucherEntriesRelatedToAccountID);
    }
    const totalPages = Math.ceil(data && data.GetVoucherEntriesRelatedToAccountID.length / limit);
    setPage(totalPages);
  }, [data]);

  useEffect(() => {
    if (accountData && accountData.GetAccountBalanceWithAccountType_ID) {
      setAccBalance(accountData.GetAccountBalanceWithAccountType_ID[0].Account_Balance);
    }
  }, [accountData]);

  const startIndex = (page - 1) * limit;
  const endIndex = page * limit;
  const currentPageData = alldata.slice(startIndex, endIndex);
  const totalPages = Math.ceil(alldata.length / limit);

  const dateForXlsx = (originalDate) => {
    if (!originalDate) {
      return;
    }
    const formattedDate = format(new Date(originalDate), "dd/MMM/yyyy");
    return formattedDate;
  };

  const downloadExcel = () => {
    const tableData = alldata.map((entry) => [
      dateForXlsx(entry.Date),
      entry.Narration,
      entry.Doc_No,
      entry.specificTransactions[0].Transaction_Type === "Debit"
        ? `+${entry.specificTransactions[0].Amount}`
        : `-${entry.specificTransactions[0].Amount}`,
      entry.closingBalance,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ["Date", "Description", "Reference No", "Amount(₹)", "Balance(₹)"],
      ...tableData,
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Account Statement");

    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(blob, "account_statement.xlsx");
  };

  return alldata.length === 0 ? (
    <ShowNoDataFound msg={"No Account Statement Available"} />
  ) : (
    <div className="accStatementDiv">
      <div className="accStatementHeaderDiv">
        <div></div>
        <div></div>
        <div></div>
        <div>
          <p className="accStatementHeader">Account Statement</p>
        </div>
        <div className="dateFilterdiv">
          <CustomDatePicker
            label={"Start Date"}
            name={"Start_Date"}
            id={"Start_Date"}
            placeholder={"Start_Date"}
            type={"date"}
            value={startDate}
            handleCustomDatePickerChange={(e) => {
              setStartDate(e.target.value);
            }}
            reset={reset}
          />{" "}
          To
          <CustomDatePicker
            label={"End Date"}
            name={"End_Date"}
            id={"End_Date"}
            placeholder={"End_Date"}
            type={"date"}
            value={endDate && endDate}
            handleCustomDatePickerChange={(e) => {
              setEndDate(e.target.value);
            }}
            // handleformikdata={handleformikdata}
            reset={reset}
          />
          <button className="gobtn" onClick={handleClickGo}>
            Reset
          </button>
        </div>
      </div>

      <div className="accDataTable">
        <AccountStatementTable accountStatementData={currentPageData} />
      </div>
      <div className="footerASDiv">
        <div>
          <p></p>
        </div>
        <div className="paginationdiv">
          <button
            className={page === 1 ? "acceptBtndisabled" : "acceptBtn"}
            onClick={() => {
              setPage(page - 1);
            }}
            disabled={page === 1}
          >
            {"<<"}
          </button>
          <button className="acceptBtn">{page}</button>
          <button
            className={page === totalPages ? "acceptBtndisabled" : "acceptBtn"}
            onClick={() => {
              setPage(page + 1);
            }}
            disabled={page === totalPages}
          >
            {">>"}
          </button>
        </div>
        <div>
          <button className="downloadXlsxIcon" onClick={downloadExcel}>
            Download Xlsx
          </button>
        </div>
      </div>
      <div className="accStatementFooterDiv">
        {/* <div className="footerDivOne">
          <p className="footerHeading">Pending Balance for POD:</p>
          <p className="footerContent">100000</p>
        </div> */}
        <div className="footerDivTwo">
          <p className="footerHeading">Rentention Amount as per Volume:</p>
          <p className="footerContent">0</p>
        </div>
        <div className="footerDivThree">
          <p className="footerHeading">Usable Balance:</p>
          <p className="footerContent">{accBalance}</p>
        </div>
      </div>
    </div>
  );
}

export default AccountStatement;

import box from "../../Assets/Icons/loadbox1.png";
import reports from "../../Assets/Icons/reports.png";
import truck from "../../Assets/Icons/truck.png";
export const TopNavbarDataLP = [
  {
    id: 1,
    name: "Loads",
    img: box,
    fields: [
      {
        name: "Live",
        path: "/live",
      },
      {
        name: "Resulted",
        path: "/resulted",
      },
      {
        name: "Archive",
        path: "/archive",
      },
    ],
  },
  {
    id: 2,
    name: "Booking",
    img: truck,
    fields: [
      {
        name: "Ongoing",
        path: "/ongoing",
      },
      {
        name: "Completed",
        path: "/completed",
      },
    ],
  },
  {
    id: 3,
    name: "Reports",
    img: reports,
    fields: [
      {
        name: "Account Statement",
        path: "/accountstatment",
      },
    ],
  },
];

export const TopNavbarDataSP = [
  {
    id: 1,
    name: "Loads",
    img: box,
    fields: [
      {
        name: "Open",
        path: "/open",
      },
      {
        name: "Resulted",
        path: "/resulted",
      },
      {
        name: "Awarded",
        path: "/awarded",
      },
      // {
      //   name: "Archive",
      //   path: "/archive",
      // },
    ],
  },
  {
    id: 2,
    name: "Booking",
    img: truck,
    fields: [
      {
        name: "Ongoing",
        path: "/ongoing",
      },
      {
        name: "Completed",
        path: "/completed",
      },
    ],
  },
  {
    id: 3,
    name: "Reports",
    img: reports,
    fields: [
      {
        name: "Account Statement",
        path: "/accountstatment",
      },
    ],
  },
];

import React, { useEffect, useState } from "react";
import OngoingBox from "./Ongoing components/OngoingBox/OngoingBox";
import TruckLoadingIndicator from "../../../../Components/Common/TruckLoadingIndicator/TruckLoadingIndicator";
import { useQuery } from "@apollo/client";
import ShowNoDataFound from "../../../../Components/Common/ShowNoDataFound/ShowNoDataFound";
import socket from "../../../../CommonFunction/Socket/socketClient";
import { CommittedVehiclesByUserId_withPopulate } from "../../../../gql/getOngoingData";
import "./Ongoing.scss";

function Ongoing() {
  const [acceptedCommittedVehiclesData, setAcceptedCommittedVehiclesData] = useState([]);

  const { loading, data, refetch } = useQuery(CommittedVehiclesByUserId_withPopulate, {
    variables: {
      isAccepted: true,
    },
  });
  useEffect(() => {
    if (data && data.CommittedVehiclesByUserId_withPopulate) {
      const sortedData = [...data.CommittedVehiclesByUserId_withPopulate].sort(
        (a, b) => new Date(b.Booking_Confirmed_Time) - new Date(a.Booking_Confirmed_Time)
      );
      const filteredData = sortedData.filter(
        (el) => el.Current_Stage !== "POD Collected From SP" && el.Current_Stage !== "POD Submitted To LP"
      );
      setAcceptedCommittedVehiclesData(filteredData);
    }
  }, [data]);

  useEffect(() => {
    socket.on("CurrentTask_Update", (id) => {
      refetch();
    });
  });

  return loading ? (
    <TruckLoadingIndicator />
  ) : acceptedCommittedVehiclesData.length === 0 ? (
    <ShowNoDataFound msg={"No Bookings Available"} />
  ) : (
    <div className="ongoingPage">
      {acceptedCommittedVehiclesData?.map((el) => (
        <OngoingBox key={el._id} data={el} />
      ))}
    </div>
  );
}

export default Ongoing;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FilterBox from "../FilterBox/FilterBox";
import WalletBalance from "../../Common/WalletBalance/WalletBalance";
import {
  TopNavbarDataLP,
  TopNavbarDataSP,
} from "../../../db/TopNavbar/topNavbar.config";
import TopNavbarSection from "./TopNavbarSection";
import Cookies from "js-cookie";
import "./TopNavbar.scss";

const TopNavbar = ({ filters }) => {
  const [topNavbarRoutes, setTopNavbarRoutes] = useState([]);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");
  const accType = accInfo.accType;

  useEffect(() => {
    setTopNavbarRoutes(
      accType === "Load Partner Account" ? TopNavbarDataLP : TopNavbarDataSP
    );
  }, [accType]);

  dispatch({ type: "set_sidebarOpen", payload: isOpen });

  return (
    <div className="TopNavbarparent">
      <div className="TopNavbar">
        <div className={filters && isOpen ? "filterBox" : "displayhidden"}>
          <FilterBox />
        </div>

        {topNavbarRoutes.map((e) => {
          return (
            <TopNavbarSection
              key={e.name}
              img={e.img}
              name={e.name}
              fieldsdata={e.fields}
            />
          );
        })}
      </div>
      <div>
        <WalletBalance />
      </div>
    </div>
  );
};

export default TopNavbar;

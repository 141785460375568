import { gql } from "@apollo/client";

export const CREATE_VOUCHER_ENTRIES = gql`
mutation CreateVoucherEntries($createInput: VoucherEntriesInput!, $transactions: [TransactionInput]!) {
  createVoucherEntries(createInput: $createInput, Transactions: $transactions) {
    id
    Unique_Id
    Entry_Type
    Account_Type
    Voucher_Type
    Doc_No
    Doc_Date
    Date
    Transactions
    Narration
    File
    createdAt
    updatedAt
  }
}
`;
import React from "react";
import { Link } from "react-router-dom";
import { RiUserSettingsFill } from "react-icons/ri";
import { IoMdLogOut } from "react-icons/io";
import Cookies from "js-cookie";
import "./Profile.scss";
import { useMutation } from "@apollo/client";
import { LOGOUT } from "../../../gql/logout";

function Profile({ setProfileOpen }) {
  const accInfo = JSON.parse(Cookies.get("accInfo") || "{}");

  const [logout] = useMutation(LOGOUT);

  function handleLogoutUser() {
    logout();
    Cookies.remove("token");
    Cookies.remove("accInfo");
    Cookies.remove("accDataId");
    Cookies.remove("userAccId");
    Cookies.remove("accData");
  }

  return (
    <div className="profileBox">
      <div className="detailsDiv">
        {accInfo.FullName ? (
          <p className="detailsText userName">{accInfo.FullName}</p>
        ) : (
          <p className="detailsText userName">
            {accInfo.firstName} {accInfo.lastName}
          </p>
        )}
        <p className="detailsText contactNo">{accInfo.mobileNumber}</p>
        <p className="detailsText company">{accInfo.email}</p>
      </div>
      <Link to="/profile-settings" className="mySettings">
        <div className="mySettingsDiv" onClick={() => setProfileOpen(false)}>
          <p className="mySettingsText">
            <RiUserSettingsFill className="userSettingIcon" />
            My Settings
          </p>
        </div>
      </Link>
      <Link to="/login" className="mySettings">
        <div className="logoutDiv " onClick={handleLogoutUser}>
          <p className="logoutText">
            <IoMdLogOut className="logoutIcon" />
            Logout
          </p>
        </div>
      </Link>
    </div>
  );
}

export default Profile;

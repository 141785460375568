import React, { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import Reactportal from "../../Reactportal/Reactportal";
import CnfModal from "../../../Common/AllModals/CnfModal/CnfModal";
import Addload from "../../../../Templets/Forms/AddLoad/Addload";
import "./Id.scss";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import socket from "../../../../CommonFunction/Socket/socketClient";
import { useDispatch } from "react-redux";
import { EDIT_LOAD } from "../../../../gql/editLoad";

const Id = ({ _id, loadId, bookingNo, bookingDate, editOptions, fielddata, createdAt, Is_Archive }) => {
  const [loadClosingModal, setLoadClosingModal] = useState(false);
  const timestamp = new Date(bookingDate);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = timestamp.toLocaleDateString("en-US", options);
  const [isNewLoad, setIsNewLoad] = useState(false);
  const [canEdit, setCanEdit] = useState(true);
  const [isEditLoadLoading, setIsEditLoadLoading] = useState(false);

  const [editLoad, { loading: editLoadLoading }] = useMutation(EDIT_LOAD);

  const dispatch = useDispatch();

  const date1 = new Date(createdAt);
  const date2 = new Date();

  const differenceInMilliseconds = Math.abs(date1 - date2);
  const oneHourInMilliseconds = 60 * 60 * 1000;
  const halfHourMilliseconds = 30 * 60 * 1000;

  useEffect(() => {
    if (differenceInMilliseconds < oneHourInMilliseconds) {
      setIsNewLoad(true);
    }
    if (differenceInMilliseconds > halfHourMilliseconds) {
      setCanEdit(false);
    }
  }, [date1, date2]);

  async function editLoadDetails() {
    await editLoad({
      variables: {
        id: _id,
        userInput: {
          Is_Live: false,
          Is_Archive: true,
        },
      },
    })
      .then((res) => {
        setLoadClosingModal(false);
        toast.success("Load closed");
        socket.emit("NewLoadAdded", res);
        dispatch({ type: "set_loadData", payload: true });
      })
      .catch((err) => toast.error(err));
  }

  useEffect(() => {
    if (editLoadLoading) {
      setIsEditLoadLoading(true);
    }
  }, [editLoadLoading]);

  return (
    <>
      <div className="bodyid ">
        {loadId && (
          <>
            {isNewLoad && !Is_Archive && (
              <div className="newLoadDiv">
                <p className="newLoadText">New</p>
              </div>
            )}

            <p className="idText">
              Load ID : {loadId}
              {editOptions && (
                <div className="actionDiv">
                  {canEdit && (
                    <div>
                      <Addload isEdit={true} fielddata={fielddata} TypeOfPortal={"Edit Load"} />
                    </div>
                  )}
                  <div>
                    <IoMdCloseCircle className="loadCloseBtn" onClick={() => setLoadClosingModal(true)} />
                  </div>
                  <Reactportal
                    open={loadClosingModal}
                    onClose={() => setLoadClosingModal(false)}
                    modalHeading="Confirmation"
                    modalClassName={"confirmationModal"}
                    contentClassName="confirmationModal-content"
                  >
                    <CnfModal
                      cnfMessage={`Do you want to close this load (${loadId})?`}
                      editCommittedVehiclesData={editLoadDetails}
                      onClose={() => setLoadClosingModal(false)}
                      loading={isEditLoadLoading}
                    />
                  </Reactportal>
                </div>
              )}
            </p>
          </>
        )}
        {bookingNo && bookingDate && (
          <p className="idText">
            Booking ID : {bookingNo} ({formattedDate})
          </p>
        )}
      </div>
    </>
  );
};

export default Id;

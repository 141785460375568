import { gql } from "@apollo/client";

export const BID_DETAILS_BY_LOAD_ID_QUERY = gql`
  query BidDetailsByLoadIdForUser($createInput: RequiredBidDetails) {
    BidDetailsByLoadIdForUser(createInput: $createInput) {
      _id
      Price
      Bid_Note
      Bid_History
    }
  }
`;


export const GET_ALL_BID_DETAILS = gql`
  query BidDetailsByLoadId($createInput: RequiredBidDetails) {
    BidDetailsByLoadId(createInput: $createInput) {
      _id
    }
  }
`;
import React, { useState, useEffect, useRef } from "react";
import "./AutoScrollingLine.scss";


const AutoScrollingLine = ({ text, speed }) => {
  const [position, setPosition] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const containerWidth = containerRef.current.offsetWidth;
    const textWidth = containerRef.current.firstChild.offsetWidth;

    let requestId;
    const animate = () => {
      setPosition((prevPosition) => {
        const newPosition = prevPosition - speed;
        if (newPosition < -textWidth) {
          return containerWidth;
        }
        return newPosition;
      });
      requestId = requestAnimationFrame(animate);
    };

    requestId = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(requestId);
  }, [speed]);

  return (
    <div className="autoScrollingLineDiv" ref={containerRef}>
      <div
        style={{
          transform: `translateX(${position}px)`,
        }}
        className="autoScrollingLineBox"
      >
        <p className="autoScrollingLineText">{text}</p>
      </div>
    </div>
  );
};

export default AutoScrollingLine;

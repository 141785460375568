const loadingCompleteFileUpload = [
  {
    id: Math.random() * 10,
    name: "Vehicle_Front_Img",
    lable: "Vehicle Front Image",
  },
  {
    id: Math.random() * 10,
    name: "Vehicle_Back_Img",
    lable: "Vehicle Back Image",
  },
  {
    id: Math.random() * 10,
    name: "Vehicle_Right_Img",
    lable: "Vehicle Right Image",
  },
  {
    id: Math.random() * 10,
    name: "Vehicle_Left_Img",
    lable: "Vehicle Left Image",
  },
  {
    id: Math.random() * 10,
    name: "Material_Img",
    lable: "Material Image",
  },
  {
    id: Math.random() * 10,
    name: "Invoice_Copy",
    lable: "Invoice Copy",
  },
];

const vehicleReachedAtUnloadingPointFileUpload = [
  {
    id: Math.random() * 10,
    name: "Entry_Gate_Img",
    lable: "Entry Gate Image",
  },
];
const unloadingCompleteFileUpload = [
  {
    id: Math.random() * 10,
    name: "POD_Front_Copy",
    lable: "POD Front Image",
  },
  {
    id: Math.random() * 10,
    name: "POD_Back_Copy",
    lable: "POD Back Image",
  },
  {
    id: Math.random() * 10,
    name: "Final_Invoice_Copy",
    lable: "Invoice Copy",
  },
];
const screenshotFileUpload = [
  {
    id: Math.random() * 10,
    name: "Payment_ScreenShot",
    lable: "Payment Screenshot*",
  },
];

export {
  loadingCompleteFileUpload,
  vehicleReachedAtUnloadingPointFileUpload,
  unloadingCompleteFileUpload,
  screenshotFileUpload,
};

import React, { useState } from "react";
import LoadDetails from "../../../../../../Components/Common/AllModals/LoadDetails/LoadDetails";
import Reactportal from "../../../../../../Components/LpComponents/Reactportal/Reactportal";
import "./DetailsBox.scss";
import GetVehicleNo from "../../../../../../Components/Common/GetVehicleNo/GetVehicleNo";

function DetailsBox({
  from,
  to,
  vehicleNo,
  driverName,
  driverNo,
  vehicalDetails,
  totalWeight,
  material,
  exactContent,
  loadId,
  LoadID,
  Notes,
  BidNote,
}) {
  const [loadDetailsModal, setLoadDetailsModal] = useState(false);
  // console.log(BidNote, "biddddd");
  return (
    <div className="detailsBoxEachBox">
      <div className="heading">
        <p className="headingText">Details</p>
      </div>
      <div className="belowbox">
        <p className="contentText">
          {from} to {to}
        </p>
        <p className="contentText">
          <GetVehicleNo vehicleNoId={vehicleNo} />
        </p>
        <p className="contentText">
          {driverName} - {driverNo}
        </p>
        <p className="contentText">{vehicalDetails}</p>
        <p className="contentText">
          {exactContent}({material}) - {totalWeight} MT
        </p>
        <p className="contentText loadIdLink">
          Load Id :{" "}
          <span className="loadIdNumber" onClick={() => setLoadDetailsModal(!loadDetailsModal)}>
            {loadId}
          </span>
        </p>
      </div>
      <Reactportal
        open={loadDetailsModal}
        modalClassName="loadDetailModal"
        contentClassName="loadDetailModal-content"
        modalHeading={`Load Id : ${loadId}`}
        onClose={() => setLoadDetailsModal(false)}
      >
        <LoadDetails loadId={LoadID} Notes={Notes} BidNote={BidNote} />
      </Reactportal>
    </div>
  );
}

export default DetailsBox;

import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import "./CustomSelect.scss";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import {
  BulkerCategories,
  ContainerCategories,
  DumperCategories,
  LVCContainerCategories,
  LVCOpenCategories,
  MiniPickupCategories,
  OpenBodyTruckCategories,
  TankerCategories,
  TipperCategories,
  TrailerDalaBodyCategories,
  TrailerFlatBedCategories,
} from "../../../db/vehicleCategory.config";
import { SEARCH_CITIES, SEARCH_STATE } from "../../../gql/getCitiState";

const InputOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input style={{ width: "20px" }} type="checkbox" checked={isSelected} />
      {children}
    </components.Option>
  );
};

function CustomSelect({
  label,
  handleCustomSelectChange,
  placeholder,
  options,
  isSearchable,
  isMulti,
  name,
  handleformikdata,
  value,
  value1,
  closeMenuOnSelect,
  err,
  touched,
  handleBlur,
  require,
}) {
  const [fieldcontent, setfieldcontent] = useState([]);
  const [serachWord, setSearchWord] = useState("");
  const [selectSearch, setSelectSearch] = useState([]);

  const [getCities, { data }] = useLazyQuery(SEARCH_CITIES);
  const [getSearchState, { data: dataState }] = useLazyQuery(SEARCH_STATE);

  const dispatch = useDispatch();
  const valueOfSelectTag = useSelector((store) => store.customSelectValue.customSelectValue);

  useEffect(() => {
    if (valueOfSelectTag === "Trailer Dala Body") {
      if (name === "Weight_Category_String") setSelectSearch(TrailerDalaBodyCategories);
    } else if (valueOfSelectTag === "Trailer Flat Bed") {
      if (name === "Weight_Category_String") setSelectSearch(TrailerFlatBedCategories);
    } else if (valueOfSelectTag === "Open Body Truck") {
      if (name === "Weight_Category_String") setSelectSearch(OpenBodyTruckCategories);
    } else if (valueOfSelectTag === "Container") {
      if (name === "Weight_Category_String") setSelectSearch(ContainerCategories);
    } else if (valueOfSelectTag === "Tanker") {
      if (name === "Weight_Category_String") setSelectSearch(TankerCategories);
    } else if (valueOfSelectTag === "Tipper") {
      if (name === "Weight_Category_String") setSelectSearch(TipperCategories);
    } else if (valueOfSelectTag === "Dumper") {
      if (name === "Weight_Category_String") setSelectSearch(DumperCategories);
    } else if (valueOfSelectTag === "Bulker") {
      if (name === "Weight_Category_String") setSelectSearch(BulkerCategories);
    } else if (valueOfSelectTag === "LVC Open") {
      if (name === "Weight_Category_String") setSelectSearch(LVCOpenCategories);
    } else if (valueOfSelectTag === "LVC Container") {
      if (name === "Weight_Category_String") setSelectSearch(LVCContainerCategories);
    } else if (valueOfSelectTag === "Mini/Pickup") {
      if (name === "Weight_Category_String") setSelectSearch(MiniPickupCategories);
    }
  }, [valueOfSelectTag]);

  useEffect(() => {
    if (name === "From_City" || name === "To_City" || name === "Origin_City" || name === "Destination_City") {
      getCities({ variables: { searchCitiesKeyword2: serachWord } });
      setTimeout(() => {
        setSelectSearch(data && data.searchCities);
      }, 500);
    }
  }, [serachWord]);

  useEffect(() => {
    // console.log(dataState);
    if (name === "Origin_State" || name === "Destination_State") {
      getSearchState({ variables: { searchStatesKeyword2: serachWord } });
      setTimeout(() => {
        setSelectSearch(dataState && dataState.searchStates);
      }, 500);
    }
  }, [serachWord]);

  let arr = [];
  function handleSelectChange(selectedOptions) {
    if (name === "Vehicle_Number") {
      // console.log("alllllll", selectedOptions);
      setfieldcontent(selectedOptions.value);
      formikdatamanage(selectedOptions.id);
    } else {
      setfieldcontent(selectedOptions.value);
      formikdatamanage(selectedOptions.value);
    }

    dispatch({
      type: "set_customSelectValue",
      payload: selectedOptions.value,
    });

    dispatch({
      type: "set_customSelectValueKey",
      payload: selectedOptions,
    });
  }

  const formikdatamanage = (option) => {
    // console.log({
    //   name: name,
    //   value: option,
    // });
    handleCustomSelectChange({
      target: {
        name: name,
        value: option,
      },
    });
    handleformikdata(option, name);
  };

  useEffect(() => {
    if (options && options.length < 31) {
      setSelectSearch(options);
    }
  }, [options]);

  if (name === "Weight_Category_String") {
    return (
      <div className="customSelectBox2">
        <p className="csLabel">
          {label}
          {require && " *"}
        </p>
        <Select
          className="react-select"
          components={{
            Option: InputOption,
          }}
          defaultValue={[]}
          options={selectSearch}
          isMulti={true}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          onBlur={(e) => {
            handleBlur(e);
          }}
          onChange={(options) => {
            if (Array.isArray(options)) {
              setfieldcontent(options.map((opt) => opt.value));
              formikdatamanage(options.map((opt) => opt.value));
            }
          }}
          onInputChange={(inputValue) => {
            if (inputValue.length >= 2) {
              setSearchWord(inputValue);
            }
          }}
        />
      </div>
    );
  }
  return (
    <div className="customSelectBox">
      <p className="csLabel">
        {label}
        {require && " *"}
      </p>
      <Select
        className="react-select"
        components={makeAnimated()}
        value={value ? { value: value, label: value } : fieldcontent}
        options={selectSearch}
        isSearchable={isSearchable}
        closeMenuOnSelect={closeMenuOnSelect}
        onChange={handleSelectChange}
        onInputChange={(inputValue) => {
          if (inputValue.length >= 2) {
            setSearchWord(inputValue);
          }
        }}
        onBlur={(e) => {
          handleBlur(e);
        }}
      />
    </div>
  );
}

export default CustomSelect;

const initial = {
  accBalance: 0,
};

export function AccountBalanceReducer(state = initial, { type, payload }) {
  switch (type) {
    case "set_accountBalance": {
      return {
        ...state,
        accBalance: payload,
      };
    }
    default: {
      return state;
    }
  }
}

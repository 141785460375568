import React, { useEffect, useState } from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import InputBox from "../../InputBox/InputBox";
import { useFormik } from "formik";
import { useMutation, useQuery } from "@apollo/client";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { NumberFormatter } from "../../../../CommonFunction/NumberFormatter/NumberFormatter";
import Spinner from "../../Spinner/Spinner";
import { GET_USER_ACCOUNT_BALANCE } from "../../../../gql/getUserAccountBalance";
import { PAYMENT_REQUEST } from "../../../../gql/createPaymentReq";
import "./WithdrawnBalanceModal.scss";

function WithdrawnBalanceModal({ onClose }) {
  const [accBalance, setAccBalance] = useState(0);
  const [accBalId, setAccBalId] = useState("");
  const [isPaymentRequestLoading, setIsPaymentRequestLoading] = useState(false);
  const [paymentRequest, { loading: paymentRequestLoading, data: paymentRequestData, error: paymentRequestError }] =
    useMutation(PAYMENT_REQUEST);

  useEffect(() => {
    setIsPaymentRequestLoading(paymentRequestLoading);
  }, [paymentRequestLoading]);

  const accDataId = Cookies.get("accDataId");

  const { data } = useQuery(GET_USER_ACCOUNT_BALANCE, {
    variables: {
      accountTypeId: accDataId,
    },
  });

  useEffect(() => {
    if (data && data.GetAccountBalanceWithAccountType_ID) {
      setAccBalId(data.GetAccountBalanceWithAccountType_ID[0]._id);
      setAccBalance(data.GetAccountBalanceWithAccountType_ID[0].Account_Balance);
    }
  }, [data]);

  useEffect(() => {
    if (paymentRequestData) {
      toast.success("Withdrawn balance requested Successfully");
      onClose();
    }
  }, [paymentRequestData]);

  useEffect(() => {
    if (paymentRequestError) {
      toast.error(paymentRequestError.message);
    }
  }, [paymentRequestError]);

  const initialValues = {
    Amount: "",
  };

  const validate = (values) => {
    let errors = {};

    if (!values.Amount) {
      errors.Amount = "Please Provide Amount";
    } else if (values.Amount > accBalance) {
      errors.Amount = "Amount must be lesser than withdrawable balance";
    } else if (+values.Amount === 0) {
      errors.Amount = "Amount cannot be zero";
    }

    return errors;
  };

  const onSubmit = (values) => {
    paymentRequest({
      variables: {
        createPaymentRequestInput: {
          Narration: "Withdrawn Balance",
          Amount: +values.Amount,
          Account_Balance_ID: accBalId,
          Reference_No: accBalId,
        },
      },
    });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="withdrawnBalanceModalDiv">
        <div className="withdrawnBalanceEachDiv">
          <p className="withdrawnBalanceHeading">Withdrawable Balance :</p>
          <p className="withdrawnBalanceContentText">{NumberFormatter(accBalance)} /-</p>
        </div>

        <div className="withdrawnBalanceEachDiv">
          <p className="withdrawnBalanceHeading">Amount (₹)* :</p>
          <InputBox
            className={"cnfQtyInput no-spinners"}
            formtype={"WithdrawBalance"}
            type={"number"}
            name={"Amount"}
            handleInputChange={formik.handleChange}
            autoComplete={"false"}
            err={formik.errors.Amount}
            value={formik.values.Amount}
            placeholder={"Enter amount"}
            touched={formik.touched.Amount}
          />
        </div>

        <PortalFooter>
          <Button
            className={isPaymentRequestLoading ? "acceptBtndisabled" : "acceptBtn"}
            text={isPaymentRequestLoading ? <Spinner /> : "Withdraw"}
            type="submit"
          />
          <Button className={"rejectBtn"} text="Cancel" type={"button"} handleClick={onClose} />
        </PortalFooter>
      </div>
    </form>
  );
}

export default WithdrawnBalanceModal;

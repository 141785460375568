import { useState, useEffect } from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import { HiOutlineRefresh } from "react-icons/hi";
import { ModalCard } from "../../../";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import Spinner from "../../Spinner/Spinner";
import InputBox from "../../InputBox/InputBox";
import "./UserProfileModal.scss";
import { toast } from "react-toastify";
import { Aadhaar_Download, Aadhar_With_Verify_Otp, Get_Aadhar_captcha } from "../../../../gql/getAadhar";
import { useTimer } from "../../../../hooks";

let counter = 1;

const AdhaarVerificationModal = ({ onClose, modalVisible }) => {
  // Get captcha
  const dispatch = useDispatch();
  const {
    fielddata: { Personal_Details, Verified_Details },
  } = useSelector((store) => store.editProfile);
  const [showOtpContainer, setShowOtpContainer] = useState(false);
  const [aadhaarDetails, setAadhaarDetails] = useState(null);
  const [captcha, setCaptcha] = useState("");
  const [otp, setOtp] = useState("");
  const { isTimerRunning, seconds, start: handleStartTimer, stop: handleStopTimer } = useTimer(60);

  // GET OTP
  const [fetchOTP, { loading: fetchOtpLoading }] = useMutation(Aadhar_With_Verify_Otp);
  const [fetchOtpError, setFetchOtpError] = useState("");

  // POST OTP and UID
  const [verifyOTP, { loading: verifyOtpLoading }] = useMutation(Aadhaar_Download);
  const [verifyOtpError, setVerifyOtpError] = useState("");

  const {
    data: captchaData,
    loading: captchaLoading,
    refetch: captchaRefetch,
  } = useQuery(Get_Aadhar_captcha, { notifyOnNetworkStatusChange: true });

  useEffect(() => {
    if (modalVisible) {
      captchaRefetch();
    }
  }, [modalVisible]);

  const handleResetModal = () => {
    toggleModal();
    setFetchOtpError("");
    setVerifyOtpError("");
    setCaptcha("");
    setOtp("");
    setShowOtpContainer(false);
  };

  const handleResetErrors = () => {
    setFetchOtpError("");
    setVerifyOtpError("");
  };

  const toggleModal = () => {
    setShowOtpContainer((prev) => !prev);
  };

  // Madhu Aadhaar
  //371742134116

  // Kiran Aadhaar
  // 348374426697

  const handleFetchOTP = () => {
    handleResetErrors();
    fetchOTP({
      variables: {
        userInput: {
          captcha,
          session_id: captchaData?.Get_Aadhar_captcha?.session_id,
          uid_no: Personal_Details.Aadhar_Number,
        },
      },
    })
      .then((response) => {
        if (response.errors) {
          setFetchOtpError(response.errors[0].message);
          return;
        }
        !isTimerRunning && handleStartTimer();
        handleResetErrors();
        setShowOtpContainer((prev) => true);
      })
      .catch((error) => {
        handleStopTimer();
        setFetchOtpError(error.message);
        toast.error(error.message);
      });
  };

  const handleVerifyOTP = () => {
    handleResetErrors();
    verifyOTP({
      variables: {
        aadhaarDownloadUserInput2: {
          otp: Number(otp),
          session_id: captchaData?.Get_Aadhar_captcha?.session_id,
          uid_no: Personal_Details.Aadhar_Number,
        },
      },
    })
      .then((response) => {
        if (response.errors) {
          setVerifyOtpError(response.errors[0].message);
          return;
        }

        setAadhaarDetails(response.data?.Aadhaar_Download);
        dispatch({
          type: "editProfileData",
          payload: {
            key: "Verified_Details",
            data: {
              name: "Is_Aadhar_Verified",
              value: true,
            },
          },
        });
        handleResetErrors();
      })
      .catch((error) => {
        setVerifyOtpError(error.message);
        toast.error(error.message);
      });
  };

  return (
    <>
      <div className="modal-body">
        <ModalCard>
          {Verified_Details?.Is_Aadhar_Verified ? (
            <>
              <h4>Aadhaar Verified</h4>
              <div className="verified-box">
                <img src={`data:image/png;base64,${aadhaarDetails?.aadhaar_photo}`} />
                <div className="aadhaar-details">
                  <p className="bold-label">
                    Name: <span>{aadhaarDetails?.aadhaar_name}</span>
                  </p>

                  <p className="bold-label">
                    DOB: <span>{aadhaarDetails?.aadhaar_dob}</span>
                  </p>

                  {!!aadhaarDetails?.aadhaar_mobile && (
                    <p className="bold-label">
                      Phone: <span>{aadhaarDetails?.aadhaar_mobile}</span>
                    </p>
                  )}

                  {!!aadhaarDetails?.aadhaar_email && (
                    <p className="bold-label">
                      Email: <span>{aadhaarDetails?.aadhaar_email}</span>
                    </p>
                  )}

                  <p className="bold-label">
                    Address: <span>{aadhaarDetails?.aadhaar_address}</span>
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <p className="title-text">Note: Do not close the popup until verification process is complete.</p>
              {showOtpContainer ? (
                <div className="input-box">
                  <InputBox
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    maxNo={6}
                    handleInputChange={(event) => setOtp(event.target.value)}
                    value={otp}
                  />
                  <div className="message-box">
                    {!!verifyOtpError && <p>{verifyOtpError}</p>}
                    <>
                      {isTimerRunning ? (
                        <p>Resend OTP in {seconds}</p>
                      ) : (
                        <span
                          onClick={() => {
                            handleFetchOTP();
                            handleStartTimer();
                          }}
                        >
                          Resend OTP
                        </span>
                      )}
                    </>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="input-box">
                    <InputBox
                      type="text"
                      name="captcha"
                      placeholder="Enter Captcha"
                      maxNo={5}
                      handleInputChange={(event) => setCaptcha(event.target.value)}
                      value={captcha}
                    />
                    <div className="message-box">
                      {!!fetchOtpError && <p className="error-text">{fetchOtpError}</p>}
                    </div>
                  </div>

                  <div className="captcha-box">
                    {captchaLoading ? (
                      <Spinner size={20} />
                    ) : (
                      <img
                        src={`data:image/png;base64,${captchaData?.Get_Aadhar_captcha?.image_captcha}`}
                        alt="captcha"
                      />
                    )}
                    <HiOutlineRefresh
                      size={20}
                      onClick={() => {
                        captchaRefetch().then((res) => {
                          // console.log(captcha);
                          setCaptcha("");
                        });
                        handleResetErrors();
                      }}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </ModalCard>
      </div>
      <PortalFooter>
        {!Verified_Details.Is_Aadhar_Verified &&
          (showOtpContainer ? (
            <Button
              text={verifyOtpLoading ? <Spinner /> : "Verify"}
              disabled={otp.length > 6}
              handleClick={handleVerifyOTP}
              className={otp.length < 6 ? "disabledBtnBank" : "acceptBtn"}
            />
          ) : (
            <Button
              text={fetchOtpLoading ? <Spinner /> : "Get OTP"}
              disabled={captcha.length < 5}
              handleClick={handleFetchOTP}
              className={captcha.length < 5 ? "disabledBtnBank" : "acceptBtn"}
            />
          ))}
        {Verified_Details.Is_Aadhar_Verified && <Button text="Ok" className="acceptBtn" handleClick={onClose} />}
      </PortalFooter>
    </>
  );
};

export default AdhaarVerificationModal;

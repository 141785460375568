import "./SupportSection.scss";

const SupportSection = () => {
  return (
    <>
      <div className="SupportSection_MainDiv">
        <img  height={"600px"} src="https://www.dat.com/wp-content/uploads/2023/03/large-truck-backed-into-the-loading-dock-with-freight-being-packed-into-it.jpg" alt="" />
        <div className="text_div">
          <p>Need Immediate Support Or Any Help?</p>
          <p>If you need logistical assistance, we are the ones to call</p>
        </div>
      </div>
    </>
  );
};

export default SupportSection;

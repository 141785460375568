import React from "react";
import CurrentTaskTable from "../../CurrentTaskTable/CurrentTaskTable";
import "./RecentClaims.scss";

function RecentClaimsModal({ CommittedVehicleId }) {
  return (
    <div className="recentClaimsModalDiv">
      <CurrentTaskTable
        CommittedVehicleId={CommittedVehicleId}
        className={"recentClaimCurrentTaskTable"}
      />
    </div>
  );
}

export default RecentClaimsModal;

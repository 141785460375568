import { gql } from "@apollo/client";

export const get_coustomer = gql`
  query AccountSearchFromCookies {
    AccountSearchFromCookies {
      _id
      UserId
      Unique_Id
      Aadhar_Number
      Orgnaization_Type
      Business_Address
      Business_Address_2
      Business_City
      Business_Name
      Business_Pan
      Business_Pincode
      Business_State
      Email
      GST_Number
      Land_Line
      Mobile_Number
      Pan_Number
      Proprietor_Name
      Residential_Address
      Residential_Address_2
      Residential_City
      Residential_Pincode
      Residential_State
      Aadhar_Card
      Pan_Card
      Proprietor_Photo
      GST_Certificate
      Shop_Act_Licence
      Office_Board_Photo
      MSME_certificate
      Self_Declaration_video
      Other_Doc_1
      Other_Doc_2
      Bank_Name
      Account_Type
      BankAccount_Type
      IFSC_Code
      Account_Number
      Cancelled_Cheque
      Visiting_Card
      Is_Lock
      Is_Verified
      Is_Mobile_Number_Verified
      Is_Email_Verified
      Is_Bank_Account_Lock
      Is_Pan_Verified
      Is_Aadhar_Verified
      Is_Bank_Verified
      Is_Document_Verified
      createdAt
      updatedAt
    }
  }
`;

import React from "react";
import "./Dropdownitem.scss";
const Dropdownitem = (props) => {
  // console.log('props', props)
  function handleclick() {
   // console.log("HJUGvfyughvfyusd");
  }

  return (
    <div className="item"    >
      {props.text}
    </div>
  );
};

export default Dropdownitem;

import React, { useEffect, useState } from "react";
import CreateButton from "../../../../../../Components/Common/CreateButton/CreateButton";
import Table from "../../../../../../Components/LpComponents/Table/Table";
import TBody from "../../../../../../Components/LpComponents/Table/TableComponents/TBody/TBody";
import TD from "../../../../../../Components/LpComponents/Table/TableComponents/TD/TD";
import TH from "../../../../../../Components/LpComponents/Table/TableComponents/TH/TH";
import THead from "../../../../../../Components/LpComponents/Table/TableComponents/THead/THead";
import TR from "../../../../../../Components/LpComponents/Table/TableComponents/TR/TR";
import { useQuery } from "@apollo/client";
import socket from "../../../../../../CommonFunction/Socket/socketClient";
import { BID_DETAILS_BY_LOAD_ID_QUERY_LP } from "../../../../../../gql/getBidDetailsForSP";
import ExpTimer from "../../../../../../Components/Common/ExpTimer/ExpTimer";

function ResultTable({ loadId, No_Of_Vehicels_Required, Load_Post_Type }) {
  const [resultedData, setResultedData] = useState([]);
  const [noOfVehiclesRequired, setNoOfVehiclesRequired] = useState(0);
  const [cnfMinusRej, setCnfMinusRej] = useState(0);
  const [finalNoOfVehicleRequired, setFinalNoOfVehicleRequired] = useState(0);

  const { data, refetch } = useQuery(BID_DETAILS_BY_LOAD_ID_QUERY_LP, {
    variables: {
      createInput: {
        id: loadId,
        sort: 1,
        sortBy: "Price",
        limit: 5,
      },
    },
  });

  useEffect(() => {
    socket.on("Refetch_EditConfirmVechileswithId", async (loadidForBackend) => {
      try {
        if (loadId === loadidForBackend) {
          await refetch();
        }
      } catch (error) {
        console.error("Refetch error:", error);
      }
    });
  });

  useEffect(() => {
    if (data) {
      setResultedData(data.BidDetailsForSp);
    }
  }, [data]);

  useEffect(() => {
    setNoOfVehiclesRequired(No_Of_Vehicels_Required);
  }, [No_Of_Vehicels_Required]);

  useEffect(() => {
    if (resultedData.length > 0) {
      const confirmedCount = resultedData.reduce(
        (count, obj) =>
          count +
          obj.Committed_Vehicles.filter((vehicle) => {
            if (vehicle !== null) {
              return vehicle.Is_Confirmed;
            }
          }).length,
        0
      );
      const rejectedCount = resultedData.reduce(
        (count, obj) =>
          count +
          obj.Committed_Vehicles.filter((vehicle) => {
            if (vehicle !== null) {
              return vehicle.Is_Rejected;
            }
          }).length,
        0
      );
      const cnfMinusRejCount = confirmedCount - rejectedCount;
      const finalCount = noOfVehiclesRequired - cnfMinusRejCount;
      setCnfMinusRej(cnfMinusRejCount);
      setFinalNoOfVehicleRequired(finalCount);
    }
  }, [resultedData, noOfVehiclesRequired]);

  const UpdateData = () => {
    socket.emit("update_Awarded", loadId);
    refetch();
  };

  function getBookingIds(Committed_Vehicles_Arr) {
    if (!Committed_Vehicles_Arr || Committed_Vehicles_Arr.length === 0) {
      return;
    }

    Committed_Vehicles_Arr = Committed_Vehicles_Arr.filter((e) => {
      if (e !== null) {
        return e;
      }
    });

    const uniqueIds = Committed_Vehicles_Arr.filter(
      ({ Is_Accepted }) => Is_Accepted === true && Is_Accepted !== null
    ).map(({ Unique_Id }) => Unique_Id);
    return uniqueIds.length > 0 ? uniqueIds.join(", ") : "-";
  }

  return (
    <>
      {resultedData.length > 0 ? (
        <Table>
          <THead>
            <TR>
              <TH>Rank</TH>
              <TH>Bid x Load = Freight</TH>
              <TH>Qty</TH>
              <TH>Bid Note</TH>
              <TH>Exp In</TH>
              <TH>Confirmed({cnfMinusRej})</TH>
              <TH>Booking Details</TH>
            </TR>
          </THead>
          <TBody>
            {resultedData?.map((el, i) => (
              <TR key={el._id}>
                {Load_Post_Type === "Fixed Load" ? <TD>L1</TD> : <TD>L{i + 1}</TD>}
                <TD>
                  ₹ {el.Price}/- x{" "}
                  {resultedData[i] &&
                    resultedData[i].Committed_Vehicles[0] &&
                    resultedData[i].Committed_Vehicles[0].Vehicle_Capacity}{" "}
                  MT ={" "}
                  <span className="freight_Amount">
                    ₹{" "}
                    {resultedData[i] &&
                      resultedData[i].Committed_Vehicles[0] &&
                      resultedData[i].Committed_Vehicles[0].Freight_Amount}
                    /-
                  </span>
                </TD>
                <TD>{el.No_Of_Vehicle}</TD>
                {el.Bid_Note ? <TD>{el.Bid_Note}</TD> : <TD>-</TD>}
                <TD>
                  <ExpTimer CreateTime={el.Committed_Vehicles[0]?.createdAt} />
                </TD>
                <TD className="btndivision">
                  <CreateButton
                    finalNoOfVehicleRequired={finalNoOfVehicleRequired}
                    noOfVehiclesRequired={noOfVehiclesRequired}
                    btnQty={el.No_Of_Vehicle}
                    el={el}
                    confirm={UpdateData}
                  />
                </TD>

                <TD>{getBookingIds(el.Committed_Vehicles)}</TD>
              </TR>
            ))}
          </TBody>
        </Table>
      ) : (
        <p>No Bid Placed</p>
      )}
    </>
  );
}

export default ResultTable;

import "./MiddlePartLandingPage.scss";
import truckbg from "../../../Assets/Images/truck-bg.jfif";

const MiddleImagePart = () => {
  return (
    <>
      <div boxSize="sm" className="ImageBox">
        <img src={truckbg} alt="Trucks" />
        <div className="ImageText">
          Safest, Reliable and Affordable <br />
          truck provider
        </div>
      </div>
    </>
  );
};

export default MiddleImagePart;

import { gql } from "@apollo/client";

export const GET_CLAIMS_BY_COMMITTEDVEHICLESID = gql`
  query ClaimsByCommittedVehiclesID($findByInputs: RequiredClaims) {
    ClaimsByCommittedVehiclesID(FindByInputs: $findByInputs) {
      Lp {
        id
        CommittedVehicleId
        Reject_Reason
        Shortage_Charges
        Damage_Charges
        Is_Accepted
        Is_Rejected
        Narration
      }
      Sp {
        id
        CommittedVehicleId
        Holding_Charges
        Hamali_Charges
        Extra_Running
        Other_Charges
        Other_Charges_Reason
        Challan_Charges
        Reject_Reason
        Is_Accepted
        Is_Rejected
      }
    }
  }
`;

import React, { useEffect, useState } from "react";
import Dropdownitem from "./Dropdownitem/Dropdownitem";
import "./InputDropdown.scss";

const InputDropdown = (props) => {
  const [selectedOption, setSelectedOption] = useState(-1);
  const [data, setData] = useState(props.dropdowndata);
  const [count, setCount] = useState(0);

  // console.log(props)
  useEffect(() => {
    if (props.searchText.length === 0) {
      setData(props.dropdowndata);
    } else if (Array.isArray(props.dropdowndata)) {
      // Check if props.dropdowndata is an array
      let filteredData = props.dropdowndata.filter((el) => {
        let searchValue = !isNaN(props.searchText)
          ? props.searchText.toLowerCase()
          : props.searchText;
        el = typeof el === "string" ? el.toLowerCase() : el;

        if (el && el.includes(searchValue)) {
          // Add a check for valid array elements
          return el;
        }
      });
      setData(filteredData);
    }
    setTimeout(() => {}, 3000);
  }, [props.dropdowndata]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (selectedOption === -1) {
        if (event.keyCode === 9 || event.keyCode === 13) {
          // If Tab key or Enter key is pressed
          setSelectedOption(0); // Select the first item
          props.inputcontent(data[0]); // Pass the first item to the parent
          if (event.keyCode === 13) {
            // If Enter key is pressed
            event.preventDefault(); // Prevent the default enter key action
          }
          props.handleDropDownClose();
          return;
        }
      }
      if (
        event.keyCode !== 38 &&
        event.keyCode !== 40 // If keys other than arrow up and down are pressed
      ) {
        props.handleDropDownClose();
        return;
      }

      event.preventDefault(); // Move this line here

      if (event.keyCode === 38) {
        // If Arrow up key is pressed
        if (selectedOption >= 0) {
          setSelectedOption((prevOption) => prevOption - 1);
          props.inputcontent(data[selectedOption - 1]);
        }
      } else if (event.keyCode === 40) {
        // If Arrow down key is pressed
        if (data.length !== selectedOption) {
          setSelectedOption((prevOption) => prevOption + 1);
          props.inputcontent(data[selectedOption + 1]);
        } else {
          setSelectedOption(0);
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedOption, data, props]);

 
  return (
    <div
      className={`Inputdropdown ${
        props.JurnalDropdown ? "JurnalInputdropdown" : ""
      }`}
    >
      {data?.map((e, i) => {
        return (
          <div
            key={i}
            className={i === selectedOption ? `forHover` : "selectDropdown"}
            onClick={() => {
              setSelectedOption(i);
              props.inputcontent(e);
              props.handleDropDownClose();
            }}
          >
            <Dropdownitem text={e.name || e} />
          </div>
        );
      })}
    </div>
  );
};

export default InputDropdown;

import { gql } from "@apollo/client";

export const GET_VEHICLES_DATA = gql`
  query GetVehicleManagementDetailsByUserId {
    GetVehicleManagementDetailsByUserId {
      Vehicle_No
      _id
    }
  }
`;

export const GET_VEHICLE_NO = gql`
query VehicleManagement($vehicleManagementId: ID!) {
  VehicleManagement(id: $vehicleManagementId) {
    Vehicle_No
  }
}
`;
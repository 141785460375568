import React, { useEffect, useState } from "react";
import EachStatusBox from "./StatusBoxComponents/EachStatusBox";
import Reactportal from "../../../../../../Components/LpComponents/Reactportal/Reactportal";
import ShowDocsModal from "../../../../../../Components/Common/AllModals/ShowDocsModal/ShowDocsModal";
import RecentClaimsModal from "../../../../../../Components/Common/AllModals/RecentClaimsModal/RecentClaimsModal";
import "./StatusBox.scss";

function StatusBox({
  CommittedVehicleId,
  Booking_Confirmed,
  Vehicle_Reached_At_Loading_Point,
  Loading_Completed,
  Advance_Processed,
  Vehicle_Reach_At_Unloading_Point,
  Unloading_Completed,
  Clear_Delevery_Confirmation,
  Vehicle_Front_Img,
  Vehicle_Back_Img,
  Vehicle_Right_Img,
  Vehicle_Left_Img,
  Material_Img,
  Entry_Gate_Img,
  POD_Front_Copy,
  POD_Back_Copy,
  Invoice_Copy,
  POD_Collected_From_SP,
  Final_Invoice_Copy,
}) {
  const [StatusBoxArr, setStatusBoxArr] = useState([]);
  const [openLoadingCompletedDocs, setOpenLoadingCompletedDocs] = useState(false);
  const [openVehicleReachedAtUnloadingPointDocs, setOpenVehicleReachedAtUnloadingPointDocs] = useState(false);
  const [openUnloadingCompletedDocs, setUnloadingCompletedDocs] = useState(false);
  const [openClaimViewBox, setOpenClaimViewBox] = useState(false);

  useEffect(() => {
    setStatusBoxArr([
      { status: Booking_Confirmed, statusName: "Booking Confirmed" },
      {
        status: Vehicle_Reached_At_Loading_Point,
        statusName: "Vehicle Reached At Loading Point",
      },
      {
        status: Loading_Completed,
        statusName: "Loading Completed",
        showLoadingCompletedDoc: true,
      },
      { status: Advance_Processed, statusName: "Advance Processed" },
      {
        status: Vehicle_Reach_At_Unloading_Point,
        statusName: "Vehicle Reach At Unloading Point",
        showVehicleReachedAtUnloadingPointDoc: true,
      },
      {
        status: Unloading_Completed,
        statusName: "Unloading Completed",
        showUnloadingCompletedDoc: true,
      },
      {
        status: Clear_Delevery_Confirmation,
        statusName: "Clear Delivery Confirmation",
        showClaim: true,
      },
      { status: POD_Collected_From_SP, statusName: "POD Collected from SP" },
    ]);
  }, [
    Booking_Confirmed,
    Vehicle_Reached_At_Loading_Point,
    Loading_Completed,
    Advance_Processed,
    Vehicle_Reach_At_Unloading_Point,
    Unloading_Completed,
    Clear_Delevery_Confirmation,
    POD_Collected_From_SP,
  ]);
  return (
    <div className="statusBoxEachBox">
      <div className="heading">
        <p className="headingText">Status</p>
      </div>
      <div className="belowbox">
        {StatusBoxArr?.map((el) => (
          <div key={el.statusName} className="eachStatusBoxDiv">
            <EachStatusBox
              status={el.status}
              statusName={el.statusName}
              Loading_Completed={Loading_Completed}
              Clear_Delevery_Confirmation={Clear_Delevery_Confirmation}
              Vehicle_Reach_At_Unloading_Point={Vehicle_Reach_At_Unloading_Point}
              Unloading_Completed={Unloading_Completed}
              showLoadingCompletedDoc={el.showLoadingCompletedDoc}
              showVehicleReachedAtUnloadingPointDoc={el.showVehicleReachedAtUnloadingPointDoc}
              showUnloadingCompletedDoc={el.showUnloadingCompletedDoc}
              showClaim={el.showClaim}
              setOpenLoadingCompletedDocs={setOpenLoadingCompletedDocs}
              setOpenVehicleReachedAtUnloadingPointDocs={setOpenVehicleReachedAtUnloadingPointDocs}
              setOpenClaimViewBox={setOpenClaimViewBox}
              setUnloadingCompletedDocs={setUnloadingCompletedDocs}
            />
          </div>
        ))}
        <Reactportal
          open={openLoadingCompletedDocs}
          modalHeading={"Documents"}
          modalClassName={"loadDetailModal"}
          contentClassName={"loadDetailModal-content"}
          onClose={() => setOpenLoadingCompletedDocs(false)}
        >
          <ShowDocsModal
            documentsArr={
              Vehicle_Front_Img || Vehicle_Back_Img || Vehicle_Left_Img || Vehicle_Right_Img || Material_Img
                ? [
                    {
                      name: "Vehicle Front Image",
                      url: Vehicle_Front_Img,
                    },
                    {
                      name: "Vehicle Back Image",
                      url: Vehicle_Back_Img,
                    },
                    {
                      name: "Vehicle Left Image",
                      url: Vehicle_Left_Img,
                    },
                    {
                      name: "Vehicle Right Image",
                      url: Vehicle_Right_Img,
                    },
                    {
                      name: "Material Image",
                      url: Material_Img,
                    },
                    {
                      name: "Invoice Copy",
                      url: Invoice_Copy,
                    },
                  ]
                : []
            }
          />
        </Reactportal>
        <Reactportal
          open={openVehicleReachedAtUnloadingPointDocs}
          modalHeading={"Documents"}
          modalClassName={"loadDetailModal"}
          contentClassName={"loadDetailModal-content"}
          onClose={() => setOpenVehicleReachedAtUnloadingPointDocs(false)}
        >
          <ShowDocsModal documentsArr={Entry_Gate_Img ? [{ name: "Entry Gate Image", url: Entry_Gate_Img }] : []} />
        </Reactportal>
        <Reactportal
          open={openUnloadingCompletedDocs}
          modalHeading={"Documents"}
          modalClassName={"loadDetailModal"}
          contentClassName={"loadDetailModal-content"}
          onClose={() => setUnloadingCompletedDocs(false)}
        >
          <ShowDocsModal
            documentsArr={
              POD_Front_Copy || POD_Back_Copy || Final_Invoice_Copy
                ? [
                    {
                      name: "POD Front Copy",
                      url: POD_Front_Copy,
                    },
                    {
                      name: "POD Back Copy",
                      url: POD_Back_Copy,
                    },
                    { name: "Invoice Copy", url: Final_Invoice_Copy },
                  ]
                : []
            }
          />
        </Reactportal>
        <Reactportal
          open={openClaimViewBox}
          modalHeading={"Recent Claims"}
          modalClassName={"loadDetailModal"}
          contentClassName={"loadDetailModal-content"}
          onClose={() => setOpenClaimViewBox(false)}
        >
          <RecentClaimsModal CommittedVehicleId={CommittedVehicleId} />
        </Reactportal>
      </div>
    </div>
  );
}

export default StatusBox;

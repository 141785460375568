import React, { useEffect, useState, useRef } from "react";
import { TiRefresh } from "react-icons/ti";
import InputBox from "../InputBox/InputBox";
import { filterBoxData } from "../../../db/filterBox.config";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { formikinitialvalues } from "../../../CommonFunction/common";
import { useFormik } from "formik";
import Box from "../Box/Box";
import "./FilterBox.scss";

function FilterBox() {
  const [fields, setfields] = useState(filterBoxData);
  const [alldata, setalldata] = useState({});
  const [reset, setReset] = useState(false);
  const dispatch = useDispatch();
  const { fielddata } = useSelector((store) => store.filterData);

  const formRef = useRef(null);

  const resetFilter = () => {
    setalldata({});
    setReset(true);
    formik.resetForm();
  };

  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };

  useEffect(() => {}, [fielddata]);

  const validate = (values) => {
    // console.log(values);
    setalldata(values);
    setReset(false);
  };

  useEffect(() => {
    dispatch({
      type: "addfields",
      payload: { data: alldata, key: "Filters" },
    });
  }, [alldata]);

  const formik = useFormik({
    initialValues: formikinitialvalues(fields),
    validate,
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  return (
    <Box>
      <div className="filterContent">
      
        <form
          ref={formRef}
          onSubmit={(event) => {
            formik.handleSubmit(event);
            resetFilter();
          }}
          autoComplete="off"
        >
          <div className="filterHeaderDiv boxcenter">
            <p className="filterText">Filter</p>
            <p className="reset" onClick={resetFilter}>
              reset
              <TiRefresh className="resetIcon"  />
            </p>
          </div>
          <div className="filtersInputDiv">
         
            {fields?.map((el) => (
              <div key={el.id} className="inputBoxDiv">
                {el.type !== "date" ? (
                  <InputBox
                    className={"no-spinners"}
                    label={el.label}
                    name={el.name}
                    id={el.name}
                    placeholder={el.name}
                    type={el.type}
                    modalform={true}
                    Iconinside={true}
                    value={formik.values[el.name]}
                    dropdowndata={el.dropdowncontent}
                    isSearchable={el.isSearchable}
                    searchType={el.searchType}
                    handleInputChange={formik.handleChange}
                    handleformikdata={handleformikdata}
                    reset={reset}
                    handleBlur={formik.handleBlur}
                    autoComplete={el.autoComplete}
                    autoCapital={el.autoCapital}
                  />
                ) : (
                  ""
                )}
                {el.type === "date" ? (
                  <CustomDatePicker
                    label={el.label}
                    name={el.name}
                    id={el.name}
                    placeholder={el.name}
                    type={el.type}
                    value={formik.values[el.name]}
                    handleCustomDatePickerChange={formik.handleChange}
                    handleformikdata={handleformikdata}
                    reset={reset}
                  />
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        </form>
      </div>
    </Box>
  );
}

export default FilterBox;

const initial = {
  dropdownOpen: "",
};

export function InputDropdownState(state = initial, { type, payload }) {
  switch (type) {
    case "set_dropdownOpen": {
      return {
        ...state,
        dropdownOpen: payload,
      };
    }
    default: {
      return state;
    }
  }
}

import React from "react";
import emt_logo from "../../../Assets/Logo/logoemt.png";
import Button from "../Button/Button";
// import { useNavigate } from "react-router-dom";

function SelectAccType({ loginType, setLoginType, setLogin }) {
  // const navigate = useNavigate();

  // function handleNavigateToSignup() {
  //   navigate("/signup");
  // }

  return (
    <div className="bgImgDivLogin">
      <div className="loginpage">
        <div className="loginbox">
          <div className="boxcenter">
            <img className="emtlogo" src={emt_logo} alt="emtlogo" />
          </div>
          <div className="TypesOfLoignDiv">
            <h4 className="radioLableLogin">Select Account Type</h4>
            <div
              className="descDivlogin"
              onClick={() => {
                setLoginType("Load Partner Account");
              }}
            >
              <div className="radioBtnLogin">
                <h4 className="radioLableLogin">Load Partner Account</h4>
                <input
                  className="radioInputLogin"
                  type="radio"
                  value={loginType}
                  name="loadType"
                  checked={loginType === "Load Partner Account"}
                />
              </div>
            </div>
            <div
              className="descDivlogin"
              onClick={() => {
                setLoginType("Supply Partner Account");
              }}
            >
              <div className="radioBtnLogin">
                <h4 className="radioLableLogin">Supply Partner Account</h4>
                <input
                  className="radioInputLogin"
                  type="radio"
                  value={loginType}
                  name="loadType"
                  checked={loginType === "Supply Partner Account"}
                />
              </div>
            </div>
            <Button
              type="submit"
              className={!loginType ? "btn1Disalbe" : "btn1"}
              handleClick={() => {
                setLogin(true);
              }}
              disabled={!loginType}
              text={"Proceed to Next"}
            />
            {/* <Button className="btn2" type={"button"} handleClick={handleNavigateToSignup} text="Go to Signup" /> */}
          </div>
          <p className="helplineNoLoginText">
            Helpline No -<br></br>
            7066664747 / 7066669090
          </p>
        </div>
      </div>
    </div>
  );
}

export default SelectAccType;

import React from "react";
import { format } from "date-fns";

function DateFormatForAccountStatement({ originalDate }) {
  if (!originalDate) {
    return <></>;
  }

  const formattedDate1 = originalDate.toString();

  if (!formattedDate1) {
    return;
  }

  const formattedDate = format(new Date(formattedDate1), "dd/MMM/yyyy");

  return <>{formattedDate}</>;
}

export default DateFormatForAccountStatement;

import React, { useEffect, useState } from "react";
import "./ExpTimer.scss";

const ExpTimer = ({ CreateTime }) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [inDanger, setInDanger] = useState(false);

  useEffect(() => {
    const timeReceived = new Date(CreateTime);
    const endTime = new Date(timeReceived.getTime() + 3600000); // Adding 1 hour in milliseconds

    const calculateTimeRemaining = () => {
      const now = new Date();
      const remaining = Math.max(0, endTime.getTime() - now.getTime());

      setTimeRemaining(remaining);

      if (remaining < 300000) {
        setInDanger(true);
      }
    };

    calculateTimeRemaining();
    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [CreateTime]);

  const formatTime = (milliseconds) => {
    if (!milliseconds) {
      return <p className="expTime">Time's up!</p>;
    }

    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return (
      <p className="expRunTime">
        {minutes.toString().padStart(2, "0")}m : {seconds.toString().padStart(2, "0")}s
      </p>
    );
  };

  return <>{formatTime(timeRemaining)}</>;
};

export default ExpTimer;

import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TruckLoadingIndicator from "../../../../Components/Common/TruckLoadingIndicator/TruckLoadingIndicator";
import "./Resulted.scss";
import ResultedCard from "./ResultedComponents/ResultedCard/ResultedCard";
import ShowNoDataFound from "../../../../Components/Common/ShowNoDataFound/ShowNoDataFound";
import { useDispatch, useSelector } from "react-redux";
import { load_by_search_resulted } from "../../../../gql/getResultedLoad";

function Resulted() {
  const [loadsArr, setLoadsArr] = useState([]);
  const { fielddata } = useSelector((store) => store.filterData);
  const filterFields = fielddata.Filters;

  const dispatch = useDispatch();

  const isLoadData = useSelector((store) => store.loadData.loadData);

  const { loading, error, data, refetch } = useQuery(load_by_search_resulted, {
    variables: {
      userInput: filterFields,
    },
  });
  useEffect(() => {
    if (data && data.getAllLoadsWithSearchForSp) {
      const getData = [...data.getAllLoadsWithSearchForSp].reverse();
      const archiveData = getData.filter((e) => e.Is_Archive === false);
      const filteredData = archiveData
        .filter((e) => e.Is_Live === false || e.Load_Post_Type === "Open Load" || e.Load_Post_Type === "Fixed Load")
        .filter((e) => e.BidDetails.length > 0);
      setLoadsArr(filteredData);
    }
  }, [data]);

  useEffect(() => {
    if (isLoadData) {
      refetch();
      dispatch({ type: "set_loadData", payload: false });
    }
  }, [isLoadData, refetch]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  return (
    <>
      {loading ? (
        <TruckLoadingIndicator />
      ) : loadsArr.length === 0 ? (
        <ShowNoDataFound msg={"No Loads Available"} />
      ) : (
        <div>
          {loadsArr?.map((e) => {
            return (
              <ResultedCard
                key={e._id}
                _id={e._id}
                loadId={e.Unique_Id}
                Load_Post_Type={e.Load_Post_Type}
                From_City={e.From_City}
                To_City={e.To_City}
                Total_Distance={e.Total_Distance}
                Consignor_Area={e.Loading_Area}
                Consignor_Landmark={e.Loading_Landmark}
                Consignor_City={e.Loading_City}
                Consignor_State={e.Loading_State}
                Consignor_Pincode={e.Loading_Pincode}
                Consignee_Area={e.Unloading_Area}
                Consignee_Landmark={e.Unloading_Landmark}
                Consignee_City={e.Unloading_City}
                Consignee_State={e.Unloading_State}
                Consignee_Pincode={e.Unloading_Pincode}
                No_Of_Vehicels_Required={e.No_Of_Vehicels_Required}
                Vehicle_Type_String={e.Vehicle_Type_String}
                Weight_Category_String={e.Weight_Category_String}
                Payment_Type={e.Payment_Type}
                Material_Insurence={e.Material_Insurence}
                Loading_By={e.Loading_By}
                // Loading_Scope={e.Loading_Scope}
                // Unloading_Scope={e.Unloading_Scope}
                Over_Dimension_Cargo={e.Over_Dimension_Cargo}
                Total_Weight={e.Total_Weight}
                Exact_Content={e.Exact_Content}
                Loading_Date={e.Loading_Date}
                Loading_time={e.Loading_Time}
                Material_Category={e.Material_Category}
                Expected_Price={e.Expected_Price}
                Auction_Date={e.Auction_Date}
                Auction_Time={e.Auction_Time}
                Load_Live_Duration={e.Load_Live_Duration}
                Dangerous_Goods={e.Dangerous_Goods}
                Material_Dimension={e.Material_Dimension}
                Material_Dimension_In={e.Material_Dimension_In}
                Load_Type={e.Load_Type}
                Rate_Per={e.Rate_per}
                Notes={e.Notes}
                Target_Price={e.Target_Price}
                Material_Image={e.Material_Image}
                Material_Image_2={e.Material_Image_2}
                Material_Image_3={e.Material_Image_3}
                createdAt={e.createdAt}
              />
            );
          })}
        </div>
      )}
    </>
  );
}

export default Resulted;

import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./GetRank.scss";
import socket from "../../../CommonFunction/Socket/socketClient";
import { GET_ALL_BID_DETAILS } from "../../../gql/getBidDetails";

function GetRank({ id, loadId }) {
  const { data, refetch } = useQuery(GET_ALL_BID_DETAILS, {
    variables: {
      createInput: {
        id: loadId,
        sort: 1,
        sortBy: "Price",
      },
    },
  });

  const [bidsArr, setBidsArr] = useState([]);
  const isLoadData = useSelector((store) => store.loadData.loadData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setBidsArr(data.BidDetailsByLoadId);
    }
  }, [data]);

  const result = bidsArr.findIndex((doc) => doc._id === id);

  useEffect(() => {
    if (isLoadData) {
      refetch();
      dispatch({ type: "set_loadData", payload: false });
    }
  }, [isLoadData, refetch]);

  useEffect(() => {
    socket.on("Update_Bid_Sp", () => {
      refetch();
      dispatch({ type: "set_loadData", payload: true });
    });
  });
  return <p className={result < 6 ? "greenRankText" : "redRankText"}>{result > -1 ? `L${result + 1}` : ""}</p>;
}

export default GetRank;

import React, { useEffect, useState } from "react";
import LiveCard from "./LiveLoadComponents/LiveCard/LiveCard";
import TruckLoadingIndicator from "../../../../Components/Common/TruckLoadingIndicator/TruckLoadingIndicator";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ShowNoDataFound from "../../../../Components/Common/ShowNoDataFound/ShowNoDataFound";
import "./Live.scss";
import socket from "../../../../CommonFunction/Socket/socketClient";
import { load_by_search_live_lp } from "../../../../gql/getLiveLoadsLP";

function Live() {
  const [loadsArr, setLoadsArr] = useState([]);
  const dispatch = useDispatch();
  const isLoadData = useSelector((store) => store.loadData.loadData);

  const { fielddata } = useSelector((store) => store.filterData);

  const filterFields = fielddata.Filters;

  const { loading, error, data, refetch } = useQuery(load_by_search_live_lp, {
    variables: {
      userInput: { ...filterFields, Is_Live: true },
    },
  });

  useEffect(() => {
    if (isLoadData) {
      refetch();
      dispatch({ type: "set_loadData", payload: false });
    }
  }, [isLoadData, refetch]);

  useEffect(() => {
    if (data && data.getLoadsbyUserIdWithSearchFilterForLp) {
      const getData = [...data.getLoadsbyUserIdWithSearchFilterForLp].reverse();
      setLoadsArr(getData);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    socket.on("UpdateLoadStatus", function (updatedLoads) {
      // console.log("Loads were updated:", updatedLoads);
      refetch();
      dispatch({ type: "set_loadData", payload: true });
    });
  }, []);

  if (loadsArr.length === 0) {
    return <ShowNoDataFound msg={"No Live Loads Available"} />;
  }
  return (
    <>
      {loading ? (
        <TruckLoadingIndicator />
      ) : loadsArr.length === 0 ? (
        <ShowNoDataFound msg={"No Live Loads Available"} />
      ) : (
        <div>
          {loadsArr?.map((e) => {
            return (
              <div key={e._id}>
                <LiveCard
                  fielddata={e}
                  key={e.id}
                  _id={e._id}
                  Load_Post_Type={e.Load_Post_Type}
                  loadId={e.Unique_Id}
                  From_City={e.From_City}
                  To_City={e.To_City}
                  Total_Distance={e.Total_Distance}
                  Consignor_Area={e.Loading_Area}
                  Consignor_Landmark={e.Loading_Landmark}
                  Consignor_City={e.Loading_City}
                  Consignor_State={e.Loading_State}
                  Consignor_Pincode={e.Loading_Pincode}
                  Consignee_Area={e.Unloading_Area}
                  Consignee_Landmark={e.Unloading_Landmark}
                  Consignee_City={e.Unloading_City}
                  Consignee_State={e.Unloading_State}
                  Consignee_Pincode={e.Unloading_Pincode}
                  No_Of_Vehicels_Required={e.No_Of_Vehicels_Required}
                  Vehicle_Type_String={e.Vehicle_Type_String}
                  Weight_Category_String={e.Weight_Category_String}
                  Payment_Type={e.Payment_Type}
                  Material_Insurence={e.Material_Insurence}
                  Loading_By={e.Loading_By}
                  // Loading_Scope={e.Loading_Scope}
                  // Unloading_Scope={e.Unloading_Scope}
                  Over_Dimension_Cargo={e.Over_Dimension_Cargo}
                  Total_Weight={e.Total_Weight}
                  Exact_Content={e.Exact_Content}
                  Load_Live_Duration={e.Load_Live_Duration}
                  Loading_Date={e.Loading_Date}
                  Loading_time={e.Loading_Time}
                  Material_Category={e.Material_Category}
                  Expected_Price={e.Expected_Price}
                  Target_Price={e.Target_Price}
                  Auction_Date={e.Auction_Date}
                  Auction_Time={e.Auction_Time}
                  Dangerous_Goods={e.Dangerous_Goods}
                  Material_Dimension={e.Material_Dimension}
                  Material_Dimension_In={e.Material_Dimension_In}
                  Load_Type={e.Load_Type}
                  Rate_Per={e.Rate_per}
                  Notes={e.Notes}
                  Material_Image={e.Material_Image}
                  Material_Image_2={e.Material_Image_2}
                  Material_Image_1={e.Material_Image_3}
                  Is_Live={e.Is_Live}
                  createdAt={e.createdAt}
                />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default Live;

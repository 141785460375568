const data = {
  fielddata: {},
  selectedRow: [],
  selectedRows: [],
  errors: {},
  refresh: false,
  errorsedit: {},
  LoadTypes: "",
};
export function AddLoadReducer(state = data, { type, payload }) {
  // console.log(state);
  switch (type) {
    case "addload": {
      return {
        ...state,
        fielddata: {
          ...state.fielddata,
          [payload.key]: payload.data,
        },
      };
    }
    case "selectedRow": {
      // console.log(payload);
      let array = [];
      if (payload[0]) {
        array.push(payload[0].original);
      }
      return { ...state, selectedRow: array };
    }
    case "selectedRow": {
      const array = [];
      for (var a = 0; a < payload.length; a++) {
        array.push(payload[a].original);
      }
      return { ...state, selectedRows: array };
    }
    case "ClearAddData": {
      state = {
        fielddata: {},
        selectedRows: [],
      };
      return state;
    }
    case "Refetch": {
      // console.log(payload);
      state = {
        ...state,
        refresh: payload,
      };
      // console.log(state);
      // console.log("in refresh.............................................",payload)
      return state;
    }
    case "errordata": {
      return {
        ...state,
        errors: {
          ...state.errors,
          [payload.key]: payload.data,
        },
      };
    }
    case "errordataedit": {
      return {
        ...state,
        errorsedit: {
          ...state.errorsedit,
          [payload.key]: payload.data,
        },
      };
    }
    case "LoadType": {
      // console.log(payload);
      state = {
        ...state,
        LoadTypes: payload,
      };
      return state;
    }
    default: {
      return state;
    }
  }
}

import React from "react";
import "./LoadingPoint.scss";
import pickup from "../../../../Assets/Icons/pickup.png";

const LoadingPoint = ({ area, landmark, city, state, pincode }) => {
  return (
    <div className="loadingbody ">
      <div className="LPHeadingDiv">
        <img src={pickup} alt="pickup" />
        <p className="LPHeading">Laoding Point</p>
      </div>
      <p className="LPContent">
        {landmark}, {area},<br></br>
        {city}, {state}, {pincode}
      </p>
    </div>
  );
};

export default LoadingPoint;

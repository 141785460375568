import React, { useState, useEffect } from "react";
import PortalFooter from "../../../LpComponents/Reactportal/PortalFooter";
import Button from "../../Button/Button";
import InputBox from "../../InputBox/InputBox";
import { useFormik } from "formik";
import { formikinitialvalues } from "../../../../CommonFunction/common";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { CREATE_SUB_USER_MUTATION } from "../../../../gql/createSubUser";
import Cookies from "js-cookie";
import "./AddUserModal.scss";
import SpinnerIndicator from "../../SpinnerIndicator/SpinnerIndicator";

function AddUserModal({ onClose, NotificationData }) {
  const [fieldsData, setFieldsData] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [alldata, setAllData] = useState({});
  const UserInfo = JSON.parse(Cookies.get("accInfo"));
  const [CreateSubUser, { loading, data, error }] = useMutation(CREATE_SUB_USER_MUTATION);
  const dispatch = useDispatch();

  useEffect(() => {
    const allFields = [];
    for (let i = 0; i < NotificationData.length; i++) {
      const fields = NotificationData[i].fields;
      for (let j = 0; j < fields.length; j++) {
        allFields.push(fields[j]);
      }
    }
    setFieldsData(allFields);
  }, [NotificationData]);

  useEffect(() => {
    if (error) {
      console.log(error);
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "set_loadData", payload: true });
      toast.success("Subuser Created Sucessfully");
      onClose();
    }
  }, [data]);

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues((prevValues) => ({
      ...prevValues,
      [name]: checked,
    }));
    setAllData(checkboxValues);
  };

  const handleformikdata = (Value, id) => {
    let data = Value;
    if (isNaN(+data) === true) {
      data = Value;
    } else {
      data = +Value;
    }
    formik.setFieldValue(id, data);
  };

  const validate = (values) => {
    setAllData(values);

    let errors = {};

    if (!values.FullName) {
      errors.FullName = "User name is required";
    } else if (values.FullName.length < 3) {
      errors.FullName = "At least 3 letters required";
    } else if (!values.mobileNumber) {
      errors.mobileNumber = "Mobile No is required";
    } else if (String(values.mobileNumber).length < 10) {
      errors.mobileNumber = "Enter Valid 10 Digit Mobile Number";
    }
    // else if (!values.password) {
    //   errors.password = "required";
    // } else if (values.password.search(/[A-Z]/) < 0) {
    //   errors.password = "At least one uppercase letter required";
    // } else if (!/[a-z]/.test(values.password)) {
    //   errors.password = "At least one lowercase letter required";
    // } else if (values.password.search(/[0-9]/) < 0) {
    //   errors.password = "At least one number is required";
    // }

    return errors;
  };

  const handleClickSave = () => {
    let data = { ...alldata, ...checkboxValues };
    let no = +data.mobileNumber;

    delete data.mobileNumber;

    CreateSubUser({
      variables: {
        SubUserInput: {
          ...data,
          mobileNumber: no,
          Root_User: UserInfo.id,
          accType: UserInfo.accType,
        },
      },
    });
  };

  const formik = useFormik({
    initialValues: { ...formikinitialvalues(fieldsData) },
    validate,
    onSubmit: (values) => {
      handleClickSave();
    },
  });

  const f1 = fieldsData.slice(0, 3);

  return (
    <div className="addUserModalDiv">
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        {f1.map((e) => {
          return (
            <>
              {e.inputtype === "input" && (
                <div className="addUserModalContentDiv boxcenter">
                  <div className="addUserModalHeaderDiv">
                    <p className="addUserModalHeader">{e.lable} :</p>
                  </div>
                  <InputBox
                    className={"filterInputBox"}
                    label={e.label}
                    value={formik.values[e.name]}
                    name={e.name}
                    id={e.name}
                    type={e.type}
                    handleInputChange={formik.handleChange}
                    handleformikdata={handleformikdata}
                    maxNo={e.maxNo}
                    autoEveryCapital={e.capital}
                    err={formik.errors[e.name]}
                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                  />
                </div>
              )}
              {e.type === "password" && (
                <div className="addUserModalContentDiv boxcenter">
                  <div className="addUserModalHeaderDiv">
                    <p className="addUserModalHeader">{e.lable} :</p>
                  </div>
                  <InputBox
                    className={"filterInputBox"}
                    label={e.label}
                    value={formik.values[e.name]}
                    name={e.name}
                    id={e.name}
                    type={showPassword ? "text" : "password"}
                    handleInputChange={formik.handleChange}
                    handleformikdata={handleformikdata}
                    err={formik.errors[e.name]}
                    touched={formik.touched[e.name] ? formik.touched[e.name] : false}
                  />
                  {showPassword ? (
                    <FaEye className="eyeIcon" onClick={() => setShowPassword(!showPassword)} />
                  ) : (
                    <FaEyeSlash className="eyeIcon" onClick={() => setShowPassword(!showPassword)} />
                  )}
                </div>
              )}
              {e.type === "checkbox" && (
                <div className="addUserModalContentDiv boxcenter">
                  <div className="addUserModalHeaderDiv">
                    <p className="addUserModalHeader">{e.lable} :</p>
                  </div>
                  <input
                    type={e.type}
                    name={e.name}
                    id={e.name}
                    checked={checkboxValues[e.name] || false}
                    onChange={handleChange}
                  />
                </div>
              )}
            </>
          );
        })}
        <PortalFooter>
          <Button
            text={loading ? <SpinnerIndicator /> : "ADD"}
            className={"acceptBtn"}
            disabled={loading}
            type="submit"
          />
          <Button text="Cancel" className={"rejectBtn"} handleClick={onClose} type="button" />
        </PortalFooter>
      </form>
    </div>
  );
}

export default AddUserModal;

import React, { useState } from "react";
import InputMask from "react-input-mask";
import "./CustomInputMask.scss";

function CustomInputMask({
  mask,
  label,
  name,
  value,
  handleInputMaskChange,
  handleformikdata,
  disabled,
}) {
  const [dimensions, setDimensions] = useState("");
  const formikdatamanage = (value) => {
    // console.log({
    //   name: name,
    //   value: value,
    // });
    handleInputMaskChange({
      target: {
        name: name,
        value: value,
      },
    });
    handleformikdata(value, name);
  };

  const handleInputChange = (event) => {
    setDimensions(event.target.value);
    formikdatamanage(event.target.value);
  };
  return (
    <div className="CIMDiv">
      <p className="imLabel">{label}</p>
      <InputMask
      className={name === "Land_Line" ? "editable no-spinners" : null}
        mask={mask}
        disabled={disabled}
        value={value ? value : dimensions}
        onChange={handleInputChange}
      />
    </div>
  );
}

export default CustomInputMask;

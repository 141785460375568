export const PrivacyAndPolicy = [
    {
        title: 'Acknowledgement and Acceptance of Privacy Policy',
        description: 'By using the Ease My Transport Pvt Ltd (EMT) platform, Account Holders acknowledge that they have read, understood, and agree to be bound by this Privacy Policy.'
    },
    {
        title: 'Collection of Information',
        description: 'We collect personal information from Account Holders during the registration process, and other information automatically when Account Holders use our platform. This information may include but is not limited to, contact details, vehicle details, and transaction history.'
    },
    {
        title: 'Use of Information',
        description: 'The information collected is used to facilitate transactions on the platform, improve our services, and communicate with Account Holders regarding updates, promotions, and other relevant notifications.'
    },
    {
        title: 'Information Sharing',
        description: 'Ease My Transport Pvt Ltd (EMT) may share information with third parties in compliance with legal obligations or to facilitate services on the platform. We do not sell or rent Account Holders’ personal information to third parties for their marketing purposes without Account Holders’ explicit consent.'
    },
    {
        title: 'Security of Information',
        description: 'We employ reasonable physical, electronic, and managerial procedures to safeguard and secure the information collected from Account Holders. However, no online transmission can guarantee absolute security.'
    },
    {
        title: "Account Holder's Responsibility",
        description: 'Account Holders are responsible for maintaining the confidentiality of their account information, including their password, and for all activities that occur under their account.'
    },
    {
        title: 'Cookies',
        description: 'Our platform may use cookies and similar technologies to enhance the user experience and collect data for security, traffic and site interaction.'
    },
    {
        title: 'Rights of Account Holders',
        description: 'Account Holders have the right to access, rectify, or delete their personal information held by EMT. They may exercise these rights by contacting us at the email address provided in section 10.'
    },
    {
        title: 'Changes to Privacy Policy',
        description: 'Ease My Transport Pvt Ltd (EMT) reserves the right to amend this Privacy Policy at any time. Account Holders will be notified of any significant changes, and continued use of the platform indicates acceptance of the updated policy.'
    },
    {
        title: 'Contact',
        description: 'For any questions or concerns regarding this privacy policy, Account Holders can contact us at contact@easemytransport.com'
    },
    {
        title: 'Governing Law',
        description: 'This Privacy Policy shall be governed by and construed in accordance with the laws of India.'
    },
    {
        title: 'Data Retention',
        description: 'We retain the personal data collected from the Account Holders for as long as necessary to fulfill the purposes outlined in this privacy policy, unless a longer retention period is required or permitted by law.'
    },
    {
        title: 'Data Transfer',
        description: 'Your information, including personal data, may be transferred to and maintained on servers located outside of your country where the data protection laws may differ. By using our services, you consent to this transfer of information.'
    },
    {
        title: 'Your Consent',
        description: 'By using our platform, you consent to our privacy policy and agree to its terms.'
    },
    {
        title: 'Analytics',
        description: 'We may use third-party service providers to monitor and analyze the use of our platform. These service providers may collect data such as your IP address, browser type, and the pages of our platform that you visit.'
    },
    {
        title: ' Marketing Communications',
        description: 'With your permission, we may send you emails about our new products, services, and other updates. You have the right to opt out of receiving marketing communications at any time.'
    },
    {
        title: 'Feedback and Complaints',
        description: 'Account Holders have the right to provide feedback or file complaints regarding our privacy practices. We take such feedback seriously and will promptly investigate and respond to any complaints.'
    },
    {
        title: 'Confidentiality of Transactions',
        description: 'All transactions conducted on the platform are confidential. We maintain strict confidentiality protocols to ensure the privacy of transaction details.'
    },
    {
        title: 'Breach Notification',
        description: 'In the event of a data breach that is likely to result in a high risk to the rights and freedoms of Account Holders, we will notify affected individuals and relevant authorities as required by applicable law.'
    },
    {
        title: 'Privacy Policy Acceptance',
        description: 'By registering an account on the Ease My Transport Pvt Ltd platform, Account Holders are deemed to have accepted the terms of this Privacy Policy.'
    },
    {
        title: 'Revision of Privacy Policy',
        description: 'Ease My Transport Pvt Ltd reserves the right to revise this privacy policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons.'
    },
];
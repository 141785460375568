const initial = {
    loadData: false,
  };
  
  export function LoadDataReducer(state = initial, { type, payload }) {
    switch (type) {
      case "set_loadData": {
        return {
          ...state,
          loadData: payload,
        };
      }
      default: {
        return state;
      }
    }
  }
import React from "react";
import DateToString from "../../../Common/DateToString/DateToString";
import "./LoadingDetails.scss";

const LoadingDetails = ({
  fromcity,
  tocity,
  loadingdate,
  loadingtime,
  paymenttype,
  loadingby,
  loadingscope,
  unloadingscope,
}) => {
  return (
    <div className="lpLoadingdetailsbody">
      <div>
        <h5>Load Details</h5>
        <p className="loadingDetailsText">
          {fromcity} to {tocity}
        </p>
        <p className="loadingDetailsText flexText">
          Loading : <DateToString date={loadingdate} time={loadingtime} />
        </p>
        <p className="loadingDetailsText">Payment Type: {paymenttype}</p>
        <p className="loadingDetailsText">Loading By : {loadingby}</p>
        <p className="loadingDetailsText">Loading Scope : {loadingscope}</p>
        <p className="loadingDetailsText">Unloading Scope : {unloadingscope}</p>
      </div>
    </div>
  );
};

export default LoadingDetails;

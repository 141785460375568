import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../../gql/otpRequest";
import { toast } from "react-toastify";
import SpinnerIndicator from "../SpinnerIndicator/SpinnerIndicator";
import Button from "../Button/Button";
import InputBox from "../InputBox/InputBox";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";

function ChangePassword({ setLogin, sessionId }) {
  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (data) {
      toast.success("New Password Set Successfully");
      setLogin(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };
  const validate = (values) => {
    console.log(values, "validate");
    let errors = {};

    if (!values.password) {
      errors.password = "Required";
    } else if (values.password.search(/[A-Z]/) < 0) {
      errors.password = "At least one uppercase letter required";
    } else if (!/[a-z]/.test(values.password)) {
      errors.password = "At least one lowercase letter required";
    } else if (values.password.search(/[0-9]/) < 0) {
      errors.password = "At least one number is required";
    } else if (!values.confirmPassword) {
      errors.confirmPassword = "Please Re-enter New Password";
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "Passwords do not match. Please try again.";
    }

    return errors;
  };

  const onSubmit = (values) => {
    // console.log(values, "onSubmit");

    if (values.password === values.confirmPassword) {
      resetPassword({
        variables: {
          newPassword: values.password,
          sessionId: sessionId,
        },
      });
    }
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit,
  });

  return (
    <div className="changePasswordDiv">
      <p className="changePasswordHeading">Create New Password</p>
      <p className="changePasswordSubHeading">
        Set the new Password for your account so you can login and access all the features.
      </p>
      <form onSubmit={formik.handleSubmit}>
        <div className="Icon-inside">
          <InputBox
            className="inputbox no-spinners"
            type={showPassword ? "text" : "password"}
            placeholder="New Password"
            id="password"
            name="password"
            value={formik.values.password}
            handleInputChange={formik.handleChange}
            err={formik.errors.password}
            formtype="requestOtp"
            touched={formik.touched.password ? formik.touched.password : false}
            autoComplete={"none"}
          />
          {showPassword ? (
            <FaEye className="eyeIcon" onClick={() => setShowPassword(!showPassword)} />
          ) : (
            <FaEyeSlash className="eyeIcon" onClick={() => setShowPassword(!showPassword)} />
          )}
          <i aria-hidden="true">
            <RiLockPasswordFill id="passwordicon" className="form_icons" />
          </i>
        </div>
        <div className="Icon-inside">
          <InputBox
            className="inputbox no-spinners"
            type={"password"}
            placeholder="Confirm New Password"
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            handleInputChange={formik.handleChange}
            err={formik.errors.confirmPassword}
            formtype="requestOtp"
            touched={formik.touched.confirmPassword ? formik.touched.confirmPassword : false}
            autoComplete={"none"}
          />
          <i aria-hidden="true">
            <RiLockPasswordFill id="passwordicon" className="form_icons" />
          </i>
        </div>

        <div className="submitBtnDiv">
          <Button
            className={loading ? "disabledBtnBank" : "acceptBtn"}
            type={"submit"}
            text={loading ? <SpinnerIndicator /> : "Reset Password"}
            disabled={loading}
          />
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;

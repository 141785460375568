import React from 'react'
import "./TH.scss"

function TH({children,className}) {
  return (
    <th className={className}>
    {children}
   </th>
  )
}

export default TH

import React from "react";
import "./CardHeader.scss"

function CardHeader({ children }) {
  return (
    <div className="Containertopbar">
      {children}
    </div>
  )
}

export default CardHeader;

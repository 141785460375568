import { gql } from "@apollo/client";

export const GET_ONGOING_BOOKINGS = gql`
  query CommittedVehiclesAcceptedForLoadPartnerUserId($committedVehiclesAcceptedForLoadPartnerUserIdId: ID) {
    CommittedVehiclesAcceptedForLoadPartnerUserId(id: $committedVehiclesAcceptedForLoadPartnerUserIdId) {
      _id
      BidId {
        _id
        LoadId
        UserId
        Price
        Unique_Id
        No_Of_Vehicle
        Confirm_Qty
        Committed_Vehicles
        Bid_Note
        Bid_History
        createdAt
        updatedAt
      }
      UserId
      LoadId {
        _id
        Unique_Id
        UserId
        Load_Post_Type
        Auction_Date
        Auction_Time
        Dangerous_Goods
        Exact_Content
        Expected_Price
        Target_Price
        From_City
        Load_Live_Duration
        Load_Manager
        Loading_Area
        Loading_By
        Loading_City
        Loading_Date
        Loading_Landmark
        Loading_Name
        Loading_Pincode
        Loading_Scope
        Loading_State
        Loading_Time
        Loading_Address_Line_1
        Loading_Address_Line_2
        Material_Category
        Material_Dimension
        Material_Dimension_In
        Material_Insurence
        Material_Image
        Material_Image_2
        Material_Image_3
        No_Of_Vehicels_Required
        No_Of_Vehicels_Loading_Completed
        Notes
        Over_Dimension_Cargo
        Payment_Type
        Rate_per
        To_City
        Total_Weight
        Unloading_Area
        Unloading_City
        Unloading_Landmark
        Unloading_Name
        Unloading_Pincode
        Unloading_Scope
        Unloading_State
        Unloading_Address_Line_1
        Unloading_Address_Line_2
        Vehicle_Type_String
        Weight_Category_String
        Vehicle_Type {
          _id
          Unique_Id
          Vehicle_Type
          User_ID
          Search_Value
          createdAt
          updatedAt
        }
        Weight_Category {
          _id
          Unique_Id
          Name
          Search_Value
          Vehicle_Type
          User_ID
          createdAt
          updatedAt
        }
        Length_Catagory {
          _id
          length
          Search_Value
          createdAt
          updatedAt
        }
        Is_Live
        Is_Auction_Started
        Is_Archive
        BidDetails {
          _id
          LoadId
          UserId
          Price
          Unique_Id
          No_Of_Vehicle
          Confirm_Qty
          Committed_Vehicles
          Bid_Note
          Bid_History
          createdAt
          updatedAt
        }
        Total_Weight_In
        createdAt
        updatedAt
      }
      Unique_Id
      Vehicle_Number {
        _id
        Unique_Id
        UserId
        Vehicle_No
        createdAt
        updatedAt
      }
      Vehicle_Capacity
      Driver_Name
      Freight_Amount
      Platform_Charges
      Current_Stage
      Driver_Number
      Reporting_Time
      Is_Confirmed
      Is_Accepted
      Is_Rejected
      Booking_Confirmed
      Booking_Confirmed_Time
      Vehicle_Reached_At_Loading_Point
      Vehicle_Reached_At_Loading_Point_Time
      Loading_Completed
      Loading_Completed_Time
      Advance_Processed
      Advance_Processed_Time
      Vehicle_Reach_At_Unloading_Point
      Vehicle_Reach_At_Unloading_Point_Time
      Unloading_Completed
      Unloading_Completed_Time
      Clear_Delevery_Confirmation
      Clear_Delevery_Confirmation_Time
      POD_Collected_From_SP
      POD_Submitted_To_LP
      POD_Collected_From_SP_Time
      POD_Submitted_To_LP_Time
      Invoice_Number
      Eway_Bill_Number
      Eway_Bill_Created_Date
      Eway_Bill_Validate_Date
      Vehicle_Front_Img
      Vehicle_Back_Img
      Vehicle_Right_Img
      Vehicle_Left_Img
      Material_Img
      Entry_Gate_Img
      POD_Front_Copy
      POD_Back_Copy
      Invoice_Copy
      Final_Invoice_Copy
      Holding_Charges
      Hamali_Charges
      Extra_Running
      Challan_Demand
      Other_Charges
      Other_Charges_Reason
      Narration
      Sp_Claim_Raised
      Lp_Claim_Raised
      Sp_Claim_Cleared
      Lp_Claim_Cleared
      Expired
      Time_Out_Expired
      createdAt
      updatedAt
    }
  }
`;

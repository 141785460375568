import React, { useEffect, useState } from "react";
import "./PointOfContact.scss";
const Pointofcontact = ({
  createdAt,
  Load_Live_Duration,
  Auction_Time,
  Auction_Date,
  Is_Archive,
}) => {
  const [formattedCreatedAt, setFormattedCreatedAt] = useState("");
  const [formattedExpireAt, setFormattedExpireAt] = useState("");

  const date = new Date(Auction_Date);
  const options = { month: "long", day: "numeric" };
  const formattedAuctionDate = date.toLocaleString("en-US", options);

  let formattedAuctionTime = Auction_Time && Auction_Time.split("-")[1];

  let expireHour = Load_Live_Duration && Load_Live_Duration.split("H")[0];
  let expHrInNumber = Number(expireHour);

  useEffect(() => {
    const date = new Date(createdAt);
    const expireDate = new Date(
      date.getTime() + expHrInNumber * 60 * 60 * 1000
    ); // Adding 1 hour (60 minutes * 60 seconds * 1000 milliseconds)

    const options = {
      day: "numeric",
      month: "long",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleString("en-US", options);
    const formattedExpireDate = expireDate.toLocaleString("en-US", options);

    setFormattedCreatedAt(formattedDate);
    setFormattedExpireAt(formattedExpireDate);
  }, [createdAt]);

  if (Auction_Date && Auction_Time) {
    return (
      <div className="pointofcontact">
        {/* <p className="pocText">*Platform Charges @2% will applicable</p> */}
        <p className="pocText">
          Load posted on {formattedCreatedAt} <br /> &{" "}
          {Is_Archive ? "expired" : "will expire"} on {formattedAuctionDate} at{" "}
          {formattedAuctionTime}
        </p>
      </div>
    );
  }
  return (
    <div className="pointofcontact">
      {/* <p className="pocText">*Platform Charges @2% will applicable</p> */}
      <p className="pocText">
        Load posted on {formattedCreatedAt} <br /> &{" "}
        {Is_Archive ? "expired" : "will expire"} on {formattedExpireAt}
      </p>
    </div>
  );
};

export default Pointofcontact;

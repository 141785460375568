import { gql } from "@apollo/client";

export const CREATE_RECEIPT = gql`
mutation CreateReceiptInfoEMT($createInput: ReceiptInfoEMTInput!) {
  createReceiptInfoEMT(createInput: $createInput) {
    _id
    Unique_Id
    Type_Of_Payment
    Amount
    Payment_ScreenShot
    UTR_Number
    Status
    createdAt
    updatedAt
  }
}
`;
import React from "react";
import ResultTable from "../../../Pages/LpPages/Loads/Resulted/ResultedComponents/ResultTable/ResultTable";
import "./ResultedBox.scss";

function ResultedBox({ loadId, No_Of_Vehicels_Required, Load_Post_Type }) {
  return (
    <div className="resultTable">
      <p className="resultHeader">Result</p>
      <div className="resultTableDiv">
        <ResultTable
          loadId={loadId}
          No_Of_Vehicels_Required={No_Of_Vehicels_Required}
          Load_Post_Type={Load_Post_Type}
        />
      </div>
    </div>
  );
}

export default ResultedBox;

import React from "react";
import "./SpinnerIndicator.scss";

function SpinnerIndicator() {
  return (
    <div className="loading-indicator">
      <div className="loading-indicator-spinner"></div>
    </div>
  );
}

export default SpinnerIndicator;

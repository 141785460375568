import { gql } from "@apollo/client";

export const OTP_REQUEST = gql`
  query sendOtpForPassword($emailOrNumber: String!, $accType: String!) {
    sendOtpForPassword(emailOrNumber: $emailOrNumber, accType: $accType) {
      message
      sessionId
    }
  }
`;

export const OTP_VERIFY = gql`
  query verifyOtpForPassword($otp: Int!, $sessionId: String) {
    verifyOtpForPassword(otp: $otp, sessionId: $sessionId) {
      message
      sessionId
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation createForgotPassword($newPassword: String!, $sessionId: String) {
    createForgotPassword(newPassword: $newPassword, sessionId: $sessionId) {
      message
    }
  }
`;

import React, { useEffect, useState } from "react";
import "./ResultedBox.scss";

import ResultTable from "../../../Pages/SpPages/Loads/Resulted/ResultedComponents/ResultTable/ResultTable";
import { useQuery } from "@apollo/client";
import socket from "../../../CommonFunction/Socket/socketClient";
import { GET_BID_DETAILS } from "../../../gql/getBDWithCommitedVehicles";

function ResultedBox({ loadId, LPUserId }) {
  const { data, refetch } = useQuery(GET_BID_DETAILS, {
    variables: {
      getBidDetailsWithCommittedVehiclesId: loadId,
    },
  });
  const [committedVehicles, setCommittedVehicles] = useState([]);

  useEffect(() => {
    socket.on("Refetch_EditConfirmVechileswithId", (LoadIdfrombackend) => {
      if (LoadIdfrombackend === loadId) {
        refetch();
      }
    });
  });
  useEffect(() => {
    setCommittedVehicles(
      data && data.GetBidDetailsWithCommittedVehicles ? data.GetBidDetailsWithCommittedVehicles.Committed_Vehicles : []
    );
  }, [data]);
  console.log(data, "data");

  // console.log(data,"alllladsaaa",committedVehicles)

  return (
    <div className="resultTable">
      <p className="resultHeader">Result</p>
      <div className="resultTableDiv">
        <ResultTable
          loadId={loadId}
          LPUserId={LPUserId}
          awardedData={committedVehicles}
          HandleRefetch={() => refetch()}
        />
      </div>
    </div>
  );
}

export default ResultedBox;

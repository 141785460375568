import React, { useEffect, useState } from "react";
import "./Timer.scss";

function Timer({ Auction_Time, Auction_Date }) {
  let dateArr = Auction_Date.split("/");
  let dateStr = `${dateArr[2]}-${
    dateArr[0] < 10 ? `0${dateArr[0]}` : dateArr[0]
  }-${dateArr[1] < 10 ? `0${dateArr[1]}` : dateArr[1]}`;
  let showDate = `${dateArr[1] < 10 ? `0${dateArr[1]}` : dateArr[1]}/${
    dateArr[0] < 10 ? `0${dateArr[0]}` : dateArr[0]
  }/${dateArr[2]}`;

  const timeArr = Auction_Time.split(" ");
  let amPm = timeArr[3];
  let sTime = timeArr[0];
  let [hr, min] = sTime.split(":");

  if (amPm === "PM" && hr !== "12") {
    hr = parseInt(hr, 10) + 12;
  } else if (amPm === "AM" && hr === "12") {
    hr = "00";
  }
  let eTime = timeArr[2];
  let [ehr, emin] = eTime.split(":");
  if (amPm === "PM" && ehr !== "12") {
    ehr = parseInt(ehr, 10) + 12;
  } else if (amPm === "AM" && ehr === "12") {
    ehr = "00";
  }

  let startTimeFormat = `${hr}:${min}`;
  let endTimeFormat = `${ehr}:${emin}`;

  const date = dateStr;
  const startTime = `${startTimeFormat}:00`;
  const endTime = `${endTimeFormat}:00`;
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [inDanger, setInDanger] = useState(false);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const nowInIST = new Date(
        now.toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
      );
      const startDate = new Date(`${date}T${startTime}+05:30`); // Add timezone offset for IST
      const endDate = new Date(`${date}T${endTime}+05:30`); // Add timezone offset for IST
      const remaining =
        nowInIST < startDate ? 0 : Math.max(0, endDate - nowInIST);
      setTimeRemaining(remaining);

      if (remaining < 300000) {
        setInDanger(true);
      }
    };

    calculateTimeRemaining();
    const interval = setInterval(calculateTimeRemaining, 1000);

    return () => clearInterval(interval);
  }, [date, startTime, endTime, timeRemaining]);

  const formatTime = (milliseconds) => {
    if (!milliseconds)
      return (
        <p className="beforeAuctionText">
          Auction will start on {showDate} at {sTime} {amPm}
        </p>
      );

    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return (
      <>
        <div className={"timerDiv"}>
          <h5>Auction Ends In</h5>
          <p className={inDanger ? "aboutEnd" : "timer"}>
            {minutes < 10 ? "0" + minutes : minutes}m :{" "}
            {seconds < 10 ? "0" + seconds : seconds}s
          </p>
        </div>
      </>
    );
  };

  return <>{formatTime(timeRemaining)}</>;
}

export default Timer;

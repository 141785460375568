import React from "react";
import Button from "../Button/Button";
import InputBox from "../InputBox/InputBox";
import "./Pagination.scss";

function Pagination({
  pageIndex,
  pageOptions,
  gotoPage,
  pageSize,
  setPageSize,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageCount,
}) {
  return (
    <div className="paginationDiv boxcenter">
      <span>
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </span>
      <span className="goto boxcenter">
        Go To Page :
        <InputBox
          type="number"
          defaultValue={pageIndex + 1}
          handleInputChange={(e) => {
            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pageNumber);
          }}
        />
      </span>
      <select
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
      >
        {[5, 10, 15, 20].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
      <Button
        className={canPreviousPage ? "addLoadFormBtn" : "disableAddLoadFormBtn"}
        text="|<<"
        handleClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
      />
      <Button
        className={canPreviousPage ? "addLoadFormBtn" : "disableAddLoadFormBtn"}
        text="Previous"
        handleClick={() => previousPage()}
        disabled={!canPreviousPage}
      />
      <Button
        className={canNextPage ? "addLoadFormBtn" : "disableAddLoadFormBtn"}
        text="Next"
        handleClick={() => nextPage()}
        disabled={!canNextPage}
      />
      <Button
        className={canNextPage ? "addLoadFormBtn" : "disableAddLoadFormBtn"}
        text=">>|"
        handleClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      />
    </div>
  );
}

export default Pagination;

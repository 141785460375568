import React, { useEffect, useState } from "react";
import AwardedCard from "./AwardedPageComponents/AwardedCard/AwardedCard";
import TruckLoadingIndicator from "../../../../Components/Common/TruckLoadingIndicator/TruckLoadingIndicator";
import { useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import ShowNoDataFound from "../../../../Components/Common/ShowNoDataFound/ShowNoDataFound";
import { useDispatch, useSelector } from "react-redux";
import "./AwardedPage.scss";
import { GET_AWARDED_DATA } from "../../../../gql/getAwardedLoads";

const AwardedPage = () => {
  const [loadsArr, setLoadsArr] = useState([]);
  const { fielddata } = useSelector((store) => store.filterData);
  const filterFields = fielddata.Filters;
  const dispatch = useDispatch();

  const isLoadData = useSelector((store) => store.loadData.loadData);

  const { loading, error, data, refetch } = useQuery(GET_AWARDED_DATA, {
    variables: {
      getBidsWithLoadForUserUserInput2: filterFields || {},
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refetch();
      } catch (error) {}
    };

    fetchData();
  });

  // console.log("jhvuyfgu", data);

  useEffect(() => {
    if (data && data.getBidsWithLoadForUser) {
      const getData = [...data.getBidsWithLoadForUser];
      const archiveData = getData.filter((e) => e.LoadId.Is_Archive === false);
      const filteredData = archiveData.filter(
        (e) =>
          e.LoadId.Is_Live === false ||
          e.LoadId.Load_Post_Type === "Open Load" ||
          e.LoadId.Load_Post_Type === "Fixed Load"
      );
      // console.log(filteredData, "filteredDatafilteredData");
      const sortedData = filteredData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setLoadsArr(sortedData);
    }
  }, [data]);

  useEffect(() => {
    if (isLoadData) {
      refetch();
      dispatch({ type: "set_loadData", payload: false });
    }
  }, [isLoadData, refetch]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  return (
    <>
      {loading ? (
        <TruckLoadingIndicator />
      ) : loadsArr.length === 0 ? (
        <ShowNoDataFound msg={"No Awarded Loads Available"} />
      ) : (
        <div>
          {loadsArr?.map((e) => {
            return (
              <AwardedCard
                key={e.LoadId._id}
                _id={e.LoadId._id}
                loadId={e.LoadId.Unique_Id}
                Load_Post_Type={e.LoadId.Load_Post_Type}
                From_City={e.LoadId.From_City}
                To_City={e.LoadId.To_City}
                Total_Distance={e.LoadId.Total_Distance}
                Consignor_Area={e.LoadId.Loading_Area}
                Consignor_Landmark={e.LoadId.Loading_Landmark}
                Consignor_City={e.LoadId.Loading_City}
                Consignor_State={e.LoadId.Loading_State}
                Consignor_Pincode={e.LoadId.Loading_Pincode}
                Consignee_Area={e.LoadId.Unloading_Area}
                Consignee_Landmark={e.LoadId.Unloading_Landmark}
                Consignee_City={e.LoadId.Unloading_City}
                Consignee_State={e.LoadId.Unloading_State}
                Consignee_Pincode={e.LoadId.Unloading_Pincode}
                No_Of_Vehicels_Required={e.LoadId.No_Of_Vehicels_Required}
                Vehicle_Type_String={e.LoadId.Vehicle_Type_String}
                Weight_Category_String={e.LoadId.Weight_Category_String}
                Payment_Type={e.LoadId.Payment_Type}
                Material_Insurence={e.LoadId.Material_Insurence}
                Loading_By={e.LoadId.Loading_By}
                // Loading_Scope={e.LoadId.Loading_Scope}
                // Unloading_Scope={e.LoadId.Unloading_Scope}
                Over_Dimension_Cargo={e.LoadId.Over_Dimension_Cargo}
                Total_Weight={e.LoadId.Total_Weight}
                Exact_Content={e.LoadId.Exact_Content}
                Loading_Date={e.LoadId.Loading_Date}
                Loading_time={e.LoadId.Loading_Time}
                Material_Category={e.LoadId.Material_Category}
                Expected_Price={e.LoadId.Expected_Price}
                Auction_Date={e.LoadId.Auction_Date}
                Auction_Time={e.LoadId.Auction_Time}
                Load_Live_Duration={e.LoadId.Load_Live_Duration}
                Dangerous_Goods={e.LoadId.Dangerous_Goods}
                Material_Dimension={e.LoadId.Material_Dimension}
                Material_Dimension_In={e.LoadId.Material_Dimension_In}
                Load_Type={e.LoadId.Load_Type}
                Rate_Per={e.LoadId.Rate_per}
                Notes={e.LoadId.Notes}
                Target_Price={e.LoadId.Target_Price}
                BidId={e.id}
                Price={e.Price}
                Bid_Note={e.Bid_Note}
                LPUserId={e.LoadId.UserId}
                createdAt={e.LoadId.createdAt}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default AwardedPage;

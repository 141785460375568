import React from "react";
import "./SpFinalResultBox.scss";
import { useQuery } from "@apollo/client";
import GetRank from "../../../../../../Components/Common/GetRank/GetRank";
import BidNote from "../../../../../../Components/SpComponents/BidNote/BidNote";
import { NumberFormatter } from "../../../../../../CommonFunction/NumberFormatter/NumberFormatter";
import { BID_DETAILS_BY_LOAD_ID_QUERY } from "../../../../../../gql/getBidDetails";

function SpFinalResultBox({ loadId, bidNote, Load_Post_Type }) {
  const { data } = useQuery(BID_DETAILS_BY_LOAD_ID_QUERY, {
    variables: {
      createInput: {
        id: loadId,
        sort: -1,
        sortBy: "createdAt",
        limit: 1,
      },
    },
  });

  return (
    <>
      {data && data.BidDetailsByLoadIdForUser[0] ? (
        <>
          <div className="SpFinalResultBox">
            {Load_Post_Type === "Fixed Load" ? (
              <p className="greenRankText">L1</p>
            ) : (
              <GetRank
                id={data && data.BidDetailsByLoadIdForUser[0] ? data.BidDetailsByLoadIdForUser[0]._id : ""}
                loadId={loadId}
              />
            )}

            <p className="finalQuotePriceText">
              {NumberFormatter(data.BidDetailsByLoadIdForUser[0].Price)}
              /-
            </p>
          </div>
          {bidNote ? <BidNote bidnote={data.BidDetailsByLoadIdForUser[0].Bid_Note} /> : ""}
        </>
      ) : (
        <div className="SpFinalResultBox">
          <p className="noBidPlaced">No Bid Placed</p>
        </div>
      )}
    </>
  );
}

export default SpFinalResultBox;
